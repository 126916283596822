import { TableCell } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { CellMode, RowType } from '../../DataTable';
import { getValueByKey, renderCell } from '../../helper/DataTableHelper';
import { IColumnDefinition } from '../../IColumnDefinition';

export interface IDataTableCellProps {
    column: IColumnDefinition;
    row: RowType;
    index: number;
    mode?: CellMode;
    onDoubleClick(column: IColumnDefinition, row: RowType, mode?: CellMode): void;
    onFocusLost(column: IColumnDefinition, row: RowType): void;
    onValueChange(column: IColumnDefinition, row: RowType, newValue: unknown): void;
}

export const DataTableCell = (props: IDataTableCellProps) => {
    const { column, row, onDoubleClick, mode, onValueChange, onFocusLost, index } = props;
    const { id, numeric, align, disablePadding, getStyle, className, getClassName, getValue: valueExtrator } = column;

    const value = useMemo(
        () => (valueExtrator ? valueExtrator(row, column) : getValueByKey(id, row)),
        [column, id, row, valueExtrator]
    );
    const handleDoubleClick = useCallback(
        () => onDoubleClick(column, row, mode === 'edit' ? 'view' : 'edit'),
        [column, mode, onDoubleClick, row]
    );
    // const handleFocusLost = useCallback(
    //     (e: FocusEvent<HTMLTableCellElement>) => {
    //         if (!e.currentTarget.contains(e.relatedTarget)) {
    //             onFocusLost(column, row);
    //         }
    //     },
    //     [column, onFocusLost, row]
    // );

    const handleValueChange = (column: IColumnDefinition, row: RowType, newValue: unknown) => {
        onValueChange(column, row, newValue);
        onFocusLost(column, row);
    };

    return (
        <TableCell
            key={id}
            align={align ?? (numeric ? 'right' : undefined)}
            padding={disablePadding ? 'checkbox' : 'normal'}
            className={[className ?? '', getClassName ? getClassName(value, row, column) : ''].join(' ')}
            style={getStyle ? getStyle(value, row, column) : undefined}
            onDoubleClick={handleDoubleClick}
        >
            {renderCell(column, row, value, index, mode, handleValueChange)}
        </TableCell>
    );
};

export default DataTableCell;
