import type { IAsyncAction, IBaseAction, ICallAPI, IShouldCallAPI } from './actions';
import EntityController from './controller/EntityController';

export class ActionCreator {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static createAction<
        T extends string = string,
        P = any | undefined,
        A extends IBaseAction<T, P> = IBaseAction<T, P>,
    >(type: T, payload?: P): A {
        return {
            type: type,
            payload: payload,
        } as A;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static createAsyncAction<
        RequestData extends string = string,
        ReceivedData extends string = string,
        FailedAction extends string = string,
        P = any,
    >(
        type: string,
        types: [RequestData, ReceivedData, FailedAction],
        callAPI: ICallAPI,
        shouldCallAPI: IShouldCallAPI = () => true,
        payload?: P,
        showBusyIndicator?: boolean,
        isInterruptive?: boolean
    ): IAsyncAction<RequestData, ReceivedData, FailedAction, P> {
        return {
            type: type,
            types: types,
            shouldCallAPI: shouldCallAPI,
            callAPI: callAPI,
            payload: payload,
            showBusyIndicator: showBusyIndicator,
            isInterruptive: isInterruptive,
        } as IAsyncAction<RequestData, ReceivedData, FailedAction, P>;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static createAsyncActionByType<P = any>(
        type: string,
        callAPI: ICallAPI,
        payload?: P,
        showBusyIndicator?: boolean,
        isInterruptive?: boolean
    ) {
        return ActionCreator.createAsyncAction(
            type,
            ActionCreator.createAsyncActionTypes(type),
            callAPI,
            undefined,
            payload,
            showBusyIndicator,
            isInterruptive
        );
    }
    public static createAsyncActionTypes<L extends string>(literal: L): [string, string, string] {
        return EntityController.createAsyncActionTypes(literal);
    }
}

export default ActionCreator;
