import { CheckCircle as faCircleCheck, Info as faCircleInfo, Warning as faTriangleExclamation, Close as faX, Favorite as faHeart } from '@mui/icons-material';
import { Grow, SnackbarOrigin } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider } from 'notistack';
import React from 'react';

import Icon from '../../../components/Icon';
import Notifier from '../Notifier';

export interface INotificationProviderProps {
    children?: React.ReactNode;
}

const snackBarOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

const iconVariant = {
    success: <Icon icon={faCircleCheck} className="spacing margin right" />,
    error: <Icon icon={faX} className="spacing margin right" />,
    warning: <Icon icon={faTriangleExclamation} className="spacing margin right" />,
    info: <Icon icon={faCircleInfo} className="spacing margin right" />,
    default: <Icon icon={faHeart} className="spacing margin right" color="error" />,
};

const useStyles = makeStyles(() => ({
    anchorOriginTopRight: {
        top: 48,
    },
}));

export const NotificationProvider = (props: INotificationProviderProps) => {
    const { children } = props;
    const classes = useStyles();
    return (
        <SnackbarProvider
            anchorOrigin={snackBarOrigin}
            iconVariant={iconVariant}
            maxSnack={4}
            autoHideDuration={2000}
            classes={classes}
            TransitionComponent={Grow}
        >
            <Notifier />
            {children}
        </SnackbarProvider>
    );
};

export default NotificationProvider;
