import { ReducerManager } from '../StoreManager';
import type { IAction } from '../StoreManager/actions';
import type { IMiddleware, IReducersMapObject } from '../StoreManager/models';
import { createDictionary, type IDictionary } from '../models/helper/IDictionary';
import { isArray, isUndefined } from '../typeguards';
import CustomEvent from '../ui_helper/CustomEvent';
import type { ReactNode } from 'react';
import BaseModule from './BaseModule';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class ModuleManager {
    private static _instance: ModuleManager | undefined;
    private _modules: IDictionary<BaseModule<any, any>> = createDictionary();
    private _onChange: CustomEvent<IDictionary<BaseModule<any, any>>> = new CustomEvent<
        IDictionary<BaseModule<any, any>>
    >();

    public static getInstance(): ModuleManager {
        return this._instance || (this._instance = new this());
    }

    // public static getDispatchEvaluateLink(dispatch: IDispatch) {
    //     return async (link: ILink, currentProject: IProject, currentSite: IProjectSite, ...args: any[]) => {
    //         const actions = ModuleManager.getInstance().evaluateLink(link, currentProject, currentSite, ...args);
    //         if (actions) {
    //             isArray(actions)
    //                 ? await batchActions(() => actions.forEach((d) => d && dispatch(d)))
    //                 : await dispatch(actions);
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     };
    // }

    private static _concatResults<T>(modules: IDictionary<BaseModule<any, any>>, fnName: keyof BaseModule<any, any>) {
        let items: T[] = [];

        const moduleArray = Object.values(modules);
        for (const module of moduleArray) {
            const res = module[fnName]();
            if (res) {
                if (isArray<T>(res)) {
                    items = items.concat(res);
                } else {
                    items.push(res);
                }
            }
        }

        return items;
    }

    private constructor() {
        // reduce visibility
    }

    public getRegisteredModules(): Array<BaseModule<any, any>> {
        return Object.values(this.modules);
    }

    public getReducer(): IReducersMapObject<{}, IAction> {
        const reducers: IReducersMapObject<{ [k in keyof typeof this.modules]: any }, IAction> = {};
        // build up an dictionary
        const moduleArray = Object.values(this.modules);
        for (const module of moduleArray) {
            reducers[module.moduleIdent.toLowerCase()] = module.reducer;
        }
        return reducers;
    }

    public getMiddlewares(): IMiddleware[] {
        return ModuleManager._concatResults<IMiddleware>(this.modules, 'middleware');
    }

    public renderModules(): ReactNode[] {
        return ModuleManager._concatResults<ReactNode>(this.modules, 'render');
    }

    public renderRoutes(): ReactNode[] {
        return ModuleManager._concatResults<ReactNode>(this.modules, 'renderRoutes');
    }

    // public evaluateLink(
    //     link: ILink,
    //     currentProject: IProject,
    //     currentSite: IProjectSite,
    //     ...args: any[]
    // ): IAction | IAction[] | void {
    //     let errorType: ErrorTypes = ErrorTypes.BACKEND;
    //     let matchingModule: BaseModule<any, any> | undefined;

    //     try {
    //         // split class for 'Aams' 'Menu' or 'BMis' 'ParameterCollection'
    //         const matches: string[] = (link.class || '').split('/');
    //         // check if the Class prop is correctly set up
    //         if (matches.length > 0) {
    //             const matchClass = matches[0].toUpperCase();
    //             // check if the module already exists
    //             matchingModule = this.modules[matchClass];
    //             if (matchingModule) {
    //                 // Let the matching Module evaluate the link.
    //                 return matchingModule.evaluateLink(link, currentProject, currentSite, ...args);
    //             } else {
    //                 errorType = ErrorTypes.RUNTIME;
    //                 throw new Error(
    //                     ImString.replaceAll(
    //                         CoreConfiguration.messages.ERR_MODULE_NOT_REGISTERED,
    //                         '{moduleId}',
    //                         matchClass
    //                     )
    //                 );
    //             }
    //         } else {
    //             // TODO: maybe we can make use of hyperlinks there!
    //             throw new Error(CoreConfiguration.messages.ERR_LINK_CLASS_NOT_VALID);
    //         }
    //     } catch (e) {
    //         const errorInfo = ErrorInfo.create(
    //             ErrorCodes.RESSOURCE_NOT_FOUND,
    //             (e as Error).message,
    //             ErrorSeverities.ERROR,
    //             'ModuleManager'
    //         );
    //         return ErrorActionCreator.raiseError(errorType, errorInfo);
    //     }
    // }

    public registerModule(moduleToRegister: BaseModule<any, any>) {
        if (!this.moduleAlreadyExists(moduleToRegister)) {
            ReducerManager.getInstance().register(moduleToRegister.moduleIdent.toLowerCase(), moduleToRegister.reducer);
            this.modules = { ...this.modules, [moduleToRegister.moduleIdent.toUpperCase()]: moduleToRegister };
        }
    }

    public unregisterModule(moduleToUnregister: BaseModule<any, any>) {
        if (!this.moduleAlreadyExists(moduleToUnregister)) {
            delete this.modules[moduleToUnregister.moduleIdent.toUpperCase()];
            ReducerManager.getInstance().unregister(moduleToUnregister.moduleIdent.toLowerCase());
        }
    }

    // eslint-disable-next-line class-methods-use-this
    public initializeModules() {
        //
    }

    public async importModules() {
        await this.initializeModules();
    }

    public addListener(handler: (modules: IDictionary<BaseModule<any, any>>) => void) {
        this._onChange.on(handler);
    }

    public removeListener(handler: (modules: IDictionary<BaseModule<any, any>>) => void) {
        this._onChange.off(handler);
    }

    public moduleAlreadyExists(module: BaseModule<any, any>): boolean {
        const ident = module.moduleIdent.toLowerCase();
        return ident in this.modules && !isUndefined(this.modules[ident]);
    }

    private set modules(value: IDictionary<BaseModule<any, any>>) {
        this._modules = value;
        this._onChange.trigger(value);
    }

    private get modules(): IDictionary<BaseModule<any, any>> {
        return this._modules;
    }
}

export default ModuleManager.getInstance();
