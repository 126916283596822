import spread from '../../helper/spread';
import { isArray } from '../../typeguards';

export function updateState<S extends {}, K extends keyof S>(
    state: S,
    value: Partial<S> | Array<[key: keyof S, value: S[K]]>
): S {
    if (isArray(value) && value.length > 0) {
        return spread(state, Object.fromEntries(value));
    } else {
        return spread(state, value);
    }
}

export default updateState;
