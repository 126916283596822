import { createPaginatedCollection } from '@bnb-tech/core/src/models/core/collection/IPaginatedCollection';
import { IEntityCollection } from '@bnb-tech/core/src/models/core/entity';
import { getResponse, updateState } from '@bnb-tech/core/src/StoreManager';

import { AnimationAction, AnimationActions } from '../actions/AnimationActionCreator';
import { ILiveDialog } from '../models/domain/dialog/ILiveDialog';
import { IProjectPaginatedCollection } from '../models/domain/project';

export interface IAnimationState {
    dialogsInWaitingRoom: IProjectPaginatedCollection<IEntityCollection<ILiveDialog>>;
}

const defaultState: IAnimationState = {
    dialogsInWaitingRoom: createPaginatedCollection(),
};

export function AnimationReducer(state: IAnimationState = defaultState, action: AnimationAction): IAnimationState {
    switch (action.type) {
        case AnimationActions.GET_DIALOGS_IN_WAITING_ROOM_RESPONSE: {
            return updateState(state, { dialogsInWaitingRoom: getResponse(action) });
        }
        default:
            return state;
    }
}
export default AnimationReducer;
