import FetchService from '../../FetchService';
import { createEmptyEntityCollection, type IEntity, type IEntityCollection } from '../../models/core/entity';
import type { ILink } from 'models/core/link';
import ActionCreator from '../ActionCreator';
import { ReducerManager } from '../ReducerManager';
import type { IAsyncAction, IRequestFinishedAction } from '../actions';
import { EntityCollectionHelper, getResponse, getResponseParams, updateState } from '../helper';
import {
    createAsyncActionEnum,
    createAsyncActionEnumResult,
    type AsyncActionEnumValueCreator,
    type DeclareAsyncAction,
} from '../models';

export type GenerateEntityAction<T extends IEntity, N extends string> =
    | DeclareAsyncAction<AsyncActionEnumValueCreator<'GET_ALL', N>, IEntityCollection<T>, { isNext?: boolean }>
    | DeclareAsyncAction<AsyncActionEnumValueCreator<'GET', N>, T>
    | DeclareAsyncAction<AsyncActionEnumValueCreator<'ADD', N>, T>
    | DeclareAsyncAction<AsyncActionEnumValueCreator<'UPDATE', N>, undefined, T>
    | DeclareAsyncAction<AsyncActionEnumValueCreator<'DELETE', N>, undefined, T>;

// type EnumType<Action> = { [P in Action]: Extract<Action, P> };

export type GenerateActionsEnumType<N extends string> = createAsyncActionEnumResult<'GET_ALL', Uppercase<N>> &
    createAsyncActionEnumResult<'GET', Uppercase<N>> &
    createAsyncActionEnumResult<'ADD', Uppercase<N>> &
    createAsyncActionEnumResult<'UPDATE', Uppercase<N>> &
    createAsyncActionEnumResult<'DELETE', Uppercase<N>>;

export interface IReducerState<T extends IEntity> {
    collection: IEntityCollection<T>;
}

const idProp: keyof IEntity = 'id';

export class EntityController {
    public static create<T extends IEntity, N extends string>(
        name: N,
        config: { baseUrl: string; selfUrl: (id: string) => string }
    ) {
        const { baseUrl, selfUrl } = config;

        const entityActions: GenerateActionsEnumType<N> = {
            ...createAsyncActionEnum('GET_ALL', name.toUpperCase() as Uppercase<N>),
            ...createAsyncActionEnum('GET', name.toUpperCase() as Uppercase<N>),
            ...createAsyncActionEnum('ADD', name.toUpperCase() as Uppercase<N>),
            ...createAsyncActionEnum('UPDATE', name.toUpperCase() as Uppercase<N>),
            ...createAsyncActionEnum('DELETE', name.toUpperCase() as Uppercase<N>),
        } as const;

        const EntityActionCreator = EntityController._createActionCreator<T, N, typeof entityActions>(
            baseUrl,
            entityActions,
            selfUrl,
            entityActions.GET_ALL,
            entityActions.GET,
            entityActions.ADD,
            entityActions.UPDATE,
            entityActions.DELETE
        );

        return [entityActions, EntityActionCreator] as [typeof entityActions, typeof EntityActionCreator];
    }

    public static createReducer<T extends IEntity, N extends string, IState = {}>(
        entityActions: GenerateActionsEnumType<N>,
        getRootState: (state: IState) => IReducerState<T>
    ) {
        const defaultState: IReducerState<T> = {
            collection: createEmptyEntityCollection(),
        };

        const getItems = (state: IState) => getRootState(state).collection ?? createEmptyEntityCollection();
        const getItem = (state: IState, id: string) => getRootState(state).collection.items.find((x) => x.id === id);

        const reducer = ReducerManager.create<IReducerState<T>, GenerateEntityAction<T, N>>(defaultState, {
            [entityActions.GET_ALL_RESPONSE as never]: (
                state: IReducerState<T>,
                action: IRequestFinishedAction<`GET_ALL_RESPONSE`, IEntityCollection<T>>
            ) => updateState(state, { collection: getResponse(action) }),

            [entityActions.GET_RESPONSE as never]: (
                state: IReducerState<T>,
                action: IRequestFinishedAction<`GET_RESPONSE`, T>
            ) => updateState(state, { collection: EntityController.handleGetEntityResponse(state.collection, action) }),

            [entityActions.ADD_RESPONSE as never]: (
                state: IReducerState<T>,
                action: IRequestFinishedAction<`ADD_RESPONSE`, T>
            ) => updateState(state, { collection: EntityController.handleAddEntityResponse(state.collection, action) }),

            [entityActions.UPDATE_RESPONSE as never]: (
                state: IReducerState<T>,
                action: IRequestFinishedAction<`UPDATE_RESPONSE`, undefined, T>
            ) =>
                updateState(state, {
                    collection: EntityController.handleUpdateEntityResponse(state.collection, action),
                }),
            [entityActions.DELETE_RESPONSE as never]: (
                state: IReducerState<T>,
                action: IRequestFinishedAction<`DELETE_RESPONSE`, undefined, T>
            ) =>
                updateState(state, {
                    collection: EntityController.handleDeleteEntityResponse(state.collection, action),
                }),
        } as never);

        return [reducer, getItems, getItem] as [typeof reducer, typeof getItems, typeof getItem];
    }

    public static handleGetAllResponse<
        T extends IEntity,
        K extends string,
        A extends IRequestFinishedAction<K, IEntityCollection<T>, { isNext?: boolean | undefined }>,
    >(collection: IEntityCollection<T>, action: A) {
        if (getResponseParams(action).isNext) {
            const col = getResponse(action);
            return EntityCollectionHelper.mergeCollection<T>(collection, col);
        }
        return getResponse(action);
    }

    public static handleGetEntityResponse<T extends IEntity, K extends string, A extends IRequestFinishedAction<K, T>>(
        collection: IEntityCollection<T>,
        action: A
    ) {
        const entity = getResponse(action);

        // check if the entity does exist, if not we need to add it
        if (!collection.items.find((x) => x.id === entity.id)) {
            return EntityCollectionHelper.addToCollection<T>(collection, entity);
        }

        return EntityCollectionHelper.updateElementOfCollectionByProperty<T>(collection, idProp, entity.id, entity);
    }

    public static handleAddEntityResponse<T extends IEntity, K extends string, A extends IRequestFinishedAction<K, T>>(
        collection: IEntityCollection<T>,
        action: A
    ) {
        return EntityCollectionHelper.addToCollection<T>(collection, getResponse(action));
    }

    public static handleUpdateEntityResponse<
        T extends IEntity,
        K extends string,
        A extends IRequestFinishedAction<K, undefined, T>,
    >(collection: IEntityCollection<T>, action: A) {
        const entity = getResponseParams(action);
        return EntityCollectionHelper.updateElementOfCollectionByProperty<T>(collection, idProp, entity.id, entity);
    }

    public static handleDeleteEntityResponse<
        T extends IEntity,
        K extends string,
        A extends IRequestFinishedAction<K, undefined, T>,
    >(collection: IEntityCollection<T>, action: A) {
        const id = getResponseParams(action);
        return EntityCollectionHelper.removeFromCollectionByProperty<T>(collection, idProp, id.id);
    }

    public static createAsyncActionTypes<L extends string>(literal: L): [string, string, string] {
        return [`${literal}_REQUEST`, `${literal}_RESPONSE`, `${literal}_FAILED`];
    }

    private static _createActionCreator<T extends IEntity, N extends string, E = {}>(
        baseUrl: string,
        entityActions: E,
        selfUrl: (id: string) => string,
        getAllLiteral: Readonly<AsyncActionEnumValueCreator<'GET_ALL', Uppercase<N>>>,
        getLiteral: Readonly<AsyncActionEnumValueCreator<'GET', Uppercase<N>>>,
        createLiteral: Readonly<AsyncActionEnumValueCreator<'ADD', Uppercase<N>>>,
        updateLiteral: Readonly<AsyncActionEnumValueCreator<'UPDATE', Uppercase<N>>>,
        deleteLiteral: Readonly<AsyncActionEnumValueCreator<'DELETE', Uppercase<N>>>
    ) {
        return class {
            public static getAll(link?: ILink): IAsyncAction {
                const href = link?.href ?? baseUrl;

                return ActionCreator.createAsyncActionByType(getAllLiteral.toString(), () => FetchService.get(href), {
                    isNext: Boolean(link?.href),
                });
            }

            public static get(id: string): IAsyncAction {
                const href = selfUrl(id);

                return ActionCreator.createAsyncActionByType(getLiteral.toString(), () => FetchService.get(href));
            }

            public static create(request: Omit<T, keyof IEntity>): IAsyncAction {
                const href = baseUrl;

                return ActionCreator.createAsyncActionByType(createLiteral.toString(), () =>
                    FetchService.put(href, request)
                );
            }

            public static update(entity: T): IAsyncAction {
                const href = selfUrl(entity.id);

                return ActionCreator.createAsyncActionByType(
                    updateLiteral.toString(),
                    () => FetchService.patch(href, entity),
                    entity
                );
            }

            public static delete(entity: T): IAsyncAction {
                const href = selfUrl(entity.id);

                return ActionCreator.createAsyncActionByType(
                    deleteLiteral.toString(),
                    () => FetchService.delete(href),
                    entity
                );
            }
        };
    }
}

export default EntityController;

// const [MyActions, MyActionCreator] = EntityController.create<IEntity, 'PRODUCT'>('PRODUCT', {
//     baseUrl: '',
//     selfUrl: (id: string) => '',
// });
// type MyAction = GenerateEntityAction<IEntity, 'PRODUCT'>;

// const [reducer, getItems, getItem] = EntityController.createReducer<IEntity, 'PRODUCT', MyAction, IState>(
//     MyActions,
//     (state: IState) => state.test
// );

// // MyActionCreator.getAll();

// MyActionCreator.get('id');

// //  MyActionCreator.create({  });

// interface IReducerState {
//     collection: IEntityCollection<IProduct>;
// }

// const defaultValue: IReducerState = {
//     collection: createEmptyEntityCollection(),
// };

// export const reducer = (state: IReducerState = defaultValue, action: MyAction): IReducerState => {
//     switch (action.type) {
//         case MyActions.GET_ALL_PRODUCTS_RESPONSE: {
//             return updateState(state, { collection: getResponse(action) });
//         }

//         case MyActions.GET_PRODUCT_RESPONSE: {
//             return updateState(state, {
//                 collection: EntityController.handleGetEntityResponse(state.collection, action),
//             });
//         }
//         case MyActions.ADD_PRODUCT_RESPONSE: {
//             return updateState(state, {
//                 collection: EntityController.handleAddEntityResponse(state.collection, action),
//             });
//         }
//         case MyActions.UPDATE_PRODUCT_RESPONSE: {
//             return updateState(state, {
//                 collection: EntityController.handleUpdateEntityResponse(state.collection, action),
//             });
//         }
//         case MyActions.DELETE_PRODUCT_RESPONSE: {
//             return updateState(state, {
//                 collection: EntityController.handleDeleteEntityResponse(state.collection, action),
//             });
//         }

//         default:
//             return state;
//     }
// };
