import { ThemeOptions } from '@mui/material/styles';

import { DefaultTheme } from './DefaultTheme';

export const DarkTheme: ThemeOptions = {
    ...DefaultTheme,
    palette: {
        ...DefaultTheme.palette,
        primary: {
            light: '#88aaf7',
            main: '#487CF4',
            dark: '#1146bf',
            contrastText: '#fff',
        },
        secondary: {
            light: '#f16a73',
            main: '#EA1F2D',
            dark: '#CD131F',
            contrastText: '#fff',
        },
        background: {
            default: '#001e3c',
            paper: '#00183c',
        },

        mode: 'dark',
    },
    components: {
        ...DefaultTheme.components,

        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.10), rgba(255, 255, 255, 0.10))`,
                },
                elevation2: {
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11))`,
                },
                elevation3: {
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))`,
                },
                elevation4: {
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.13))`,
                },
                elevation6: {
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14))`,
                },
                elevation8: {
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))`,
                },
                elevation12: {
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))`,
                },
                elevation16: {
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.17), rgba(255, 255, 255, 0.17))`,
                },
                elevation24: {
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0.18))`,
                },
                outlined: {
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.17), rgba(255, 255, 255, 0.17))`,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',

                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.12)',
                    },

                    '> MuiTouchRipple-root': {
                        borderRadius: 12,
                    },
                },

                sizeSmall: {
                    borderRadius: 12,
                    width: 28,
                    height: 28,
                    padding: 6,
                },
            },
        },
    },
};
