import { getResponse, updateState } from '@bnb-tech/core/src/StoreManager';

import { AuthenticationAction, AuthenticationActions } from '../actions/AuthenticationActionCreator';
import { UserAction, UserActions } from '../actions/UserActionCreator';
import { AuthenticationState, RegisterState } from '../models/contracts/responses';
import { ChatAction } from '../services/Chat/actions/ChatActionCreator';

export interface IAuthenticationState {
    userId: string | undefined;
    authenticationState: AuthenticationState | undefined;
    registerState: RegisterState | undefined;
}

const AuthenticationDefault: IAuthenticationState = {
    userId: undefined,
    authenticationState: undefined,
    registerState: undefined,
};

export function AuthenticationReducer(
    state: IAuthenticationState = AuthenticationDefault,
    action: AuthenticationAction | UserAction | ChatAction
): IAuthenticationState {
    switch (action.type) {
        case AuthenticationActions.SESSION_EXPIRED:
        case AuthenticationActions.SUBMIT_LOGOUT_RESPONSE: {
            return AuthenticationDefault;
        }
        case AuthenticationActions.SUBMIT_LOGIN_RESPONSE: {
            const { authenticationState, user } = getResponse(action);

            return updateState(state, {
                userId: user?.id,
                authenticationState: authenticationState,
                registerState: undefined,
            });
        }
        case UserActions.GET_USER_RESPONSE: {
            const user = getResponse(action);

            // if (!state.userId || user.id !== state.userId) {
            //     return state;
            // }

            return updateState(state, { userId: user.id });
        }

        default:
            return state;
    }
}

export default AuthenticationReducer;
