export const MAIN_PATH = '/';

export const HOME_PATH = '/';

// export const DASHBOARD_PATH = '/dashboard';

export const MESSAGES_PATH = '/messages/:id';

// export const LIKES_PATH = '/likes';

// export const VISITORS_PATH = '/visitors';

// export const PROFILE_PATH = '/profile';

// export const STRANGER_PROFILE_PATH = '/stranger-profile/:id';

// export const SHOP_PATH = '/shop';
// export const APP_SHOP_PATH = '/appshop';

// export const SETTINGS_PATH = '/settings';

// export const IMPRESSUM_PATH = '/impressum';

// export const AGB_PATH = '/agb';

// export const DATA_PROTECTION_POLICY_PATH = '/datenschutz';

// export const USER_SEARCH_PATH = '/search/:id';
// export const SUPPORT_PATH = '/support';

export const LOGIN_PATH = '/login';
// export const FORGOT_PASSWORD_PATH = '/forgot-password';

// export const REGISTER_PATH = '/register';

// export const ADMINISTRATION_PATH = '/administration';
// export const TICKETS_PATH = ADMINISTRATION_PATH + '/tickets';
// export const TICKET_DETAIL_PATH = TICKETS_PATH + '/:id';

//Animate
// export const ANIMATE_PATH = '/animate';
// export const CHAT_MASK_PATH = ANIMATE_PATH + '/chat-mask';
// export const SEND_BROADCAST_MAIL_PATH = ANIMATE_PATH + '/send-broadcast-mail';
// export const AUTO_MAILS_PATH = ANIMATE_PATH + '/auto-mails';
// export const LIVE_DIALOGS_PATH = ANIMATE_PATH + '/live-dialogs';
// export const KICK_CHATTER_PATH = ANIMATE_PATH + '/kick-chatter';
// export const WAITING_ROOM_PATH = ANIMATE_PATH + '/waiting-room';
