import { IEntityCollection, createEmptyEntityCollection } from '@bnb-tech/core/src/models/core/entity';
import { shallowEqual, useDispatch, useSelector } from '@bnb-tech/core/src/StoreManager';
import { Share as faShare } from '@mui/icons-material';
import { Avatar, IconButton, Toolbar, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';

import { AnimationActionCreator } from '../../../actions/AnimationActionCreator';
import DataTable from '../../../components/DataTable';
import { IColumnDefinition } from '../../../components/DataTable/IColumnDefinition';
import EmptyState from '../../../components/EmptyState';
import Icon from '../../../components/Icon';
import IIFE from '../../../core/IIFE';
import { ILiveDialog } from '../../../models/domain/dialog/ILiveDialog';
import { IProjectPaginatedCollection, WithProjectEntity } from '../../../models/domain/project';
import { getDialogsInWaitingRoom } from '../../../selectors/Animation.selectors';
import ChatActionCreator from '../../../services/Chat/actions/ChatActionCreator';
import useTranslation from '../../../services/i18n/core/useTranslation';
import useLoadingIndicator from '../components/GlobalLoadingIndicator/useLoadingIndicator';

export interface IWaitingRoomProps {}

export const WaitingRoom = (props: IWaitingRoomProps) => {
    const { loading, setLoading } = useLoadingIndicator();

    const dialogCollection = useSelector(getDialogsInWaitingRoom, shallowEqual);
    const dialogs = useMemo(() => mergeProjectCollection(dialogCollection), [dialogCollection]);
    const { WAITING_ROOM, LAST_MESSAGE, PARTNER, AGENT, ACTIONS, WAITING_ROOM_EMPTY_TITLE } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        IIFE(async () => {
            setLoading(true);
            await dispatch(AnimationActionCreator.getDialogsInWaitingRoom());
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const occupyDialog = useCallback(
        async (id: string, projectId: string) => {
            setLoading(true);
            await dispatch(ChatActionCreator.occupyDialogFromWaitingRoom(id, projectId));
            setLoading(false);
        },
        [dispatch, setLoading]
    );

    const columns = useMemo<Array<IColumnDefinition<WithProjectEntity<ILiveDialog>>>>(
        () => [
            {
                id: 'user.name',
                label: 'User',
                cellComponent: (_, dialog) => (
                    <div className="flex align-items-center row">
                        <Avatar
                            className="spacing margin right"
                            src={dialog.user?.image?.href}
                            imgProps={{ loading: 'lazy', decoding: 'async' }}
                        />
                        <Typography>{dialog.user?.name}</Typography>
                    </div>
                ),
            },

            {
                id: 'lastMessage.message',
                label: LAST_MESSAGE,
                cellComponent: (_, dialog) => (
                    <div className="flex align-items-center row">
                        <Typography style={{ textOverflow: 'ellipsis' }}>{dialog.lastMessage?.message}</Typography>
                    </div>
                ),
            },

            {
                id: 'partner.name',
                label: PARTNER,
                cellComponent: (_, dialog) => (
                    <div className="flex align-items-center row">
                        <Avatar
                            className="spacing margin right"
                            src={dialog.partner.image?.href}
                            imgProps={{ loading: 'lazy', decoding: 'async' }}
                        />
                        <Typography>{dialog.partner.name}</Typography>
                    </div>
                ),
            },

            {
                id: 'agent',
                label: AGENT,
                getValue: (dialog) => dialog.agent?.userName ?? '',
            },
            {
                id: 'project',
                label: 'Project',
                getValue: (dialog) => dialog.project?.name ?? '',
                cellComponent: (_, dialog) => (
                    <>
                        {dialog.project?.logoUrl ? (
                            <img
                                draggable={false}
                                src={dialog.project.logoUrl}
                                alt="project-logo"
                                style={{ height: 32, width: 'auto', userSelect: 'none' }}
                                loading="lazy"
                                decoding="async"
                            />
                        ) : (
                            dialog.project && <Typography>{dialog.project.name}</Typography>
                        )}
                    </>
                ),
            },
            {
                id: 'actions',
                label: ACTIONS,
                disablePadding: true,
                style: { minWidth: 152 },
                sortable: false,
                cellComponent: (_, dialog) => (
                    <IconButton
                        disabled={loading}
                        onClick={() => occupyDialog(dialog.dialogInformationId ?? dialog.id, dialog.projectId)}
                        size="large"
                    >
                        <Icon icon={faShare} />
                    </IconButton>
                ),
            },
        ],
        [ACTIONS, AGENT, LAST_MESSAGE, PARTNER, loading, occupyDialog]
    );

    return (
        <div className="flex">
            <Toolbar className="flex row no-grow justify-content-space-between" variant="dense" disableGutters>
                <Typography variant="h5">{WAITING_ROOM}</Typography>
            </Toolbar>
            <DataTable
                columns={columns}
                rows={dialogs.items}
                className="flex"
                dense
                emptyState={
                    <EmptyState
                        // image={'https://media.tenor.com/images/55959e5fe11e5df87ae4ee352cc9c17a/tenor.gif'}
                        title={WAITING_ROOM_EMPTY_TITLE}
                    />
                }
            />
        </div>
    );
};

export default WaitingRoom;

const mergeProjectCollection = (
    collection: IProjectPaginatedCollection<IEntityCollection<ILiveDialog>>
): IEntityCollection<WithProjectEntity<ILiveDialog>> => {
    const result: IEntityCollection<WithProjectEntity<ILiveDialog>> = createEmptyEntityCollection();

    for (const project of collection.items) {
        const mappedItems = project.item.items.map<WithProjectEntity<ILiveDialog>>((item) => ({
            ...item,
            project: project.project,
            projectId: project.project.id,
        }));

        result.items.push(...mappedItems);

        result.count += project.item.count;
    }

    return result;
};
