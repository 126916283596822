export type ChatMessageType = 'message' | 'wink' | 'present' | 'image' | 'iceBreaker' | 'videoMessage';

export enum ChatMessageTypes {
    Message = 'message',
    Zwinker = 'wink',
    Present = 'present',
    Image = 'image',
    IceBreaker = 'iceBreaker',
    VideoMessage = 'videoMessage',
}
