import { createPaginatedCollection, type IPaginatedCollection } from '../../models/core/collection/IPaginatedCollection';
import addToCollection from './addToCollection';
import removeFromCollection, { removeFromCollectionByProperty } from './removeFromCollection';
import updateElementOfCollection, { updateElementOfCollectionByProperty } from './updateElementOfCollection';

export class PaginatedCollectionHelper {
    public static addToCollection<T>(
        collection: IPaginatedCollection<T> = createPaginatedCollection<T>(),
        ...itemsToAdd: T[]
    ): IPaginatedCollection<T> {
        const newCollection = { ...collection, items: addToCollection(collection.items, ...itemsToAdd) };
        newCollection.count = newCollection.items.length;
        return newCollection;
    }

    public static mergeCollection<T>(
        collection: IPaginatedCollection<T> = createPaginatedCollection<T>(),
        collectionToAdd: IPaginatedCollection<T>
    ): IPaginatedCollection<T> {
        return {
            ...PaginatedCollectionHelper.addToCollection<T>(collection, ...collectionToAdd.items),
            next: collectionToAdd.next,
            prev: collectionToAdd.prev,
            self: collectionToAdd.self,
            page: collectionToAdd.page,
            pageSize: collectionToAdd.pageSize,
            totalCount: collectionToAdd.totalCount ?? collection.totalCount,
        };
    }

    public static removeFromCollection<T>(
        collection: IPaginatedCollection<T> = createPaginatedCollection<T>(),
        predicate: (value: T, index: number, array: T[]) => boolean
    ) {
        const newCollection = { ...collection, items: removeFromCollection(collection.items, predicate) };
        newCollection.count = newCollection.items.length;
        return newCollection;
    }

    public static removeFromCollectionByProperty<T>(
        collection: IPaginatedCollection<T> = createPaginatedCollection<T>(),
        key: keyof T,
        value: unknown
    ) {
        const newCollection = { ...collection, items: removeFromCollectionByProperty(collection.items, key, value) };
        newCollection.count = newCollection.items.length;
        return newCollection;
    }

    public static updateElementOfCollection<T>(
        collection: IPaginatedCollection<T> = createPaginatedCollection<T>(),
        predicate: (value: T, index: number, array: T[]) => T
    ) {
        return { ...collection, items: updateElementOfCollection(collection.items, predicate) };
    }

    public static updateElementOfCollectionByProperty<T>(
        collection: IPaginatedCollection<T> = createPaginatedCollection<T>(),
        key: keyof T,
        value: unknown,
        updateFunction: T | ((value: T) => T)
    ) {
        return {
            ...collection,
            items: updateElementOfCollectionByProperty(collection.items, key, value, updateFunction),
        };
    }
}
export default PaginatedCollectionHelper;
