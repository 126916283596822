import { isArray } from '@bnb-tech/core/src/typeguards';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export interface IIconProps extends SvgIconProps {
    icon: any; // Adjust type as needed for your icon definitions
    size?: string;
    iconColor?: string;
}

export const Icon: React.FC<IIconProps> = (props: IIconProps) => {
    const { style, icon, iconColor: color, ...rest } = props;

    if (!icon || !icon.icon || !isArray(icon.icon) || icon.icon.length < 5) {
        return null;
    }

    let iconColor = color;

    if (style && style.color) {
        iconColor = style.color;
    }

    const [width, height, , , path] = icon.icon;

    return (
        <SvgIcon viewBox={`0 0 ${width} ${height}`} style={{ ...style, color: iconColor }} {...rest}>
            {isArray(path) ? (
                path.map((x, index) => (
                    <path
                        key={index}
                        fill="currentColor"
                        d={x}
                    ></path>
                ))
            ) : (
                <path
                    fill="currentColor"
                    d={path}
                ></path>
            )}
        </SvgIcon>
    );
};

Icon.displayName = 'Icon';

export default Icon;
