import { isBool, isNumber, isString } from '../typeguards';

export class CoreBoolean {
    public static toggle(value?: boolean): boolean {
        return !value;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static parse(obj: any, defaultValue: boolean = false): boolean {
        if (isBool(obj)) {
            return obj;
        }

        if (isNumber(obj)) {
            return obj !== 0;
        }

        if (isString(obj)) {
            const upper = obj.toUpperCase();
            switch (upper) {
                case 'TRUE':
                case '1': {
                    return true;
                }
                case 'FALSE':
                case '0': {
                    return false;
                }
                default:
            }
        }

        return defaultValue;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static isEqual(value: any, checkValue: boolean = true) {
        return this.parse(value) === checkValue;
    }
}

export default CoreBoolean;
