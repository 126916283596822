// This optional code is used to register a service worker.
// register() is not called by default.

import CoreConfiguration from '../config/CoreConfiguration';
import Logger from '../diagnostics/Logger';

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.exec(window.location.hostname)
);

interface IConfig {
    onSuccess?: (registration: ServiceWorkerRegistration) => void;
    onUpdate?: (registration: ServiceWorkerRegistration) => void;
}

export function register(config?: IConfig) {
    if (!CoreConfiguration.isDevelopmentMode && 'serviceWorker' in navigator) {
        const swUrl = `${window.location.href}/service-worker.js`;

        if (isLocalhost) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            (async () => {
                // This is running on localhost. Let's check if a service worker still exists or not.
                await checkValidServiceWorker(swUrl, config);

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                await navigator.serviceWorker.ready;
                Logger.log(
                    'This web app is being served cache-first by a service ' +
                        'worker. To learn more, visit https://bit.ly/CRA-PWA'
                );
            })();
        } else {
            // Is not localhost. Just register service worker
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            registerValidSW(swUrl, config);
        }
    }
}

async function registerValidSW(swUrl: string, config?: IConfig) {
    try {
        const registration = await navigator.serviceWorker.register(swUrl);

        registration.onupdatefound = () => {
            const installingWorker = registration.installing;

            if (installingWorker == null) {
                return;
            }

            installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                        // At this point, the updated precached content has been fetched,
                        // but the previous service worker will still serve the older
                        // content until all client tabs are closed.
                        Logger.log(
                            'New content is available and will be used when all ' +
                                'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
                        );

                        // Execute callback
                        if (config && config.onUpdate) {
                            config.onUpdate(registration);
                        }
                    } else {
                        // At this point, everything has been precached.
                        // It's the perfect time to display a
                        // "Content is cached for offline use." message.
                        Logger.log('Content is cached for offline use.');

                        // Execute callback
                        if (config && config.onSuccess) {
                            config.onSuccess(registration);
                        }
                    }
                }
            };
        };
    } catch (error) {
        Logger.error('Error during service worker registration:', error);
    }
}

async function checkValidServiceWorker(swUrl: string, config?: IConfig) {
    try {
        // Check if the service worker can be found. If it can't reload the page.
        const response = await fetch(swUrl, {
            headers: { 'Service-Worker': 'script' },
        });

        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get('content-type');
        if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
            // No service worker found. Probably a different app. Reload the page.

            const registration = await navigator.serviceWorker.ready;
            await registration.unregister();
            window.location.reload();
        } else {
            // Service worker found. Proceed as normal.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            registerValidSW(swUrl, config);
        }
    } catch (e) {
        Logger.log('No internet connection found. App is running in offline mode.');
    }
}

export async function unregister() {
    if ('serviceWorker' in navigator) {
        try {
            const registration = await navigator.serviceWorker.ready;
            return registration.unregister();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            Logger.error(error.message);
        }
    }
}
