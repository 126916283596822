import { CheckBox as faCheckSquare, IndeterminateCheckBox as faMinusSquare, CheckBoxOutlineBlank as faSquare } from '@mui/icons-material';
import { ThemeOptions } from '@mui/material/styles';

import Icon from '../components/Icon';

export const DefaultTheme: ThemeOptions = {
    palette: {
        background: {
            default: '#F4F5F7',
        },
        // primary: {
        //     light: '#757de8',
        //     main: '#3f51b5',
        //     dark: '#002984',
        //     contrastText: '#fff',
        // },
        // secondary: {
        //     light: '#ff5983',
        //     main: '#f50057',
        //     dark: '#c1121f',
        //     // contrastText: '#000',
        // },

        primary: {
            light: '#5cb4ff',
            main: '#0085cc',
            dark: '#00599b',
            contrastText: '#fff',
        },
        secondary: {
            // light: '#486caf',
            // main: '#05427f',

            // dark: '#001c52',
            light: '#EF5761',
            main: '#c1121f',
            dark: '#700A11',
            contrastText: '#fff',
        },
        error: {
            main: '#ff1744',
        },
        success: {
            light: '#80e27e',
            main: '#4CAF50',
            dark: '#087f23',
        },
        info: {
            main: '#2C66D0',
        },
        warning: {
            main: '#FF8000',
        },
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 12,
        button: {
            fontWeight: 600,
        },
    },
    shape: {
        borderRadius: 10,
    },

    components: {
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                },
            },
        },
        MuiLinearProgress: {
            defaultProps: {
                variant: 'query',
            },
            styleOverrides: {
                bar: {
                    borderRadius: 4,
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                margin: 'dense',
                size: 'small',
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
                size: 'small',
            },
            styleOverrides: {
                root: {
                    marginTop: 8,
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                margin: 'dense',
                size: 'small',
            },
        },
        MuiCheckbox: {
            defaultProps: {
                icon: <Icon icon={faSquare} />,
                checkedIcon: <Icon icon={faCheckSquare} />,
                indeterminateIcon: <Icon icon={faMinusSquare} />,
            },
        },
        MuiDialog: {
            defaultProps: {
                maxWidth: 'xs',
                fullWidth: true,
            },
        },
        MuiTabs: {
            defaultProps: {
                TabIndicatorProps: { children: <span /> },
            },
            styleOverrides: {
                indicator: (t) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                    // height: '3px',
                    '& > span': {
                        width: '70%',
                        borderRadius: '4px',
                        backgroundColor:
                            t.ownerState.indicatorColor === 'primary'
                                ? t.theme.palette.primary.main
                                : t.theme.palette.secondary.main,
                    },
                }),
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    '@media (min-width: 600px)': {
                        minWidth: 'auto',
                    },
                },
            },
        },

        MuiButton: {
            defaultProps: {
                disableElevation: true,
                disableTouchRipple: true,
                disableFocusRipple: false,
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },

        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem',
                    fill: '#000000',
                },
                fontSizeSmall: {
                    fontSize: '1rem',
                },
            },
        },

        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',

                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                    },

                    '> MuiTouchRipple-root': {
                        borderRadius: 12,
                    },
                },

                sizeSmall: {
                    borderRadius: 8,
                    width: 28,
                    height: 28,
                    padding: 6,
                },
            },
        },

        MuiMobileStepper: {
            styleOverrides: {
                root: {
                    background: 'transparent',
                },
                dot: {
                    backgroundColor: 'rgb(117, 117, 117)',
                    height: 12,
                    width: 12,
                },
                dotActive: (t) => ({
                    backgroundColor: t.theme.palette.secondary.main,
                }),
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: (t) => ({
                    fill: t.ownerState.active
                        ? t.theme.palette.primary.main
                        : t.ownerState.completed
                        ? t.theme.palette.success.main
                        : 'inherit',
                }),
            },
        },
        MuiTableCell: {
            defaultProps: {},
            styleOverrides: {
                body: (t) => ({
                    ':focus-within': {
                        outline: `${t.theme.palette.secondary.main} 2px solid`,
                        outlineOffset: -2,
                    },
                    ':focus': {
                        outline: `${t.theme.palette.secondary.main} 2px solid`,
                        outlineOffset: -2,
                    },
                }),
            },
        },

        MuiMenuItem: {
            styleOverrides: {
                root: (t) => ({
                    '&.Mui-selected': {
                        color: t.theme.palette.primary.main,
                    },
                }),
            },
        },
    },
};
