export class BrowserNotificationService {
    private static _instance: BrowserNotificationService = new BrowserNotificationService();

    public static async requestPermission() {
        if (await this.checkIsSupported()) {
            const result = await Notification.requestPermission();
            return result === 'granted';
        }

        return false;
    }

    public static async checkIsSupported() {
        try {
            await Notification.requestPermission();
        } catch (e) {
            return false;
        }

        return true;
    }

    public static async showNotification(title: string, body: string, duration: number = 10) {
        if (await this.requestPermission()) {
            // create a new notification
            const notification = new Notification(title, {
                body: body,
                // icon: './img/js.png',
            });

            // close the notification after 10 seconds
            setTimeout(() => {
                notification.close();
            }, duration * 1000);

            // // navigate to a URL
            // notification.addEventListener('click', () => {
            //     window.open('https://www.javascripttutorial.net/web-apis/javascript-notification/', '_blank');
            // });
        }
    }
}

export default BrowserNotificationService;
