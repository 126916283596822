import { getResponse, updateState } from '@bnb-tech/core/src/StoreManager';

import { AuthenticationAction, AuthenticationActions } from '../actions/AuthenticationActionCreator';
import { DashboardAction, DashboardActions } from '../actions/DashboardActionCreator';
import { IAgentDashboard } from '../models/domain/dashboard/IAgentDashboard';

export interface IDashboardState {
    dashboard: IAgentDashboard | undefined;
}

const DashboardDefault: IDashboardState = {
    dashboard: undefined,
};

type Action = AuthenticationAction | DashboardAction;

export function DashboardReducer(state: IDashboardState = DashboardDefault, action: Action): IDashboardState {
    switch (action.type) {
        case DashboardActions.GET_DASHBOARD_RESPONSE: {
            return updateState(state, { dashboard: getResponse(action) });
        }
        case AuthenticationActions.SUBMIT_LOGOUT_REQUEST: {
            return updateState(state, { dashboard: undefined });
        }
        default:
            return state;
    }
}
