export type DurationUnit = 'milliSeconds' | 'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years';

export enum DurationUnits {
    MILLISECONDS = 'milliSeconds',
    SECONDS = 'seconds',
    MINUTES = 'minutes',
    HOURS = 'hours',
    DAYS = 'days',
    MONTHS = 'months',
    YEARS = 'years',
}
