import { IResourceDictionary } from './../models/IResourceDicitionary';

export const en: IResourceDictionary = {
    ACTIONS: 'Actions',
    AGENT: 'Agent',
    ALERT_LOGIN_FAILED: 'Error while logging in!',
    asa: 'ASA',
    ATTRIBUTE_BIRTHDATE: 'Birthdate',
    ATTRIBUTE_FAMILY: 'Family',
    ATTRIBUTE_HOBBIES: 'Hobbies',
    ATTRIBUTE_JOB: 'Job',
    ATTRIBUTE_LIVING: 'Living',
    ATTRIBUTE_LOVELIFE: 'Lovelife',
    ATTRIBUTE_NAME: 'Name',
    ATTRIBUTE_OTHER: 'Other',
    ATTRIBUTE_PHONE: 'Phone',
    ATTRIBUTE_PREFRENCES: 'Preferences',
    ATTRIBUTE_RELATION: 'Relation/Searches for',
    ATTRIBUTE_SPECIAL: 'Special',
    ATTRIBUTE_TATTOS: 'Tattoos/Piercings',
    ATTRIBUTE_VACATION: 'Vacation',
    ATTRIBUTE_WORKING_HOURS: 'Working hours',
    BIRTH_DAY_REMINDER_TEXT: '{name} has birthday 🎂',
    BIRTH_DAY: 'Birthday',
    BIRTH_MONTH: 'Birthmonth',
    BIRTH_YEAR: 'Birthyear',
    BROWSER_NOTIFICATION_TEXT: 'Answer now',
    BROWSER_NOTIFICATION_TITLE: '{project} - A new Dialog is available!',
    BTN_BACK: 'Back',
    BTN_CONTINUE: 'Continue',
    BTN_REPORT_CHAT: 'Report chat',
    BTN_REQUEST_PICTURE: 'Request Picture',
    BTN_SEND_IMAGE: 'Send image',
    BTN_UPLOAD_IMAGE: 'Upload image',
    BTN_USER_INFORMATION: 'User information',
    BTN_WAITING_ROOM: 'To Waitingroom',
    CANCEL: 'Cancel',
    CHAT_GOT_A_BLINK: '{name} winked at you 😉',
    CHAT_GOT_A_PRESENT: '{name} send you a present 🎁',
    CHAT_INFO: 'Important information',
    CHAT_INTERFACE_EMPTYSTATE: 'New Customer. Write {name} the first message!',
    CHAT_INTERFACE_SEND_A_MESSAGE: 'Send your Message...',
    CHAT_INTERFACE_TODAY: 'Today',
    CHAT_INTERFACE_YESTERDAY: 'Yesterday',
    CHAT_SENT_A_BLINK: "You've sent {name} a wink 😉",
    CHAT_SENT_A_PRESENT: "You've sent a present 🎁",
    CHAT_YOU: 'You:',
    chatReport: 'chat report',
    closed: 'closed',
    DASHBOARD_AGENCY: 'Agency',
    DASHBOARD_AMOUNT_PER_IN: 'Amount per In',
    DASHBOARD_AMOUNT_PER_OUT: 'Amount per Out',
    DASHBOARD_DIALOGS_OPEN: 'Dialogs open',
    DASHBOARD_GLOBAL_STATISTICS: 'Global statistic',
    DASHBOARD_MONTHLY_IN_OUTS: 'This months Ins / Outs',
    DASHBOARD_MY_PROFILE: 'My profile',
    DASHBOARD_MY_STATISTICS: 'My statistic',
    DASHBOARD_OPEN_TICKETS: 'Open Tickets',
    DASHBOARD_TODAY_IN_OUTS: 'Todays Ins / Outs',
    DASHBOARD_TOTAL_DIALOGS: 'Total dialogs',
    DASHBOARD_TOTAL_IN_OUTS: 'Total Ins / Outs',
    DASHBOARD_TOTAL_OPEN: 'Total / Open',
    DASHBOARD_WAITING_ROOM: 'Waiting room',
    DAY: 'Day',
    de: 'German',
    DELETE_NOTE_MODAL_TEXT: 'Are you sure to delete this note?',
    DELETE_NOTE_MODAL_TITLE: 'Delete note',
    DIALOG_WS_CONNECTION_LOST_TEXT: 'The connection to our Chatservice was interrupted, reconnect is in the process.',
    DISCONNECTED_TEXT: 'Currently disconnected, wait a bit until the connection is established again.',
    DISLIKE: 'Dislike',
    DROPZONE_DRAG_TEXT: 'Drop file here!',
    DROPZONE_TEXT: 'Press or drag',
    en: 'English',
    es: 'Spanish',
    FAVORITE_CHATS: 'chats',
    FAVORITE_FAVORITES: 'favorites',
    fr: 'French',
    FRIDAY: 'Friday',
    high: 'high',
    HOME_EMPTY_STATE: 'Looking for dialogs...',
    imageRequest: 'image request',
    inProgress: 'in progress',
    it: 'Italian',
    LANG_DE: 'German',
    LANG_EN: 'English',
    LAST_MESSAGE: 'Last message',
    LBL_DIALOG_LANGUAGE: 'Dialog language',
    LBL_FIRST_CONTACT: 'First contact',
    LBL_GLOBAL_INFO: 'Global information',
    LBL_GLOBAL_USER_INFORMATION_USER_TYPE: 'User type',
    LBL_PARTNER_INTERACTION: 'Partner interaction',
    LBL_TICKET_DESCRIPTION: 'Description',
    LBL_TICKET_PRIORITY: 'Priority',
    LBL_TICKET_STATE: 'State',
    LBL_TICKET_TITLE: 'Title',
    LBL_TICKET_TYPE: 'Request type',
    LBL_USER_INTERACTION: 'User interaction',
    LIKE: 'Like',
    LOGOUT_MODAL_BUTTON: 'Logout',
    LOGOUT_MODAL_TEXT: 'Are you sure to logout at this device?',
    LOGOUT_MODAL_TITLE: 'Logout',
    low: 'low',
    medium: 'medium',
    MENU_DARK_MODE: 'Darkmode',
    MENU_DASHBOARD: 'Dashboard',
    MENU_ENTER: 'Enter to Send allowed',
    MENU_OWN_PROFILE: 'My profile',
    MENU_SOUND: 'Set sound',
    MENU_WAITINGROOM: 'Waitingroom',
    MESSAGE: 'Message',
    MONDAY: 'Monday',
    MONTH: 'Month',
    newCustomer: 'new Customer',
    nl: 'Dutch',
    open: 'open',
    PARTNER: 'Partner',
    PHONE_EXISTS: 'Phone number exists',
    pl: 'Polish',
    PRESENTS_TITLE: 'Choose your gift for {name}',
    rejected: 'rejected',
    resolved: 'resolved',
    SATURDAY: 'Saturday',
    SAVE: 'Save',
    SELECT_TEMPLATE_IMAGE_TITLE: 'Select a picture you want to send',
    SELECT_TEMPLATE_IMAGE_UPLOAD_TITLE: 'Select a picture you want to upload',
    SEND_IMAGE_FILTER_BY_ALL: 'All',
    SEND_IMAGE_FILTER_BY: 'Filter by',
    SEND: 'Send',
    SETTINGS_LOGOUT: 'Logout',
    SUNDAY: 'Sunday',
    THURSDAY: 'Thursday',
    TRAITS_ASKME: 'Just ask...',
    TRAITS_BODYJEWELRY_BRANDING: 'Branding',
    TRAITS_BODYJEWELRY_NOTHING: 'Nothing',
    TRAITS_BODYJEWELRY_PIERCED: 'Piercing',
    TRAITS_BODYJEWELRY_TATTOO_AND_PIERCED: 'Tattoo and Piercing',
    TRAITS_BODYJEWELRY_TATTOO: 'Tattoo',
    TRAITS_BODYJEWELRY_TITLE: 'Body jewelry',
    TRAITS_EYECOLOR_BLACK: 'Black',
    TRAITS_EYECOLOR_BLUE: 'Blue',
    TRAITS_EYECOLOR_BROWN: 'Brown',
    TRAITS_EYECOLOR_GREEN: 'Green',
    TRAITS_EYECOLOR_GREY: 'Grey',
    TRAITS_EYECOLOR_TITLE: 'Eye Color',
    TRAITS_GENDER_MAN: 'Male',
    TRAITS_GENDER_TITLE: 'Gender',
    TRAITS_GENDER_WOMAN: 'Female',
    TRAITS_HAIRCOLOR_BLACK: 'Black',
    TRAITS_HAIRCOLOR_BLONDE: 'Blonde',
    TRAITS_HAIRCOLOR_BROWN: 'Brown',
    TRAITS_HAIRCOLOR_GREY: 'Grey',
    TRAITS_HAIRCOLOR_RED: 'Red',
    TRAITS_HAIRCOLOR_TITLE: 'Hair Color',
    TRAITS_LIVING_ALONE: 'Alone',
    TRAITS_LIVING_PARENTS: 'With my Parents',
    TRAITS_LIVING_PARTNER: 'With Partner',
    TRAITS_LIVING_SHARED: 'Shared Aparment',
    TRAITS_LIVING_TITLE: 'Living situaiton',
    TRAITS_LIVING_UNIVERSITY: 'At the University',
    TRAITS_RELATIONSHIP_COMPLICATED: 'Its complicated',
    TRAITS_RELATIONSHIP_DIVORCED: 'Divorced',
    TRAITS_RELATIONSHIP_GIVEN: 'Taken',
    TRAITS_RELATIONSHIP_MARRIED: 'Married',
    TRAITS_RELATIONSHIP_OPEN: 'Open relationship',
    TRAITS_RELATIONSHIP_SINGLE: 'Single',
    TRAITS_RELATIONSHIP_TITLE: 'Relationship status',
    TRAITS_RELATIONSHIP_WIDOW: 'Widowed',
    TRAITS_SIZE_TITLE: 'Size',
    TRAITS_SMOKER_NO: 'Not smoking',
    TRAITS_SMOKER_OCCASIONALLY: 'Smoke sometimes',
    TRAITS_SMOKER_TITLE: 'Smoker',
    TRAITS_SMOKER_VAPE: 'Vaper',
    TRAITS_SMOKER_YES: 'Yes',
    TRAITS_STARSIGN_AQUARIUS: 'Aquarius',
    TRAITS_STARSIGN_ARIES: 'Aries',
    TRAITS_STARSIGN_CANCER: 'Cancer',
    TRAITS_STARSIGN_CAPRICORN: 'Capricorn',
    TRAITS_STARSIGN_GEMINI: 'Gemini',
    TRAITS_STARSIGN_LEO: 'Leo',
    TRAITS_STARSIGN_LIBRA: 'Libra',
    TRAITS_STARSIGN_PISCES: 'Pisces',
    TRAITS_STARSIGN_SAGITTARIUS: 'Sagittarius',
    TRAITS_STARSIGN_SCORPIO: 'Scorpio',
    TRAITS_STARSIGN_TAURUS: 'Taurus',
    TRAITS_STARSIGN_TITLE: 'Starsign',
    TRAITS_STARSIGN_VIGRO: 'Vigro',
    TUESDAY: 'Tuesday',
    UNLOCK_IMAGE_BUTTON: 'Unlock ({coins}C)',
    UNLOCK_IMAGE_TEXT: 'Do you want to unlock that image of {name}?',
    UNLOCK_IMAGE_TITLE: 'What is behind that photo?',
    urgent: 'urgent',
    USER_INFO_DIALOG_MINOR_COINS: 'Coin credit & ',
    USER_INFO_DIALOG_MINOR_MESSAGES: 'Sent messages:',
    USER_INFO_DIALOG_TITLE: 'Information to the User:',
    USER_INFO_DIALOG_TRAITS: 'Usertraits:',
    WAITING_ROOM_EMPTY_TITLE: 'There are no chats currently available in the waiting room.',
    WAITING_ROOM: 'Waiting Room',
    waitingRoom: 'Waitingroom',
    WEDNESDAY: 'Wednesday',
    YEAR: 'Year',
    YES: 'Yes',
    NO: 'No',
    BIRTH_DAY_MODAL_TITLE: 'Have you already congratulated the users birthday?',
    CHAT_MORE: 'Show more',
    DELETE: 'Delete',
    USER_ABOUT: 'About me',
    dk: 'Danish',
    se: 'Swedish',
};

export default en;
