export interface IDictionary<T = string> {
    [id: string]: T;
}

export type Dictionary<T> = { [P in keyof T]: T[P] };
export type TypedDictionary<T, V> = { [P in keyof T]?: V };

/**
 * Creates a dicitionary of the given maps
 * ```typescript
 * createDictionary(
 *  new Map([['test', 1], ['test2', 2], ['test3', 1], ['test4', 1]]),
 *  new Map([['test', 5], ['test4', 2000], ['test7', 1], ['test8', 1]])
 * );
 * // => { test: 5, test2: 2, test3: 1, test4: 2000, test7: 1, test8: 1 }
 * ```
 *
 * @export
 * @template T
 * @param {...Map<string, T>[]} values
 * @returns IDictionary<T>
 */
export function createDictionary<T = string>(...values: Array<Map<string, T>>): IDictionary<T> {
    const dictionary = Object.create(null);

    if (values) {
        for (const map of values) {
            const entries = map.entries();
            for (const item of entries) {
                dictionary[item[0]] = item[1];
            }
        }
    }

    return dictionary;
}

/**
 * merges the given dictionaries into a new oned
 *
 * @export
 * @template T
 * @param {...IDictionary<T>[]} values
 * @returns {IDictionary<T>}
 */
export function mergeDictionaries<T = string>(...values: Array<IDictionary<T>>): IDictionary<T> {
    return Object.assign({}, ...values);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExactDictionary<K extends keyof any, T = string> = Record<K, T>;
