import { RouteObject } from 'react-router-dom';

import SecuredRoute from '../../components/security/SecuredRoute';
import LoginView from '../../views/LoginView';
import Mainpage from '../../views/Mainpage';

export const AppRouteConfig: RouteObject[] = [
    {
        path: 'login',
        element: (
            <SecuredRoute insecureOnly>
                <LoginView />
            </SecuredRoute>
        ),
    },
    {
        path: '/*',
        element: (
            <SecuredRoute>
                <Mainpage />
            </SecuredRoute>
        ),
    },
];
