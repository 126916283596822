import { CheckBoxOutlineBlank as faSquare, CheckBox as faSquareCheck, IndeterminateCheckBox as faSquareMinus } from '@mui/icons-material';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { ReactNode } from 'react';

import Icon from '../Icon';

export interface ICheckboxProps extends CheckboxProps {
    label?: string | ReactNode;
    labelClassName?: string;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

export const Checkbox = (props: ICheckboxProps) => {
    const { label, labelClassName, labelPlacement, ...rest } = props;

    const main = (
        <MuiCheckbox
            icon={<Icon icon={faSquare} />}
            checkedIcon={<Icon icon={faSquareCheck} />}
            indeterminateIcon={<Icon icon={faSquareMinus} />}
            {...rest}
        />
    );

    if (label) {
        return (
            <FormControlLabel control={main} label={label} className={labelClassName} labelPlacement={labelPlacement} />
        );
    }

    return main;
};

export default Checkbox;
