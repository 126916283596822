import { createContext } from 'react';

export const GlobalLoadingIndicatorContext = createContext<{
    loading: boolean;
    setLoading: (loading: boolean) => void;
}>({
    loading: false,
    setLoading: () => null,
});

export default GlobalLoadingIndicatorContext;
