import { IDialogReadRequest } from '../../../models/contracts/requests/chat/IDialogReadRequest';
import { IGetDialogRequest } from '../../../models/contracts/requests/chat/IGetDialogRequest';
import { IOnWriteRequest } from '../../../models/contracts/requests/chat/IOnWriteRequest';
import { ISendMessageRequest } from '../../../models/contracts/requests/chat/ISendMessageRequest';
import { IWebSocketMessage } from '../models/IWebSocketMessage';

export class ChatMessages {
    public static refresh(dialog: IGetDialogRequest): IWebSocketMessage {
        if (dialog?.dialogId) {
            return {
                event: 'getDialog',
                dialog: dialog.dialogId,
                payload: dialog,
            };
        }

        return {
            event: 'getDialogs',
            dialog: undefined,
            payload: dialog,
        };
    }

    public static sendMessage(message: ISendMessageRequest): IWebSocketMessage {
        return {
            event: 'sendMessage',
            dialog: message.dialogId,
            payload: message,
        };
    }

    public static dialogRead(dialog: IDialogReadRequest): IWebSocketMessage {
        return {
            event: 'dialogRead',
            dialog: dialog.dialogId,
            payload: dialog,
        };
    }

    public static write(message: IOnWriteRequest): IWebSocketMessage {
        return {
            event: 'write',
            payload: message,
        };
    }
}

export default ChatMessages;
