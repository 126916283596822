export class Base64 {
    public static encode(s: string, uriSafe?: boolean) {
        return Base64.btoa(s);
    }

    public static decode(base64: string) {
        return Base64.atob(base64);
    }

    public static encodeURI(s: string): string {
        return encodeURI(s);
    }

    public static atob(base64: string): string {
        return decodeURIComponent(
            Array.prototype.map.call(atob(base64), (c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`).join('')
        );
        // return decodeURIComponent(escape(atob(base64)));
    }

    public static btoa(s: string): string {
        return btoa(
            encodeURIComponent(s).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16)))
        );

        // return btoa(unescape(encodeURIComponent(s)));
    }
}

export default Base64;
