export type Currency =
    | 'EUR'
    | 'USD'
    | 'GBP'
    | 'CZK'
    | 'SEK'
    | 'PLN'
    | 'CHF'
    | 'ALL'
    | 'BGN'
    | 'HRK'
    | 'DKK'
    | 'BYN'
    | 'BAM'
    | 'HUF'
    | 'ISK'
    | 'MDL'
    | 'NOK'
    | 'RON'
    | 'RUB'
    | 'MKD'
    | 'RSD'
    | 'TRY'
    | 'UAH';

export enum Currencies {
    EURO = 'EUR',
    US_DOLLAR = 'USD',
    BRITISH_POUND = 'GBP',
    CZECH_KORUNA = 'CZK',
    SWEDISH_KRONA = 'SEK',
    POLISH_ZLOTY = 'PLN',
    SWISS_FRANC = 'CHF',
    ALBANIAN_LEK = 'ALL',
    BULGARIAN_LEV = 'BGN',
    CROATIAN_KUNA = 'HRK',
    DANISH_KRONE = 'DKK',
    BELARUSIAN_RUBLE = 'BYN',
    BOSNIA_AND_HERZEGOVINA_CONVERTIBLE_MARK = 'BAM',
    HUNGARIAN_FORINT = 'HUF',
    ICELANDIC_KRONA = 'ISK',
    MOLDOVAN_LEU = 'MDL',
    NORWEGIAN_KRONE = 'NOK',
    ROMANIAN_LEU = 'RON',
    RUSSIAN_RUBLE = 'RUB',
    SECOND_MACEDONIAN_DENAR = 'MKD',
    SERBIAN_DINAR = 'RSD',
    TURKISH_LIRA = 'TRY',
    UKRANIAN_HRYVNIA = 'UAH',
}
