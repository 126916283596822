import type { IAsyncAction } from '../StoreManager/actions';
import type { IDispatch, IState } from '../oreManager/models';

import { onRequestResult } from './fetchMiddleware';

export async function dispatchApiFetchFailure(
    dispatch: IDispatch,
    state: IState,
    calledAction: IAsyncAction,
    failureType: string,
    statusText: string,
    payload: {},
    responseJson?: string | object,
    response?: Response,
    // errorType?: ErrorTypes,
    errorInfo?: {},
    stack?: string
) {
    if (response) {
        const errorParams = {
            dispatch,
            calledAction,
            result: errorInfo,
            response,
            actionType: failureType,
            payload,
            state,
        };

        if (calledAction.onError) {
            calledAction.onError(errorParams);
        }

        // trigger the onRequestFailed event and give chance to manipulate the error info object
        const handlerResult = onRequestResult.trigger(errorParams);

        if (!handlerResult) {
            return;
        }
    }

    // // check if needs to raise an error
    // if (errorType && errorInfo) {
    //     dispatch(ErrorActionCreator.raiseError(errorType, errorInfo, stack, calledAction.isInterruptive));
    // }

    // dispatch the failure
    return await dispatch({
        type: failureType,
        payload: {
            result: responseJson,
            params: payload || {},
            response: response,
            error: errorInfo,
            statusText: statusText,
            interruptive: calledAction.isInterruptive,
        },
    });
}

export default dispatchApiFetchFailure;
