import { IResourceDictionary } from './IResourceDicitionary';

export type Language = 'de' | 'en' | 'fr' | 'pl' | 'it' | 'nl' | 'dk' | 'se';
export enum Languages {
    DE = 'de',
    EN = 'en',
    FR = 'fr',
    IT = 'it',
    PL = 'pl',
    NL = 'nl',
    DK = 'dk',
    SE = 'se',
}

const LanguageLabelMap = {
    [Languages.DE]: 'Deutsch',
    [Languages.EN]: 'Englisch',
    [Languages.FR]: 'Französisch',
    [Languages.IT]: 'Italienisch',
    [Languages.PL]: 'Polnisch',
    [Languages.NL]: 'Niederländisch',
    [Languages.DK]: 'Dänisch',
    [Languages.SE]: 'Schwedisch',
};

export const formatLanguage = (lang: Language) => LanguageLabelMap[lang] ?? '';

export type ILanguageDictionary = Record<Language, IResourceDictionary>;

export default ILanguageDictionary;
