import { IResourceDictionary } from './../models/IResourceDicitionary';

export const de: IResourceDictionary = {
    ACTIONS: 'Aktionen',
    AGENT: 'Agent',
    ALERT_LOGIN_FAILED: 'Fehler bei der Anmeldung!',
    asa: 'ASA',
    ATTRIBUTE_BIRTHDATE: 'Geburtstag',
    ATTRIBUTE_FAMILY: 'Familie',
    ATTRIBUTE_HOBBIES: 'Hobbies',
    ATTRIBUTE_JOB: 'Job',
    ATTRIBUTE_LIVING: 'Wohnort',
    ATTRIBUTE_LOVELIFE: 'Liebesleben',
    ATTRIBUTE_NAME: 'Name',
    ATTRIBUTE_OTHER: 'Sonstiges',
    ATTRIBUTE_PHONE: 'Telefon',
    ATTRIBUTE_PREFRENCES: 'Vorlieben',
    ATTRIBUTE_RELATION: 'Beziehung/Sucht nach',
    ATTRIBUTE_SPECIAL: 'Besonderes',
    ATTRIBUTE_TATTOS: 'Tattoos/Piercings',
    ATTRIBUTE_VACATION: 'Urlaub',
    ATTRIBUTE_WORKING_HOURS: 'Arbeitszeiten',
    BIRTH_DAY_REMINDER_TEXT: '{name} hat heute Geburtstag 🎂',
    BIRTH_DAY: 'Geburtstag',
    BIRTH_MONTH: 'Geburtsmonat',
    BIRTH_YEAR: 'Geburtsjahr',
    BROWSER_NOTIFICATION_TEXT: 'Jetzt bearbeiten',
    BROWSER_NOTIFICATION_TITLE: '{project} - Ein neuer Dialog ist verfügbar!',
    BTN_BACK: 'Zurück',
    BTN_CONTINUE: 'Weiter',
    BTN_REPORT_CHAT: 'Chat melden',
    BTN_REQUEST_PICTURE: 'Bild anfordern',
    BTN_SEND_IMAGE: 'Bild senden',
    BTN_UPLOAD_IMAGE: 'Bild hochladen',
    BTN_USER_INFORMATION: 'User information',
    BTN_WAITING_ROOM: 'Ins Wartezimmer',
    CANCEL: 'Abbrechen',
    CHAT_GOT_A_BLINK: '{name} hat dir zugezwinkert 😉',
    CHAT_GOT_A_PRESENT: '{name} hat dir ein Geschenk geschickt 🎁',
    CHAT_INFO: 'Wichtige Informationen',
    CHAT_INTERFACE_EMPTYSTATE: 'Neukunde. Schreibe {name} die erste Nachricht!',
    CHAT_INTERFACE_SEND_A_MESSAGE: 'Sende deine Nachricht...',
    CHAT_INTERFACE_TODAY: 'Heute',
    CHAT_INTERFACE_YESTERDAY: 'Gestern',
    CHAT_SENT_A_BLINK: 'Du hast {name} einen Zwinker geschickt 😉',
    CHAT_SENT_A_PRESENT: 'Du hast ein Geschenk geschickt 🎁',
    CHAT_YOU: 'Du:',
    chatReport: 'Chat Report',
    closed: 'geschlossen',
    DASHBOARD_AGENCY: 'Agentur',
    DASHBOARD_AMOUNT_PER_IN: 'Ertrag pro In',
    DASHBOARD_AMOUNT_PER_OUT: 'Ertrag pro Out',
    DASHBOARD_DIALOGS_OPEN: 'Dialoge Offen',
    DASHBOARD_GLOBAL_STATISTICS: 'Globale Statistik',
    DASHBOARD_MONTHLY_IN_OUTS: 'Diesen Monat Ins / Outs',
    DASHBOARD_MY_PROFILE: 'Mein Profil',
    DASHBOARD_MY_STATISTICS: 'Meine Statistik',
    DASHBOARD_OPEN_TICKETS: 'Offene Tickets',
    DASHBOARD_TODAY_IN_OUTS: 'Heute Ins / Outs',
    DASHBOARD_TOTAL_DIALOGS: 'Dialoge gesamt',
    DASHBOARD_TOTAL_IN_OUTS: 'Gesamt Ins / Outs',
    DASHBOARD_TOTAL_OPEN: 'Ges. / Offen',
    DASHBOARD_WAITING_ROOM: 'Wartezimmer',
    DAY: 'Tag',
    de: 'Deutsch',
    DELETE_NOTE_MODAL_TEXT: 'Bist du sicher, das du diese Notiz löschen möchtest?',
    DELETE_NOTE_MODAL_TITLE: 'Notiz entfernen',
    DIALOG_WS_CONNECTION_LOST_TEXT:
        'Die Verbindung zum Chatservice wurde unterbrochen, es wird bereits versucht sich neu zu verbinden.',
    DISCONNECTED_TEXT: 'Verbindung getrennt, warte ein bisschen bis die Verbindung wieder hergestellt wird.',
    DISLIKE: 'Dislike',
    DROPZONE_DRAG_TEXT: 'Hier reinziehen!',
    DROPZONE_TEXT: 'Drücken oder reinziehen',
    en: 'Englisch',
    es: 'Spanisch',
    FAVORITE_CHATS: 'Chats',
    FAVORITE_FAVORITES: 'Favoriten',
    fr: 'Französisch',
    FRIDAY: 'Freitag',
    high: 'hoch',
    HOME_EMPTY_STATE: 'Auf der Suche nach Dialogen...',
    imageRequest: 'Bild Anfrage',
    inProgress: 'in Bearbeitung',
    it: 'Italienisch',
    LANG_DE: 'Deutsch',
    LANG_EN: 'Englisch',
    LAST_MESSAGE: 'Letzte Nachricht',
    LBL_DIALOG_LANGUAGE: 'Dialogsprache',
    LBL_FIRST_CONTACT: 'Erstkontakt',
    LBL_GLOBAL_INFO: 'Globale Information',
    LBL_GLOBAL_USER_INFORMATION_USER_TYPE: 'Usertyp',
    LBL_PARTNER_INTERACTION: 'Partner Interaktion',
    LBL_TICKET_DESCRIPTION: 'Beschreibung',
    LBL_TICKET_PRIORITY: 'Priorität',
    LBL_TICKET_STATE: 'Status',
    LBL_TICKET_TITLE: 'Titel',
    LBL_TICKET_TYPE: 'Anfragentyp',
    LBL_USER_INTERACTION: 'User Interaktion',
    LIKE: 'Like',
    LOGOUT_MODAL_BUTTON: 'Abmelden',
    LOGOUT_MODAL_TEXT: 'Bist du dir sicher, dass du dich von cherry abmelden möchtest?',
    LOGOUT_MODAL_TITLE: 'Abmelden',
    low: 'niedrig',
    medium: 'mittel',
    MENU_DARK_MODE: 'Nachtmodus',
    MENU_DASHBOARD: 'Dashboard',
    MENU_ENTER: 'Enter zum Senden erlaubt',
    MENU_OWN_PROFILE: 'Mein Profil',
    MENU_SOUND: 'Ton einstellen',
    MENU_WAITINGROOM: 'Wartezimmer',
    MESSAGE: 'Nachricht',
    MONDAY: 'Montag',
    MONTH: 'Monat',
    newCustomer: 'Neukunde',
    nl: 'Niederländisch',
    open: 'offen',
    PARTNER: 'Partner',
    PHONE_EXISTS: 'Eingetragen',
    pl: 'Polnisch',
    PRESENTS_TITLE: 'Wähle dein Geschenk für {name}',
    rejected: 'abgelehnt',
    resolved: 'gelöst',
    SATURDAY: 'Samstag',
    SAVE: 'Speichern',
    SELECT_TEMPLATE_IMAGE_TITLE: 'Wähle ein Bild zum versenden aus',
    SELECT_TEMPLATE_IMAGE_UPLOAD_TITLE: 'Wähle ein Bild zum hochladen aus',
    SEND_IMAGE_FILTER_BY_ALL: 'Alle',
    SEND_IMAGE_FILTER_BY: 'Filtern nach',
    SEND: 'Senden',
    SETTINGS_LOGOUT: 'Abmelden',
    SUNDAY: 'Sonntag',
    THURSDAY: 'Donnerstag',
    TRAITS_ASKME: 'Frag mich...',
    TRAITS_BODYJEWELRY_BRANDING: 'Branding',
    TRAITS_BODYJEWELRY_NOTHING: 'Nichts',
    TRAITS_BODYJEWELRY_PIERCED: 'Piercing',
    TRAITS_BODYJEWELRY_TATTOO_AND_PIERCED: 'Tattoo und Piercing',
    TRAITS_BODYJEWELRY_TATTOO: 'Tattoo',
    TRAITS_BODYJEWELRY_TITLE: 'Körperschmuck',
    TRAITS_EYECOLOR_BLACK: 'Schwarz',
    TRAITS_EYECOLOR_BLUE: 'Blau',
    TRAITS_EYECOLOR_BROWN: 'Braun',
    TRAITS_EYECOLOR_GREEN: 'Grün',
    TRAITS_EYECOLOR_GREY: 'Grau',
    TRAITS_EYECOLOR_TITLE: 'Augenfarbe',
    TRAITS_GENDER_MAN: 'Männlich',
    TRAITS_GENDER_TITLE: 'Geschlecht',
    TRAITS_GENDER_WOMAN: 'Weiblich',
    TRAITS_HAIRCOLOR_BLACK: 'Schwarz',
    TRAITS_HAIRCOLOR_BLONDE: 'Blond',
    TRAITS_HAIRCOLOR_BROWN: 'Braun',
    TRAITS_HAIRCOLOR_GREY: 'Grau',
    TRAITS_HAIRCOLOR_RED: 'Rot',
    TRAITS_HAIRCOLOR_TITLE: 'Haarfarbe',
    TRAITS_LIVING_ALONE: 'Alleine',
    TRAITS_LIVING_PARENTS: 'Bei den Eltern',
    TRAITS_LIVING_PARTNER: 'Mit Partner zusammen',
    TRAITS_LIVING_SHARED: 'Wohngemeinschaft',
    TRAITS_LIVING_TITLE: 'Wohnsituation',
    TRAITS_LIVING_UNIVERSITY: 'Studentenheim',
    TRAITS_RELATIONSHIP_COMPLICATED: 'Es ist kompliziert',
    TRAITS_RELATIONSHIP_DIVORCED: 'Geschieden',
    TRAITS_RELATIONSHIP_GIVEN: 'Vergeben',
    TRAITS_RELATIONSHIP_MARRIED: 'Verheiratet',
    TRAITS_RELATIONSHIP_OPEN: 'Offene Beziehung',
    TRAITS_RELATIONSHIP_SINGLE: 'Single',
    TRAITS_RELATIONSHIP_TITLE: 'Beziehungsstatus',
    TRAITS_RELATIONSHIP_WIDOW: 'Verwitwet',
    TRAITS_SIZE_TITLE: 'Größe',
    TRAITS_SMOKER_NO: 'Nichtraucher',
    TRAITS_SMOKER_OCCASIONALLY: 'Gelegenheitsraucher',
    TRAITS_SMOKER_TITLE: 'Raucher',
    TRAITS_SMOKER_VAPE: 'Dampfer',
    TRAITS_SMOKER_YES: 'Ja',
    TRAITS_STARSIGN_AQUARIUS: 'Wassermann',
    TRAITS_STARSIGN_ARIES: 'Widder',
    TRAITS_STARSIGN_CANCER: 'Krebs',
    TRAITS_STARSIGN_CAPRICORN: 'Steinbock',
    TRAITS_STARSIGN_GEMINI: 'Zwillinge',
    TRAITS_STARSIGN_LEO: 'Löwe',
    TRAITS_STARSIGN_LIBRA: 'Waage',
    TRAITS_STARSIGN_PISCES: 'Fische',
    TRAITS_STARSIGN_SAGITTARIUS: 'Schütze',
    TRAITS_STARSIGN_SCORPIO: 'Skorpion',
    TRAITS_STARSIGN_TAURUS: 'Stier',
    TRAITS_STARSIGN_TITLE: 'Sternzeichen',
    TRAITS_STARSIGN_VIGRO: 'Jungfrau',
    TUESDAY: 'Dienstag',
    UNLOCK_IMAGE_BUTTON: 'Freischalten ({coins}C)',
    UNLOCK_IMAGE_TEXT: 'Möchtest du das Bild von {name} freischalten?',
    UNLOCK_IMAGE_TITLE: 'Was steckt hinter dem Foto?',
    urgent: 'dringend',
    USER_INFO_DIALOG_MINOR_COINS: 'Coin-Guthaben',
    USER_INFO_DIALOG_MINOR_MESSAGES: 'Gesendete Nachrichten:',
    USER_INFO_DIALOG_TITLE: 'Informationen zum User:',
    USER_INFO_DIALOG_TRAITS: 'User Eigenschaften:',
    WAITING_ROOM_EMPTY_TITLE: 'Derzeit befinden sich keine Gespräche im Warteraum.',
    WAITING_ROOM: 'Wartezimmer',
    waitingRoom: 'Warteraum',
    WEDNESDAY: 'Mittwoch',
    YEAR: 'Jahr',
    YES: 'Ja',
    NO: 'Nein',
    BIRTH_DAY_MODAL_TITLE: 'Wurde schon zum Geburtstag gratuliert?',
    CHAT_MORE: 'Mehr anzeigen',
    DELETE: 'Löschen',
    USER_ABOUT: 'Über mich',
    dk: 'Dänisch',
    se: 'Schwedisch',
};

export default de;
