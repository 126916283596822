import './LoginView.scss';

import { useDispatch } from '@bnb-tech/core/src/StoreManager';
import { Paper } from '@mui/material';
import { useCallback } from 'react';

import AuthenticationActionCreator, { AuthenticationAction } from '../../actions/AuthenticationActionCreator';
import LogoOnly from '../../assets/images/logos/cherry-middlewriting.svg';
import LoginForm from './LoginForm';

export interface ILoginViewProps {}

export function LoginView(props: ILoginViewProps) {
    const dispatch = useDispatch();

    const handleSubmitClick = useCallback(
        (email: string, password: string, rememberLogin: boolean): Promise<AuthenticationAction> => {
            return dispatch(
                AuthenticationActionCreator.submitLogin({ password: password, userName: email })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any as Promise<AuthenticationAction>;
        },
        [dispatch]
    );

    return (
        <main className="flex justify-content-center align-items-center">
            {/* <img
                src={'https://cdn.pixabay.com/photo/2016/11/21/12/27/cherries-1845053_1280.jpg'}
                alt="background"
                style={{
                    position: 'absolute',

                    height: '100%',
                    width: '100%',
                    backgroundSize: 'cover',
                    filter: 'blur(5px)',
                }}
            /> */}
            <Paper
                className="flex no-grow login-paper full-width justify-content-center"
                style={{
                    zIndex: 10,
                    // backgroundColor: '#fff8', // '#424242aa',
                    WebkitBackdropFilter: 'blur(20px)',
                    backdropFilter: 'blur(20px)',
                }}
            >
                <img
                    draggable={false}
                    src={LogoOnly}
                    alt="cherry-logo"
                    style={{ height: 120, width: 'auto', userSelect: 'none', marginTop: -80 }}
                    className="spacing margin top bottom double"
                    loading="lazy"
                    decoding="async"
                />



                {/* <Typography className="spacing margin top bottom double" align="center" variant="h4">
                    Anmelden
                </Typography> */}
                <LoginForm onSubmitClick={handleSubmitClick} />
            </Paper>
        </main>
    );
}

export default LoginView;
