import { IEntityCollection } from '@bnb-tech/core/src/models/core/entity';
import { IDispatch } from '@bnb-tech/core/src/StoreManager';

import AuthenticationActionCreator from '../../../actions/AuthenticationActionCreator';
import { IErrorInfo } from '../../../models/contracts/errors/IErrorInfo';
import { IDialogReadResponse } from '../../../models/contracts/responses/chat/IDialogReadResponse';
import { INewMessageResponse } from '../../../models/contracts/responses/chat/INewMessageResponse';
import { IOnWriteResponse } from '../../../models/contracts/responses/chat/IOnWriteResponse';
import { IDialog } from '../../../models/domain/chat';
import ChatActionCreator from '../actions/ChatActionCreator';
import { IWebsocketListener } from '../models/IWebsocketListener';
import { ChatListenerEvents } from './ChatListenerEvents';

export const ChatListeners: (dispatch: IDispatch) => IWebsocketListener[] = (dispatch: IDispatch) => {
    return [
        {
            method: ChatListenerEvents.REFRESH,
            handler: (url: string, data: IDialog) => {
                dispatch(ChatActionCreator.dialogRefresh(data));
            },
        },
        {
            method: ChatListenerEvents.REFRESH_DIALOGS,
            handler: (url: string, data: IEntityCollection<IDialog>) => {
                dispatch(ChatActionCreator.dialogRefresh(data));
            },
        },
        {
            method: ChatListenerEvents.NEW_MESSAGE,
            handler: (url: string, data: INewMessageResponse) => {
                dispatch(ChatActionCreator.newMessage(data));
            },
        },
        {
            method: ChatListenerEvents.ON_WRITE,
            handler: (url: string, data: IOnWriteResponse) => dispatch(ChatActionCreator.onWrite(data)),
        },
        // {
        //     method: ChatListenerEvents.COINS,
        //     handler: (url: string, data: ICoinsData) => {
        //         dispatch(ChatActionCreator.updateCoins(data));
        //     },
        // },
        {
            method: ChatListenerEvents.DIALOG_READ,
            handler: (url: string, data: IDialogReadResponse) => {
                dispatch(ChatActionCreator.dialogRead(data));
            },
        },
        {
            method: ChatListenerEvents.KICK,
            handler: (url: string, data: undefined) => {
                dispatch(AuthenticationActionCreator.submitLogout());
                dispatch(AuthenticationActionCreator.sessionExpired());
            },
        },
        {
            method: ChatListenerEvents.SOFT_KICK,
            handler: (url: string, data: undefined) => {
                dispatch(ChatActionCreator.softKick());
            },
        },
        {
            method: ChatListenerEvents.ON_CONNECTED,
            handler: (url: string, data: undefined) => {
                dispatch(ChatActionCreator.onConnected(true));
            },
        },
        {
            method: ChatListenerEvents.ERROR,
            handler: (url: string, data: IErrorInfo) => {
                // TODO:
            },
        },
    ];
};
