import type { IOnRequestResultPayload } from '../ddlewares/fetchMiddleware';
import { useDispatch as reduxUseDispatch } from 'react-redux';
import type { Dispatch } from 'redux';

import type { IAction } from '../actions';

export const useDispatch: <AppDispatch extends Dispatch<IAction> = Dispatch<IAction>>() => AppDispatch =
    reduxUseDispatch;

export const injectAsyncActionErrorHandler = <A extends IAction = IAction>(
    action: A,
    onError: (data?: IOnRequestResultPayload<{}>) => void
) => ({
    ...action,
    onError: onError,
});

export default useDispatch;
