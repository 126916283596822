import Logger from '@bnb-tech/core/src/diagnostics/Logger';
import { IDictionary } from '@bnb-tech/core/src/models/helper/IDictionary';
import { isNullOrUndefined } from '@bnb-tech/core/src/typeguards';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

import Config from '../../config';
import { IWebsocketListener } from './models/IWebsocketListener';
import { IWebSocketMessage } from './models/IWebSocketMessage';
import { ReadyState, ReadyStates } from './models/ReadyState';

export class ChatSocket {
    private _url: string;
    private _connection!: HubConnection;
    private _state: ReadyState;
    private _listeners: IDictionary<IWebsocketListener> = {};
    private _isForced: boolean = false;
    private _connectionTimeout: number | undefined;
    private _queue: IWebSocketMessage[] = [];

    constructor(url: string, listeners: IWebsocketListener[]) {
        this._url = url;
        this._state = ReadyStates.CLOSED;

        for (const listener of listeners) {
            this._listeners[listener.method] = listener;
        }
    }

    public start(
        onConnected?: (url: string) => void,
        onClosed?: (url: string, isForced: boolean) => void,
        onReconnect?: (url: string) => void,
        onError?: (url: string) => void
    ) {
        this._isForced = false;

        if (this.state === ReadyStates.OPEN) {
            return;
        }

        this._state = ReadyStates.CONNECTING;
        try {
            this._connection = new HubConnectionBuilder()
                .withUrl(this._url)
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: (retryContext) => {
                        if (retryContext.previousRetryCount === 0) {
                            return 1000;
                        }

                        return retryContext.previousRetryCount * 2000;
                    },
                })
                .build();

            // this.connection.keepAliveIntervalInMilliseconds = 30000;
            this._connection.serverTimeoutInMilliseconds = 60000;

            this._connection.onreconnecting((error?: Error | undefined) => {
                this._state = ReadyStates.CONNECTING;
                if (onClosed) {
                    onClosed(this._url, this._isForced);
                }
            });

            this._connection.onreconnected(() => {
                this._state = ReadyStates.CONNECTING;

                try {
                    if (this._queue.length > 0) {
                        for (const message of this._queue) {
                            this.send(message);
                        }
                        this._queue = [];
                    }
                } catch {
                    // ok we really fucked up
                }

                if (onReconnect) {
                    onReconnect(this._url);
                }
            });

            this._connection.onclose((error?: Error | undefined) => {
                Logger.log('close', error);

                this._state = ReadyStates.CLOSED;

                if (error && onError) {
                    if (!this._isForced) {
                        onError(this._url);
                    }
                }
                if (onClosed) {
                    onClosed(this._url, this._isForced);
                }
            });

            for (const [, value] of Object.entries(this._listeners)) {
                this._connection.on(value.method, (data) => {
                    value.handler(this._url, data);
                });
            }
            // eslint-disable-next-line no-void
            void this._connection.start().then(() => {
                this._state = ReadyStates.OPEN;

                try {
                    if (this._queue.length > 0) {
                        for (const message of this._queue) {
                            this.send(message);
                        }
                        this._queue = [];
                    }
                } catch {
                    // ok we really fucked up
                }

                try {
                    if (onConnected) {
                        onConnected(this._url);
                    }
                } catch (e) {
                    Logger.log('CHAT WARNING', (e as Error)?.message);
                }
            });
        } catch (e) {
            if (this._connectionTimeout) {
                clearTimeout(this._connectionTimeout);
            }

            this._connectionTimeout = window.setTimeout(() => {
                if (onReconnect) {
                    onReconnect(this._url);
                }
                this.start(onConnected, onClosed, onReconnect);
            }, Config.WS_RECONNECT_INTERVAL);
        }
    }

    public stop(force?: boolean) {
        this._state = ReadyStates.CLOSING;

        if (!isNullOrUndefined(force)) {
            this._isForced = force;
        }

        try {
            // eslint-disable-next-line no-void
            void this._connection.stop();
        } catch {
            //
        }

        this._state = ReadyStates.CLOSED;
    }

    public send(message: IWebSocketMessage) {
        if (this._connection.state === HubConnectionState.Connected) {
            // send message!
            if (message.payload) {
                // eslint-disable-next-line no-void
                void this._connection.invoke(message.event, message.payload);
            } else {
                // eslint-disable-next-line no-void
                void this._connection.invoke(message.event);
            }
        } else {
            // we need to queue up the messages
            this._queue.push(message);
        }
    }

    public get state(): ReadyState {
        return this._state;
    }
}

export default ChatSocket;
