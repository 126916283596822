import FetchService, { HttpMethods } from '@bnb-tech/core/src/FetchService';
import {
    ActionCreator,
    createAsyncActionEnum,
    DeclareAsyncAction,
    IAsyncAction,
    IBaseAction,
} from '@bnb-tech/core/src/StoreManager';

import ApiRoutes from '../config/ApiRoutes';
import { ILoginCredentials } from '../models/contracts/requests';
import { ILoginResult, ILogoutResult } from '../models/contracts/responses';

export const AuthenticationActions = {
    ...createAsyncActionEnum('SUBMIT_LOGIN'),
    ...createAsyncActionEnum('SUBMIT_LOGOUT'),
    SESSION_EXPIRED: 'SESSION_EXPIRED',
} as const;

type ISessionExpiredAction = IBaseAction<typeof AuthenticationActions.SESSION_EXPIRED>;

export type AuthenticationAction =
    | DeclareAsyncAction<typeof AuthenticationActions.SUBMIT_LOGIN, ILoginResult>
    | DeclareAsyncAction<typeof AuthenticationActions.SUBMIT_LOGOUT, ILogoutResult>
    | ISessionExpiredAction;

export class AuthenticationActionCreator {
    public static submitLogin(credentials: ILoginCredentials): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.Identity.Login);

        return ActionCreator.createAsyncAction(
            AuthenticationActions.SUBMIT_LOGIN,
            [
                AuthenticationActions.SUBMIT_LOGIN_REQUEST,
                AuthenticationActions.SUBMIT_LOGIN_RESPONSE,
                AuthenticationActions.SUBMIT_LOGIN_FAILED,
            ],
            () => FetchService.fetch(href, credentials, HttpMethods.POST)
        );
    }

    public static submitLogout(): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.Identity.Logout);

        return ActionCreator.createAsyncAction(
            AuthenticationActions.SUBMIT_LOGOUT,
            [
                AuthenticationActions.SUBMIT_LOGOUT_REQUEST,
                AuthenticationActions.SUBMIT_LOGOUT_RESPONSE,
                AuthenticationActions.SUBMIT_LOGOUT_FAILED,
            ],
            () => FetchService.fetch(href, undefined, HttpMethods.POST)
        );
    }

    public static sessionExpired(): ISessionExpiredAction {
        return ActionCreator.createAction(AuthenticationActions.SESSION_EXPIRED);
    }
}

export default AuthenticationActionCreator;
