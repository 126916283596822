import {
    addDays,
    add as addDuration,
    addHours,
    addMilliseconds,
    addMinutes,
    addMonths,
    addSeconds,
    addYears,
    format,
    formatRelative,
    isValid,
    parse,
    parseISO,
    type Locale,
} from 'date-fns';
import { de, enUS } from 'date-fns/locale';

import Logger from '../../diagnostics/Logger';
import { isNullOrUndefined, isNumber, isString } from '../../typeguards';
import CoreDuration, { type IDuration } from '../CoreDuration';
import { DurationUnits, type DurationUnit } from '../CoreDuration/DurationFormat';
import { DateFormats, type DateFormat } from './DateFormat';

// subscribe to the store to retrieve the current session
// let currentSession: ISession | undefined;

// if (Intl === null) {
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     Intl = {} as any;
// }

if (Intl && !('RelativeTimeFormat' in Intl)) {
    // eslint-disable-next-line
    require('intl-relative-time-format');
    // eslint-disable-next-line
    require('intl-relative-time-format/locale-data/en-GB');
    // eslint-disable-next-line
    require('intl-relative-time-format/locale-data/de-DE');
}

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// const currentIntl = new (Intl as any).RelativeTimeFormat();
export type RelativeTimeUnit = 'year' | 'quarter' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second';
export enum RelativeTimeUnits {
    YEAR = 'year',
    QUARTER = 'quarter',
    MOTH = 'month',
    WEEK = 'week',
    DAY = 'day',
    HOUR = 'hour',
    MINUTE = 'minute',
    SECOND = 'second',
}

// StoreManager.onStoreInitialized.on((store) => {
//     if (store) {
//         store.subscribe(() => {
//             const newSession = getSession(getAuthenticationState(StoreManager.store.getState()));
//             if (newSession !== currentSession) {
//                 currentSession = newSession;
//                 const culture = getUiCultureOfSession(currentSession);
//                 // create a new Number Format with the specified session
//                 // disable any if RelativeTimeFormat is implemented in TS
//                 // eslint-disable-next-line @typescript-eslint/no-explicit-any
//                 currentIntl = new (Intl as any).RelativeTimeFormat(culture, { numeric: 'auto', style: 'long' });
//             }
//         });
//     }
// });

const locales = { de: de, en: enUS };

const MATCHER: Array<[string, number]> = [
    ['second', 1000],
    ['minute', 60],
    ['hour', 60],
    ['day', 24],
    ['week', 7],
    ['month', 4.43],
    ['year', 12],
];

export class CoreDate {
    // eslint-disable-next-line max-len
    public static ISO8061Regex: RegExp =
        /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;

    private _date: Date;
    private _locale: string;

    public static isISOString(date: string) {
        return this.ISO8061Regex.test(date);
    }

    public static isValid(date: string | number | Date, dateFormat: DateFormat = DateFormats.DATE_TIME): boolean {
        if (isString(date)) {
            try {
                if (this.isISOString(date)) {
                    return isValid(parseISO(date));
                } else {
                    const [lang] = CoreDate._getLanguage().split('-') as Array<keyof typeof locales>;
                    const lc: Locale = locales[lang ?? 'en'];
                    const d = parse(date, CoreDate._getDateFormatString(dateFormat), new Date(), { locale: lc });
                    return isValid(d);
                }
            } catch (e) {
                return false;
            }
        } else if (isNumber(date)) {
            return true;
        } else {
            return date instanceof Date && !isNaN(date.getTime()) && isValid(date);
        }
    }

    /**
     * parses the given ISO 8061 Duration string to ticks (ms)
     *
     * @static
     * @param {string} duration
     * @returns
     * @memberof ImDate
     */
    public static parseDuration(duration: string) {
        return CoreDuration.toMilliseconds(CoreDuration.parse(duration));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static parse(value?: any, dateFormat: DateFormat = DateFormats.DATE_TIME): CoreDate {
        if (this.isISOString(value)) {
            return new CoreDate(value);
        } else {
            const [lang] = CoreDate._getLanguage().split('-') as Array<keyof typeof locales>;
            const lc: Locale = locales[lang ?? 'en'];
            const d = parse(value, CoreDate._getDateFormatString(dateFormat), new Date(), { locale: lc });
            return new CoreDate(d);
        }
    }

    // public static toRelativeString(value: number, unit?: RelativeTimeUnit): string {
    public static toRelative(prev: Date, now: Date = new Date(), unit?: RelativeTimeUnit): string {
        if (isNullOrUndefined(unit)) {
            let currentMatcher = MATCHER[0][0];
            let currentValue = prev.getTime() - now.getTime();
            const isAgo = currentValue < 0;
            if (isAgo) {
                currentValue *= -1;
            }

            for (const [matcher, val] of MATCHER) {
                const newVal = currentValue / val;
                if (newVal < 1) {
                    break;
                }
                currentMatcher = matcher;
                currentValue = newVal;
            }

            if (isAgo) {
                currentValue *= -1;
            }

            if (Intl) {
                return new Intl.RelativeTimeFormat().format(
                    Math.round(currentValue),
                    currentMatcher as Intl.RelativeTimeFormatUnit
                );
            }

            return formatRelative(prev, now);

            // return currentIntl.format(Math.round(currentValue), currentMatcher);
        } else {
            const currentValue = prev.getTime() - now.getTime();

            if (Intl) {
                return new Intl.RelativeTimeFormat().format(Math.round(currentValue), unit);
            }

            return formatRelative(prev, now);
        }
    }

    public static formatDate(date: string | number | Date, dateFormat: DateFormat | string) {
        return CoreDate.parse(date).format(dateFormat);
    }

    private static _getLanguage() {
        return navigator.language;
    }

    private static _getDateFormatString(dateFormat: DateFormat | string) {
        const formatStrings = {
            [DateFormats.TIME]: 'p',
            [DateFormats.DATE]: 'P',
            [DateFormats.DATE_TIME]: 'Pp',
            [DateFormats.LONG_DATE_TIME]: 'PPpp',
            [DateFormats.SHORT_DATE]: 'dd.MM',
            [DateFormats.LONG_DAY]: 'EEEE, d LLLLP',
            [DateFormats.MONTH]: 'LLLL',
            [DateFormats.HOUR_MINUTE]: 'HH:mm',
        } as const;

        return dateFormat in formatStrings ? formatStrings[dateFormat as DateFormat] : dateFormat;
    }

    constructor(date: string | number | Date = new Date(), language?: string) {
        if (CoreDate.isValid(date)) {
            this._date = new Date(date);
        } else {
            Logger.error(`Retrieved invalid date! ${date}`);
            this._date = new Date();
        }

        this._locale = language ? language : CoreDate._getLanguage();
    }

    /**
     * adds the given amount and creates a new ImDate
     *
     * @param {number} amount
     * @param {DurationUnit} unit
     * @returns {CoreDate}
     * @memberof ImDate
     */
    public add(amount: number, unit: DurationUnit): CoreDate {
        let newDate: Date;

        switch (unit) {
            case DurationUnits.MILLISECONDS: {
                newDate = addMilliseconds(this.date, amount);
                break;
            }
            case DurationUnits.SECONDS: {
                newDate = addSeconds(this.date, amount);
                break;
            }
            case DurationUnits.MINUTES: {
                newDate = addMinutes(this.date, amount);
                break;
            }
            case DurationUnits.HOURS: {
                newDate = addHours(this.date, amount);
                break;
            }
            case DurationUnits.DAYS: {
                newDate = addDays(this.date, amount);
                break;
            }
            case DurationUnits.MONTHS: {
                newDate = addMonths(this.date, amount);
                break;
            }
            case DurationUnits.YEARS: {
                newDate = addYears(this.date, amount);
                break;
            }
            default:
                newDate = this.date;
        }

        return new CoreDate(newDate);
    }

    public addDuration(duration: IDuration) {
        return addDuration(this.date, duration);
    }

    public toJSON(): string {
        return this._date.toJSON();
    }

    public format(dateFormat: DateFormat | string = DateFormats.LONG_DATE_TIME): string {
        try {
            const [lang] = this._locale.split('-') as Array<keyof typeof locales>;

            return format(this._date, CoreDate._getDateFormatString(dateFormat), {
                locale: locales[lang ?? 'en'],
            });
        } catch (e) {
            throw e;
        }
    }

    public toISOString(): string {
        return this._date.toISOString();
    }

    public get locale(): string {
        return this._locale;
    }

    public get date(): Date {
        return this._date;
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCoreDate = (x: any): x is CoreDate => x instanceof CoreDate;

export default CoreDate;
