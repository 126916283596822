import { useDispatch, useSelector } from '@bnb-tech/core/src/StoreManager';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import IMG from '../../../assets/images/emptyStates/chat_empty.svg';
import ActivityIndicator from '../../../components/ActivityIndicator';
import EmptyState from '../../../components/EmptyState/EmptyState';
import Config from '../../../config';
import IIFE from '../../../core/IIFE';
import ChatActionCreator from '../../../services/Chat/actions/ChatActionCreator';
import { getEstablishedState } from '../../../services/Chat/selectors/ChatSelectors';
import useTranslation from '../../../services/i18n/core/useTranslation';
import useLoadingIndicator from '../components/GlobalLoadingIndicator/useLoadingIndicator';
import WaitingRoom from '../WaitingRoom/WaitingRoom';

export interface IHomeProps {}

export const Home = (props: IHomeProps) => {
    const { setLoading } = useLoadingIndicator();
    const dispatch = useDispatch();
    const [isOnline, setOnline] = useState(navigator.onLine ?? true);
    const isConnectionEstablished = useSelector(getEstablishedState);
    const { HOME_EMPTY_STATE } = useTranslation();

    useEffect(() => {
        const handleOnline = () => setOnline(true);
        const handleOffline = () => setOnline(false);

        if (navigator.onLine !== undefined) {
            window.addEventListener('offline', handleOffline);
            window.addEventListener('online', handleOnline);
        }

        return () => {
            if (navigator.onLine !== undefined) {
                window.removeEventListener('offline', handleOffline);
                window.removeEventListener('online', handleOnline);
            }
        };
    }, [dispatch, setLoading]);

    useEffect(() => {
        if (!isConnectionEstablished) {
            return;
        }

        const timeoutTime = getIntervalByNetworkConnection();
        let timeoutHandle: number | null = null;

        const handler = () => {
            IIFE(async () => {
                setLoading(true);
                await dispatch(ChatActionCreator.getAvailableChat());
                setLoading(false);
                // timeoutHandle = window.setTimeout(handler, timeoutTime);
            });
        };

        handler();

        timeoutHandle = window.setInterval(handler, timeoutTime);

        return () => {
            if (timeoutHandle) {
                window.clearInterval(timeoutHandle);
            }
        };
    }, [dispatch, setLoading, isConnectionEstablished]);

    // if (isLoading) {
    //     return <LoadingOverlay />;
    // }

    return (
        <div className="home">
            <div className="flex centered">
                {!isOnline ? (
                    <Typography variant="h4" color="error" style={{ textAlign: 'center' }}>
                        You seem to be offline!
                    </Typography>
                ) : !isConnectionEstablished ? (
                    <>
                        <ActivityIndicator />
                        <Typography variant="h4" color="error" style={{ textAlign: 'center' }}>
                            Establishing connection...
                        </Typography>
                    </>
                ) : (
                    <EmptyState title={HOME_EMPTY_STATE} image={IMG} description={<ActivityIndicator />} />
                )}
            </div>

            <WaitingRoom />
        </div>
    );
};

export default Home;

export function getIntervalByNetworkConnection() {
    // const effectiveConnectionType = navigator.co
    // get network speed
    // if slow, increase interval
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const effectiveConnectionType = (navigator as any)?.connection?.effectiveType ?? '4g';
    if (effectiveConnectionType === 'slow-2g' || effectiveConnectionType === '2g') {
        return Config.GET_DIALOG_INTERVAL * 2;
    }
    return Config.GET_DIALOG_INTERVAL;
}
