export type TicketType = 'imageRequest' | 'chatReport';

export enum TicketTypes {
    IMAGE_REQUEST = 'imageRequest',
    CHAT_REPORT = 'chatReport',
}

const TicketTypeLabelMap = {
    [TicketTypes.IMAGE_REQUEST]: 'Bild Anfrage',
    [TicketTypes.CHAT_REPORT]: 'Chat Report',
};

export const formatTicketType = (type: TicketType) => TicketTypeLabelMap[type] ?? '';
