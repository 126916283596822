import type { IAsyncAction } from '../../StoreManager/actions';
import type { IDispatch, IState } from '../../StoreManager/models';
import { isString } from '../../typeguards';

import dispatchApiFetchFailure from './dispatchApiFetchFailure';

export async function handleApiFetchResponseError(
    dispatch: IDispatch,
    state: IState,
    calledAction: IAsyncAction,
    response: Response,
    failureType: string,
    payload: {},
    responseJson?: string | object
) {
    // let errorText = response.statusText;
    // let errorInfo: IErrorInfo = ErrorInfo.create(response.status, errorText);

    // let errorType = ErrorTypes.BACKEND;

    // determine the errorType and the statusCode
    if (response.status === 404) {
        // errorType = ErrorTypes.NETWORK;
        // TODO: check handling if it needs to be send as Error
        // errorInfo.severity = ErrorSeverity.WARNING;
    }

    // check if the response has an ErrorInfo in its body
    const value = responseJson;
    let error = undefined;

    if (value) {
        try {
            if (isString(value)) {
                error = JSON.parse(value);
            } else {
                error = value;
            }
            // parse the errorInfo from the Text
            // // check if the body is an errorInfo
            // if (errorInfo && isErrorInfo(errorInfo)) {
            //     errorInfo.statusCode = response.status;
            //     errorText = errorInfo.text;
            // }
        } catch (e) {
            // faulty errorInfo object was given
        }
    }

    return await dispatchApiFetchFailure(
        dispatch,
        state,
        calledAction,
        failureType,
        '',
        payload,
        responseJson,
        response,
        error
        // errorType,
        // errorInfo
    );
}

export default handleApiFetchResponseError;
