import { shallowEqual, useSelector } from '@bnb-tech/core/src/StoreManager';
import { AppBar, Toolbar, Typography, useScrollTrigger } from '@mui/material';
import { memo, useCallback, useState } from 'react';

import LogoOnly from '../../../assets/images/logos/cherry-middlewriting.svg';
import { HOME_PATH } from '../../../config/Paths';
import useHistoryPush from '../../../hooks/useHistoryPush';
import { IState } from '../../../models/state';
import { getUser } from '../../../selectors/AuthenticationSelectors';
import { getAvailableDialogInfo } from '../../../selectors/ChatSelectors';
import {
    getAvailableDialog,
    getAvailableDialogStart,
    getCurrentProject,
} from '../../../services/Chat/selectors/ChatSelectors';
import ChatPanelCountDown from '../components/ChatPanelCountDown/ChatPanelCountDown';
import GlobalLoadingIndicator from '../components/GlobalLoadingIndicator/GlobalLoadingIndicator';
import SignOutComponent from '../components/SignOutComponent';
import { GDWAInfo } from '../MessagesPage/ChatPanel/components/GDWAInfo';
import HeaderMenu from './HeaderMenu';

export interface IHeaderProps {}

export const Header = memo((props: IHeaderProps) => {
    const [signOutDialogOpen, setSignOutDialogOpen] = useState<boolean>(false);
    // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    const { availableDialogInfo, availableDialogStart, availableDialog, user, currentProject } = useSelector(
        (state: IState) => ({
            availableDialogInfo: getAvailableDialogInfo(state),
            availableDialog: getAvailableDialog(state),
            availableDialogStart: getAvailableDialogStart(state),
            currentProject: getCurrentProject(state),
            user: getUser(state),
        }),
        shallowEqual
    );

    const openSignOutDialog = useCallback(() => setSignOutDialogOpen(true), []);
    const closeSignOutDialog = useCallback(() => setSignOutDialogOpen(false), []);

    const handleLogoClick = useHistoryPush(HOME_PATH);

    return (
        <AppBar
            position="sticky"
            variant="outlined"
            color={trigger ? 'default' : 'transparent'}
            elevation={trigger ? 4 : 0}
            style={{ transition: trigger ? '150ms ease-in' : '150ms ease-out' }}
        >
            <Toolbar className="flex row container justify-content-space-between align-items-center">
                <div className="flex row wrap no-grow pointer centered" onClick={handleLogoClick}>
                    <img
                        draggable={false}
                        src={LogoOnly}
                        alt="cherry-logo"
                        style={{ height: 40, width: 'auto', userSelect: 'none' }}
                        loading="lazy"
                        decoding="async"
                    />
                    {/* {false && currentProject && (

                    )} */}
                    {currentProject?.logoUrl ? (
                        <img
                            draggable={false}
                            src={currentProject.logoUrl}
                            alt="project-logo"
                            style={{ height: 40, width: 'auto', userSelect: 'none', marginLeft: 8 }}
                            loading="lazy"
                            decoding="async"
                        />
                    ) : (
                        currentProject && (
                            <Typography variant="h4" className="spacing margin left">
                                {currentProject.name}
                            </Typography>
                        )
                    )}
                </div>

                <div className="flex" id="header-center" />

                <div className="flex row no-grow align-items-center">
                    <div className="flex row no-grow spacing margin right" id="header-right">
                        <GDWAInfo />

                        <div className="flex row centered" style={{ order: 2 }}>
                            {availableDialog && availableDialogInfo && (
                                <ChatPanelCountDown
                                    availableDialogStart={availableDialogStart}
                                    key={availableDialogInfo?.id}
                                    dialogId={availableDialogInfo.id}
                                />
                            )}
                        </div>
                    </div>
                    <HeaderMenu user={user} onOpenSignOutDialog={openSignOutDialog} />
                </div>
            </Toolbar>
            <GlobalLoadingIndicator />
            {signOutDialogOpen && <SignOutComponent onClose={closeSignOutDialog} open={signOutDialogOpen} />}
        </AppBar>
    );
});

export default Header;
