import { Typography, TypographyProps } from '@mui/material';
import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';

export interface ICountDownProps {
    endDate: Date;
    typographyProps?: TypographyProps;
    onEnd(): void;
    onTick?(duration: Duration): void;
}

export const CountDown = (props: ICountDownProps) => {
    const { endDate, onEnd, typographyProps, onTick } = props;
    const [duration, setduration] = useState<Duration>(intervalToDuration({ end: endDate, start: new Date() }));

    useEffect(() => {
        const interval = window.setInterval(() => {
            if (endDate.getTime() <= new Date().getTime()) {
                onEnd();
                window.clearInterval(interval);
                return;
            }
            const dur = intervalToDuration({ end: endDate, start: new Date() });
            setduration(dur);
            onTick && onTick(dur);
        }, 1000);

        return () => {
            window.clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate, onEnd]);

    const { seconds = 0, minutes = 0, hours = 0, days = 0 } = duration;

    return (
        <div className="flex row no-grow">
            {days > 0 && (
                <Typography variant="h5" {...typographyProps}>
                    {formatNumber(days)}:
                </Typography>
            )}
            {hours > 0 && (
                <Typography variant="h5" {...typographyProps}>
                    {formatNumber(hours)}:
                </Typography>
            )}
            <Typography variant="h5" {...typographyProps}>
                {hours > 0 ? formatNumber(minutes) : minutes}:
            </Typography>
            <Typography variant="h5" {...typographyProps}>
                {formatNumber(seconds)}
            </Typography>
        </div>
    );
};

const formatNumber = (value: number) => String(value).padStart(2, '0');

export default CountDown;
