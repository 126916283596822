import middlewaresToApply from '../config/middlewaresToApply';
import type { IDispatch, IMiddleware, IState } from 'StoreManager/models';
import { isArray } from '../typeguards';
import CustomEvent from '../ui_helper/CustomEvent';
import { applyMiddleware } from 'redux';

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type StoreEnhancer<Ext, StateExt extends {} = {}> = import('redux').StoreEnhancer<{ dispatch: Ext }, StateExt>;

export class MiddlewareManager {
    private static _instance: MiddlewareManager;

    private _onChangeEvent: CustomEvent<IMiddleware[]> = new CustomEvent<IMiddleware[]>();
    private _middlewares: IMiddleware[] = [...middlewaresToApply];

    public static getInstance(): MiddlewareManager {
        return this._instance || (this._instance = new this());
    }

    public static applyMiddleware<D = IDispatch, S extends {} = IState>(
        middlewares: Array<IMiddleware<D, S>>
    ): StoreEnhancer<D, S> {
        return applyMiddleware<D, S>(...middlewares);
    }

    private constructor() {
        //
    }

    public applyMiddleware(): StoreEnhancer<IDispatch, IState> {
        return MiddlewareManager.applyMiddleware<IDispatch, IState>(this.middlewares);
    }

    public register(middleware: IMiddleware | IMiddleware[]): void {
        if (isArray(middleware)) {
            this.middlewares = new Array<IMiddleware>().concat(this._middlewares, middleware);
        } else {
            this.middlewares.push(middleware);
        }
        this._onChangeEvent.trigger(this.middlewares);
    }

    public concat(...middlewares: IMiddleware[] | IMiddleware[][]): void {
        this.middlewares = new Array<IMiddleware>().concat(this.middlewares, ...middlewares);
        this._onChangeEvent.trigger(this.middlewares);
    }

    // public unregister(name: string): void {
    //     delete this._middlewares[name];
    //     this._onChangeEvent.trigger(this.middlewares);
    // }

    public addListener(handler: (middlewares: IMiddleware[]) => void): void {
        this._onChangeEvent.on(handler);
    }

    public removeListener(handler: (middlewares: IMiddleware[]) => void): void {
        this._onChangeEvent.off(handler);
    }

    public get middlewares(): IMiddleware[] {
        return this._middlewares;
    }

    public set middlewares(value: IMiddleware[]) {
        this._middlewares = value;
    }
}

export default MiddlewareManager.getInstance();
