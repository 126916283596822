import { IReducersMapObject, ReducerManager } from '@bnb-tech/core/src/StoreManager';

import { UserJobTemplateReducer } from '../actions/automation/UserJobTemplate.actioncreator';
import { IState } from '../models/state';
import ChatReducer from '../services/Chat/reducers/ChatReducer';
import { NotificationReducer } from '../services/Notifications/reducer/NotificationReducer';
import AnimationReducer from './Animation.reducer';
import AuthenticationReducer from './AuthenticationReducer';
import { DashboardReducer } from './Dashboard.reducer';
import { UserReducer } from './User.reducer';

export const AppReducers: IReducersMapObject<IState> = {
    authentication: AuthenticationReducer,
    user: UserReducer,
    chat: ChatReducer,
    notifications: NotificationReducer,
    dashboard: DashboardReducer,
    animation: AnimationReducer,
    userJobTemplate: UserJobTemplateReducer,
};

export const AppReducer = ReducerManager.combine<IState>(AppReducers);

export default AppReducer;
