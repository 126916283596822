import { useDispatch } from '@bnb-tech/core/src/StoreManager';
import { memo, useCallback } from 'react';

import AuthenticationActionCreator from '../../../../actions/AuthenticationActionCreator';
import Logout from '../../../../assets/images/modals/arrow.svg';
import IconModal from '../../../../components/IconModal';
import useTranslation from '../../../../services/i18n/core/useTranslation';

export interface ISignOutComponentProps {
    open: boolean;
    onClose(): void;
}

export const SignOutComponent = memo((props: ISignOutComponentProps) => {
    const { open, onClose } = props;
    const dispatch = useDispatch();
    const { LOGOUT_MODAL_TITLE, LOGOUT_MODAL_BUTTON, LOGOUT_MODAL_TEXT } = useTranslation();

    const handleLogoutPress = useCallback(() => dispatch(AuthenticationActionCreator.submitLogout()), [dispatch]);

    return (
        <IconModal
            icon={Logout}
            title={LOGOUT_MODAL_TITLE}
            text={LOGOUT_MODAL_TEXT}
            onClose={onClose}
            open={open}
            button={{
                onClick: handleLogoutPress,
                title: LOGOUT_MODAL_BUTTON,
            }}
        />
    );
});

export default SignOutComponent;
