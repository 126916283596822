import type { ComponentType } from 'react';
import { PersistGate as PG, type PersistGateProps } from 'redux-persist/integration/react';
import type { IPersistor } from './models/IPersistor';

export interface IPersistGateProps extends PersistGateProps {
    persistor: IPersistor;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PersistGate: ComponentType<IPersistGateProps> = PG as any;

export default PersistGate;
