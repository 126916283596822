import type { ILink } from '../link';

export interface IPaginatedCollection<T> {
    items: T[];
    count: number;
    totalCount?: number;
    page?: number;
    pageSize?: number;
    self?: ILink;
    next?: ILink;
    prev?: ILink;
}

export const createPaginatedCollection = <T>(items?: T[]): IPaginatedCollection<T> => ({
    items: items ?? [],
    count: items?.length ?? 0,
});
