import type { IDictionary } from '../dels/helper/IDictionary';

import type { IStorage } from './models/IStorage';

export type ISimpleStorage = IDictionary<string>;

/**
 * Represents a Storage api, which writes to a simple Object
 *
 * @class SimpleStorage
 * @extends {Storage}
 */
export class SimpleStorage implements IStorage {
    private static _instance: SimpleStorage;

    private _simpleStorage: ISimpleStorage = Object.create(null);

    public static getInstance(): SimpleStorage {
        return this._instance || (this._instance = new this());
    }

    constructor() {
        this._simpleStorage = Object.create(null);
    }

    public clear(): void {
        this._simpleStorage = Object.create(null);
    }

    public getItem(key: string): string | null {
        if (key in this._simpleStorage) {
            return this._simpleStorage[key];
        }

        return null;
    }

    public setItem(key: string, data: string): void {
        this._simpleStorage[key] = data;
    }

    public key(index: number): string | null {
        const keys = Object.keys(this._simpleStorage);
        if (keys.length > index) {
            return this._simpleStorage[keys[index]];
        }
        return null;
    }

    public removeItem(key: string): void {
        if (key in this._simpleStorage) {
            delete this._simpleStorage[key];
        }
    }

    public get length(): number {
        return Object.keys(this._simpleStorage).length;
    }
}

export default SimpleStorage.getInstance();
