import { FetchService } from '@bnb-tech/core/src/FetchService';
import { IEntityCollection } from '@bnb-tech/core/src/models/core/entity';
import { ActionCreator, createAsyncActionEnum, DeclareAsyncAction, IAsyncAction } from '@bnb-tech/core/src/StoreManager';

import ApiRoutes from '../config/ApiRoutes';
import { ILiveDialog } from '../models/domain/dialog/ILiveDialog';
import { IProjectPaginatedCollection } from '../models/domain/project';

export const AnimationActions = {
    ...createAsyncActionEnum('GET_DIALOGS_IN_WAITING_ROOM'),
} as const;

export type AnimationAction = DeclareAsyncAction<
    typeof AnimationActions.GET_DIALOGS_IN_WAITING_ROOM,
    IProjectPaginatedCollection<IEntityCollection<ILiveDialog>>
>;

export class AnimationActionCreator {
    public static getDialogsInWaitingRoom(): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.Chat.WaitingRoom);

        return ActionCreator.createAsyncAction(
            AnimationActions.GET_DIALOGS_IN_WAITING_ROOM,
            [
                AnimationActions.GET_DIALOGS_IN_WAITING_ROOM_REQUEST,
                AnimationActions.GET_DIALOGS_IN_WAITING_ROOM_RESPONSE,
                AnimationActions.GET_DIALOGS_IN_WAITING_ROOM_FAILED,
            ],
            () => FetchService.get(href)
        );
    }
}
