import { combineReducers, type ActionFromReducersMapObject } from 'redux';

import type { IAction } from 'StoreManager/actions';
import type { IReducer, IReducerToCombine, IReducersMapObject, IState } from 'StoreManager/models';
import ReducersToCombine from '../config/ReducersToCombine';
import { isNullOrUndefined } from '../typeguards';
import CustomEvent from '../ui_helper/CustomEvent';
import type { ReducerHandlerRegistry } from './models/ReducerHandlerRegistry';

export class ReducerManager {
    private static _instance: ReducerManager;

    private _onChangeEvent: CustomEvent<IReducersMapObject<IState>> = new CustomEvent<IReducersMapObject<IState>>();

    private _reducers: IReducersMapObject<IState> = { ...ReducersToCombine };

    public static getInstance(): ReducerManager {
        return this._instance || (this._instance = new this());
    }

    public static combine<S = IState>(
        reducers: IReducersMapObject<S>,
        initialState: {} = {}
    ): IReducer<S, ActionFromReducersMapObject<IReducersMapObject<S>>> {
        // pre initialize state if not already existing
        for (const key in reducers) {
            if (!(key in reducers)) {
                // typescript 5 just stfu. I know what I'm doing
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (reducers as any)[String(key) as keyof S] = (state: {} = {}) => state;
            }
        }

        return combineReducers<IReducersMapObject<S>>(reducers) as any;
    }

    public static create<State, Action extends IAction>(
        defaultState: State,
        handler: ReducerHandlerRegistry<State, Action>
    ): IReducer<State, Action> {
        const reducer: IReducer<State, Action> = (state: State = defaultState, action: Action) => {
            if (action.type in handler) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const specifiedHandler = (handler as any)[action.type];
                if (!isNullOrUndefined(specifiedHandler)) {
                    return specifiedHandler(state, action);
                }
            }
            return state;
        };

        return reducer;
    }

    private constructor() {
        // reduce visibility
    }

    public combine(initialState: {} = Object.create(null)): IReducer<IState, IAction> {
        return ReducerManager.combine<IState>(this._reducers, initialState);
    }

    public addCombinedReducers(reducer: IReducerToCombine) {
        this.reducers = { ...this._reducers, ...reducer };
    }

    public register(name: string, reducer: IReducer) {
        this._reducers[name] = reducer;
        this._onChangeEvent.trigger(this.reducers);
    }

    public unregister(name: string) {
        delete this._reducers[name];
        this._onChangeEvent.trigger(this.reducers);
    }

    public addListener(handler: (reducers: IReducersMapObject<IState>) => void) {
        this._onChangeEvent.on(handler);
    }

    public removeListener(handler: (reducers: IReducersMapObject<IState>) => void) {
        this._onChangeEvent.off(handler);
    }

    public get reducers(): IReducersMapObject<IState> {
        return { ...this._reducers };
    }

    public set reducers(value: IReducersMapObject<IState>) {
        this._reducers = value;
    }
}

export default ReducerManager.getInstance();

// const t = 'UPDATE' as const;

// ReducerManager.create<{ item?: IEntity }, IBaseAction<typeof t, IEntity> | IBaseAction<'ADD', IEntity>>(
//     { item: undefined },
//     {
//         UPDATE: (state, action) => updateState(state, { item: action.payload }),
//         ADD: (state, action) => updateState(state, { item: action.payload }),
//     }
// );
