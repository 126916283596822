import Base64 from '@bnb-tech/core/src/common/Base64';
import LocalStorage from '@bnb-tech/core/src/data_interfaces/LocalStorage';
import { IDispatch, IMiddlewareAPI, isResponseAction } from '@bnb-tech/core/src/StoreManager';
import { batchActions } from 'redux-batch-actions';
import { RehydrateAction } from 'redux-persist';
import { REHYDRATE } from 'redux-persist';

import { Languages } from './../services/i18n/models/ILanguageDictionary';
import AuthenticationActionCreator, {
    AuthenticationAction,
    AuthenticationActions,
} from '../actions/AuthenticationActionCreator';
import UserJobTemplateActionCreator from '../actions/automation/UserJobTemplate.actioncreator';
import UserActionCreator, { UserAction } from '../actions/UserActionCreator';
import { IState } from '../models/state';
import { isAuthenticated } from '../selectors/AuthenticationSelectors';
import ChatActionCreator from '../services/Chat/actions/ChatActionCreator';
import { ResourceService } from '../services/i18n/ResourcesService';

// import GeolocationProvider, { GeoLocation } from '../core/GeolocationProvider';

// const MAX_AGE = 0.5 * 60 * 1000;

export function AuthenticationMiddleware(middlewareAPI: IMiddlewareAPI<IState>) {
    const { dispatch, getState } = middlewareAPI;

    // let currentPositionHandle: number | undefined;

    return (next: IDispatch) => async (action: AuthenticationAction | UserAction | RehydrateAction) => {
        if (isResponseAction(action)) {
            if (
                (action.payload.response?.redirected && action.payload.response?.status === 404) ||
                action.payload.response?.type === 'opaqueredirect'
            ) {
                await next(action);

                batchActions(() => {
                    dispatch(AuthenticationActionCreator.sessionExpired());
                    if (action.type !== AuthenticationActions.SUBMIT_LOGOUT_FAILED) {
                        dispatch(AuthenticationActionCreator.submitLogout());
                    }
                });
            }
        }

        switch (action.type) {
            case REHYDRATE: {
                await next(action);
                // just try to get the user if we have a cookie set. This request fails automatically if the user is not logged in.
                await dispatch(UserActionCreator.getUser());

                const state = getState();
                if (isAuthenticated(state)) {
                    dispatch(ChatActionCreator.getPresents());
                    dispatch(UserJobTemplateActionCreator.getAll());
                }
                break;
            }
            case AuthenticationActions.SUBMIT_LOGIN_RESPONSE: {
                await next(action);
                dispatch(ChatActionCreator.getPresents());
                dispatch(UserJobTemplateActionCreator.getAll());
                break;
            }
            case AuthenticationActions.SUBMIT_LOGOUT_RESPONSE: {
                ResourceService.changeCurrentLanguage(Languages.DE);
                LocalStorage.removeItem(Base64.encode('duration'));
                return next(action);
            }
            case AuthenticationActions.SESSION_EXPIRED: {
                LocalStorage.removeItem(Base64.encode('duration'));
                return next(action);
            }
            default:
                return next(action);
        }
    };
}

export default AuthenticationMiddleware;
