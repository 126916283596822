import ActionCreator from '../../StoreManager/ActionCreator';
import type { IAsyncAction } from '../../StoreManager/actions';
import type { IDispatch, IState } from '../../StoreManager/models';

import { onRequestResult } from './fetchMiddleware';

export function processApiFetchResult(
    dispatch: IDispatch,
    state: IState,
    calledAction: IAsyncAction,
    response: Response,
    responseJson: string | object,
    successType: string,
    failureType: string,
    payload: {}
) {
    // check if the response is an error Info Object
    // if (isErrorInfo(responseJson)) {
    //     await dispatchApiFetchFailure(
    //         dispatch,
    //         state,
    //         calledAction,
    //         failureType,
    //         responseJson.text,
    //         payload,
    //         response,
    //         ErrorTypes.BACKEND,
    //         responseJson
    //     );
    //     //
    // } else {
    // let the handler go through and let them check if the result has to go further
    const handlerResult = onRequestResult.trigger({
        dispatch,
        response,
        calledAction,
        actionType: successType,
        result: responseJson,
        payload,
        state,
    });

    if (handlerResult) {
        // dispatch success with payload
        return dispatch(
            ActionCreator.createAction(successType, {
                result: responseJson,
                params: payload,
                response: response,
            })
        );
    }
    // }
}

export default processApiFetchResult;
