import { isNullOrUndefined } from '@bnb-tech/core/src/typeguards';

import { defaultAgent } from '../models/domain/agency/IAgencyUser';
import { IState } from '../models/state';
import { getChatState } from '../services/Chat/selectors/ChatSelectors';

export const getAuthenticationstate = (state: IState) => state.authentication;
export const getUserState = (state: IState) => state.user;

export const isAuthenticated = (state: IState) => !isNullOrUndefined(getUserState(state).user);

export const getUser = (state: IState) => getUserState(state).user ?? defaultAgent;
export const getAgencyUser = (state: IState) => getChatState(state).agentOfAvailableDialog ?? defaultAgent;

// export const getGeoLocation = (state: IState) => state.authentication.geolocation;

export const getAuthenticationError = (state: IState) => getAuthenticationstate(state).authenticationState;
