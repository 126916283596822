import { createEmptyEntityCollection, IEntityCollection } from '@bnb-tech/core/src/models/core/entity';
import { defaultLink, ILink } from '@bnb-tech/core/src/models/core/link';
import { isNullOrUndefined } from '@bnb-tech/core/src/typeguards';
import { addYears } from 'date-fns';

import Config from '../../../config';
import { Language, Languages } from '../../../services/i18n/models/ILanguageDictionary';
import { ITransactionCollection } from '../../contracts/responses/TransactionCollection';
import { DateType } from '../../core/date/DateType';
import { Platform, Platforms } from '../core/Platform';
import { IDatingUser } from '../dating/IDatingUser';
import { IDatingUserPreview } from '../dating/IDatingUserPreview';
import { Countries, Country } from './Country';
import { Gender, Genders } from './Gender';
import { IUserImage } from './IImage';
import {
    BodyJewelryTrait,
    EyeTrait,
    HairTrait,
    LivingTrait,
    RelationshipTrait,
    SmokerTrait,
    ZodiacTrait,
} from './traits';

export interface IUser {
    id: string;
    verified: boolean;
    firebaseId: string;
    platform: Platform;
    genDate: DateType;
    language: Language;
    email: string;
    name: string;
    birthdate: DateType;
    gender: Gender;
    description?: string;
    city: string;
    zip: string;
    country: Country;
    coins: number;
    freeCoins: number;
    balance: number;
    stars: number;
    searchGender: Gender;
    searchMinAge: number;
    searchMaxAge: number;
    searchDistance: number;
    bodyHeight: number;
    relationship?: RelationshipTrait;
    smoker?: SmokerTrait;
    living?: LivingTrait;
    eyes?: EyeTrait;
    hair?: HairTrait;
    zodiac?: ZodiacTrait;
    jewelry?: BodyJewelryTrait;
    turboRocketEndDate?: DateType;
    profileImageId: string;
    profileImage: ILink;
    images: IEntityCollection<IUserImage>;
    verificationDate?: DateType;
    lastLogin?: DateType;
    hasCompletedRegistration: boolean;
    isAutomatedUser?: boolean;
    transactions?: ITransactionCollection;

    // BlockedUsers: IPaginatedCollection<IBlockedUser>;
    // Dialogs: IEntityCollection<DialogDto>;
}

export const USER_MIN_DATE = new Date('1900-01-01');
export const USER_MAX_DATE = addYears(new Date(), -Config.MIN_AGE);
export const USER_DEFAULT_DATE = new Date();
USER_DEFAULT_DATE.setFullYear(USER_DEFAULT_DATE.getFullYear() + -Config.MIN_AGE);

export const hasUserInsufficientInformation = (user?: IUser) => {
    // TODO: get correct informations
    // return user?.LastLogin === null;

    if (!user) {
        return false;
    }

    const { images, birthdate: Birthday, gender: Gender, zip: Zip } = user;

    return (
        // LastLogin === null ||
        !images || images?.items.length === 0 || !Birthday || isNullOrUndefined(Gender) || !Zip // || Birthday.startsWith('1970-01-01') ||
    );

    // return (user?.LastLogin === null && !user.Pictures) || user?.Pictures?.length === 0;
};

export const isUserNew = (user: IUser | IDatingUser | IDatingUserPreview) =>
    new Date(user.genDate).getTime() > Date.now() - 12096e5;

export const getAge = (dateOfBirth: string) => {
    const current = new Date();
    const userDate = new Date(dateOfBirth);

    // calculate the users age from their date of birth
    const age = current.getFullYear() - userDate.getFullYear();

    if (userDate > addYears(current, -age)) {
        return age - 1;
    }

    return age;
};

export const getBalance = (user: IUser) => user.freeCoins + user.coins;

export function checkImageURL(image: IUserImage) {
    // fetch(generateValidUrl(image.Picture))
    //     .then((res) => (res.status === 404 ? Config.FALLBACK_IMAGE : image.Picture))
    //     .catch(() => Config.FALLBACK_IMAGE);
    return image.image;
}

export const getProfileImage = (user: IDatingUserPreview | IDatingUser | IUser): string => {
    return user.profileImage?.href ?? Config.FALLBACK_IMAGE;
    // if ('Picture' in user && isString(user.profileImage)) {
    //     return user.profileImage;
    // } else if ('ProfilPicture' in user && isString(user.profileImage)) {
    //     return user.profileImage;
    // }

    // const { images: Pictures = [] } = user as IUser | IDatingUser;
    // if (Pictures === null || Pictures === undefined) {
    //     return Config.FALLBACK_IMAGE;
    // } else {
    //     const img = Pictures.find((image) => image.ProfileImage === true);

    //     if (img) {
    //         if (img.Picture === '' || img.Picture === null || img.Picture === undefined) {
    //             return Config.FALLBACK_IMAGE;
    //         } else {
    //             return img.Picture;
    //         }
    //     } else {
    //         if (Pictures) {
    //             if (Pictures.length > 0) {
    //                 return checkImageURL(Pictures[0]);
    //             } else {
    //                 return Config.FALLBACK_IMAGE;
    //             }
    //         } else {
    //             return Config.FALLBACK_IMAGE;
    //         }
    //     }
    // }
};

export const formatHeight = (height?: number) => (height ? `${(height / 100).toFixed(2)} m` : '-');

export const getUserMinAgeDate = () => {
    const today = new Date(Date.now());
    const maximumYear = today.getFullYear() - Config.MIN_AGE;
    let month: string;

    if (today.getMonth() + 1 < 10) {
        month = `0${today.getMonth() + 1}`;
    } else {
        month = (today.getMonth() + 1).toString();
    }

    return new Date(`${maximumYear}-${month}-01`);
};

export const withdrawCoins = (user: IUser, amount: number): IUser => {
    if (amount > getBalance(user)) {
        throw new Error('ERR_BALANCE_TOO_LOW');
    }

    if (amount <= user.freeCoins) {
        return { ...user, freeCoins: user.freeCoins - amount };
    }

    const leftAmount = amount - user.freeCoins;

    return {
        ...user,
        freeCoins: 0,
        coins: user.coins - leftAmount,
    };
};

export const getTurboRocketEndDate = (user: IUser) => {
    if (user.turboRocketEndDate) {
        const date = new Date(user.turboRocketEndDate);
        if (date.getTime() <= new Date().getTime()) {
            return undefined;
        }
        return date;
    }
    return undefined;
};

export const defaultUser: IUser = {
    id: '',
    balance: 0,
    birthdate: USER_DEFAULT_DATE.toISOString(),
    bodyHeight: 0,
    city: '',
    coins: 0,
    country: Countries.DE,
    email: '',
    firebaseId: '',
    freeCoins: 0,
    genDate: new Date().toISOString(),
    gender: Genders.MALE,
    hasCompletedRegistration: false,
    images: createEmptyEntityCollection(),
    name: '',
    language: Languages.DE,
    platform: Platforms.Web,
    profileImage: defaultLink,
    profileImageId: '',
    searchDistance: Config.MAX_DISTANCE,
    searchGender: Genders.FEMALE,
    searchMaxAge: Config.MAX_AGE,
    searchMinAge: Config.MIN_AGE,
    stars: 0,
    verified: false,
    zip: '',
};
