import { Close as faX } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import React from 'react';

import Icon from '../Icon/Icon';

interface IIconModalProps extends DialogProps {
    open: boolean;
    icon: string;
    title?: string;
    text?: string;
    imageComponent?: React.ReactNode;
    button?: {
        title: string;
        disabled?: boolean;
        onClick(): void;
    };
    onClose(): void;
}

export const IconModal = (props: IIconModalProps) => {
    const { open, onClose, title, text, icon, button, imageComponent, children } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ style: { overflow: 'initial' }, className: 'spacing double padding all text-align-center' }}
            maxWidth="xs"
            fullWidth
        >
            <div style={{ marginTop: -60 }}>
                {imageComponent ? imageComponent : <img width={100} height={100} src={icon} alt="icon-modal" />}
            </div>

            <IconButton style={{ position: 'absolute', top: 12, right: 12 }} size="small" onClick={onClose}>
                <Icon icon={faX} fontSize="small" />
            </IconButton>

            {title && (
                <DialogTitle>
                    <Typography variant="h6">{title}</Typography>
                </DialogTitle>
            )}
            <DialogContent>
                {text && <DialogContentText>{text}</DialogContentText>}
                {children}
            </DialogContent>
            <DialogActions>
                {button && (
                    <Button fullWidth={false} onClick={button.onClick} disabled={button.disabled}>
                        {button.title}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
export default IconModal;
