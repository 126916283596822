import isDevelopmentMode from './isDevelopmentMode';

type ConsoleMethod = 'debug' | 'log' | 'info' | 'warn' | 'error' | 'trace' | 'table' | 'group' | 'groupEnd';

enum ConsoleMethods {
    DEBUG = 'debug',
    LOG = 'log',
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error',
    TRACE = 'trace',
    TABLE = 'table',
    GROUP = 'group',
    GROUP_END = 'groupEnd',
}

/* eslint-disable no-console */

/**
 * Wrapped Class for Console interaction. Automatically suppresses console logs on production build
 *
 * ### Usage for String subs:
 * **`%o / %O` - for objects;**
 *
 * **`%d / %i` - for integers;**
 *
 * **`%s` - for strings;**
 *
 * **`%f` - for floating-point numbers;**
 *
 * **`%c` - for inserting CSS, css has to be supplied in order as optional param;**
 * **for example: `Logger.log("%cExample CSS-styled log!", "color: red; font-family: monoscope;")`**
 *
 * @export class
 * @class Console
 */
export class Logger {
    public static debug(message?: unknown, ...optionalParams: unknown[]) {
        this._writeToConsole(ConsoleMethods.DEBUG, message, ...optionalParams);
    }

    public static log(message?: unknown, ...optionalParams: unknown[]) {
        this._writeToConsole(ConsoleMethods.LOG, message, ...optionalParams);
    }

    public static info(message?: unknown, ...optionalParams: unknown[]) {
        this._writeToConsole(ConsoleMethods.INFO, message, ...optionalParams);
    }

    public static warn(message?: unknown, ...optionalParams: unknown[]) {
        this._writeToConsole(ConsoleMethods.WARN, message, ...optionalParams);
    }

    public static error(message?: unknown, ...optionalParams: unknown[]) {
        this._writeToConsole(ConsoleMethods.ERROR, message, ...optionalParams);
    }

    public static trace(message?: unknown, ...optionalParams: unknown[]) {
        this._writeToConsole(ConsoleMethods.TRACE, message, ...optionalParams);
    }

    public static assert(condition: boolean, message?: string, ...optionalParams: unknown[]) {
        if (this._isLoggingEnabled) {
            console.assert(condition, message, ...optionalParams);
        }
    }

    public static table(message?: unknown[], ...optionalParams: unknown[]) {
        this._writeToConsole(ConsoleMethods.TABLE, message, ...optionalParams);
    }

    public static time(message?: string) {
        if (this._isLoggingEnabled) {
            console.time(message);
        }
    }

    public static timeLog(message?: string) {
        if (this._isLoggingEnabled) {
            console.timeLog(message);
        }
    }

    public static timeEnd(message?: string) {
        if (this._isLoggingEnabled) {
            console.timeEnd(message);
        }
    }

    public static group(message?: unknown[], ...optionalParams: unknown[]) {
        this._writeToConsole(ConsoleMethods.GROUP, message, ...optionalParams);
    }

    public static groupEnd(message?: unknown[], ...optionalParams: unknown[]) {
        this._writeToConsole(ConsoleMethods.GROUP_END, message, ...optionalParams);
    }

    /**
     * deprecated api, calls console.error
     *
     * @static
     * @param {string} [message]
     * @param {...unknown[]} optionalParams
     * @memberof Logger
     */
    public static exception(message?: unknown, ...optionalParams: unknown[]) {
        this._writeToConsole(ConsoleMethods.ERROR, message, ...optionalParams);
    }

    private static _writeToConsole(method: ConsoleMethod, message?: unknown, ...optionalParams: unknown[]) {
        if (this._isLoggingEnabled) {
            if (optionalParams?.length !== 0) {
                console[method](message, ...optionalParams);
            } else {
                console[method](message);
            }
        }
    }

    private static get _isLoggingEnabled(): boolean {
        return isDevelopmentMode();
    }
}

// create an Alias for Console, since Console is already reserved in NodeJs.
export const Console = Logger;

export default Logger;
