import Base64 from '@bnb-tech/core/src/common/Base64';
import CoreDuration, { IDuration } from '@bnb-tech/core/src/common/CoreDuration/CoreDuration';
import LocalStorage from '@bnb-tech/core/src/data_interfaces/LocalStorage';
import { useDispatch } from '@bnb-tech/core/src/StoreManager';
import { TypographyProps, useTheme } from '@mui/material';
import { addMinutes } from 'date-fns/esm';
import { useCallback, useEffect, useRef, useState } from 'react';

import AuthenticationActionCreator from '../../../../actions/AuthenticationActionCreator';
import Config from '../../../../config';
import CountDown from '../../MessagesPage/ChatPanel/components/CountDown';

export interface IChatPanelCountDownProps {
    availableDialogStart?: string;
    dialogId: string;
}

export const ChatPanelCountDown = (props: IChatPanelCountDownProps) => {
    const { availableDialogStart } = props;
    // get the initial date from local storage
    const durationRef = useRef<Date>(addMinutes(getDuration(availableDialogStart), Config.CHAT_DURATION));
    const dispatch = useDispatch();
    const [tprops, setProps] = useState<TypographyProps | undefined>();
    const theme = useTheme();

    useEffect(() => {
        durationRef.current = addMinutes(getDuration(availableDialogStart), Config.CHAT_DURATION);
    }, [availableDialogStart, props.dialogId]);

    const handleEnd = useCallback(() => {
        // logout
        clearDuration();
        dispatch(AuthenticationActionCreator.submitLogout());
    }, [dispatch]);

    const handleTick = useCallback(
        (duration: IDuration) => {
            const ms = CoreDuration.toMilliseconds(duration);

            // if less than 2 minutes left, change the color to yellow

            const warnThreshold = Config.CHAT_DURATION * 60 * 1000 * 0.5;

            const errorThreshold = Config.CHAT_DURATION * 60 * 1000 * 0.2;

            if (ms < errorThreshold) {
                setProps({ color: 'error' });
            } else if (ms < warnThreshold) {
                setProps({ color: theme.palette.warning.main });
            } else {
                setProps({ color: theme.palette.success.main });
            }
        },
        [theme.palette.success.main, theme.palette.warning.main]
    );

    return <CountDown endDate={durationRef.current} onEnd={handleEnd} onTick={handleTick} typographyProps={tprops} />;
};

export default ChatPanelCountDown;

function getDuration(availableDialogStart?: string) {
    if (availableDialogStart) {
        return new Date(availableDialogStart);
    }

    const existingDuration = LocalStorage.getItem(Base64.encode('duration'));

    let date: Date = new Date();

    if (existingDuration) {
        date = new Date(existingDuration);
    }

    LocalStorage.setItem(Base64.encode('duration'), date.toISOString());

    return date;
}

function clearDuration() {
    LocalStorage.removeItem(Base64.encode('duration'));
}
