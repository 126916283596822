export type Gender = 'male' | 'female' | 'other';

export enum Genders {
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other',
}

export const GenderTranslations: Record<Gender, string> = {
    [Genders.MALE]: 'TRAITS_GENDER_MAN',
    [Genders.FEMALE]: 'TRAITS_GENDER_WOMAN',
    [Genders.OTHER]: 'Diverse',
};
