export enum ChatListenerEvents {
    REFRESH = 'OnGetDialog',
    REFRESH_DIALOGS = 'OnGetDialogs',
    NEW_MESSAGE = 'OnNewMessage',
    COINS = 'coins',
    DIALOG_READ = 'OnDialogRead',
    ERROR = 'OnError',
    KICK = 'OnKick',
    SOFT_KICK = 'OnSoftKick',
    ON_WRITE = 'OnWrite',
    ON_CONNECTED = 'OnConnected',
}
