import { isObject } from '../../typeguards';
import type { Action } from 'redux';

import type { IRequestFailedAction } from './IRequestFailedAction';
import type { IRequestFinishedAction } from './IRequestFinishedAction';

export function isResponseAction(x: Action): x is IRequestFinishedAction | IRequestFailedAction {
    try {
        if (x && hasPayload(x) && isObject(x.payload) && 'response' in x.payload) {
            return true;
        }
    } catch {
        //
    }

    return false;
}

export default isResponseAction;

const hasPayload = (x: Action): x is Action & { payload: any } => x && 'payload' in x;
