export interface IGDWA {
    globalOpenAsaDialogs: number;
    globalOpenDialogs: number;
    globalWaitingRoomDialogs: number;
    openDialogs: number;
    newCustomerConversionPercentage: number;
    globalUnassignedOpenDialogs: number;
    globalUnassignedAsaDialogs: number;
}

export const emptyGDWA: IGDWA = {
    globalOpenAsaDialogs: 0,
    globalOpenDialogs: 0,
    globalWaitingRoomDialogs: 0,
    openDialogs: 0,
    newCustomerConversionPercentage: 0,
    globalUnassignedOpenDialogs: 0,
    globalUnassignedAsaDialogs: 0,
};
