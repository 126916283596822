import CoreConfiguration from '../config/CoreConfiguration';

export function isDevelopmentMode(): boolean {
    try {
        return CoreConfiguration.isDevelopmentMode;
    } catch {
        return true;
    }
}

export default isDevelopmentMode;
