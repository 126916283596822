import { isFunction } from '../../typeguards';

export function updateElementOfCollection<T>(
    collection: T[],
    predicate: (value: T, index: number, array: T[]) => T
): T[] {
    return collection.map<T>(predicate);
}

export function updateElementOfCollectionByProperty<T>(
    collection: T[],
    key: keyof T,
    value: unknown,
    updateFunction: T | ((value: T) => T)
): T[] {
    return collection.map<T>((x) =>
        x[key] === value ? (isFunction(updateFunction) ? updateFunction(x) : updateFunction) : x
    );
}

export default updateElementOfCollection;
