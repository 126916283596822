import { IGDWA, emptyGDWA } from '../../../models/domain/chat/IGDWA';
import { IState } from '../../../models/state';

export const getChatState = (state: IState) => state.chat;

// export const getChats = (state: IState) => (state.chat.dialogs ?? createEmptyEntityCollection()).items;
// export const getChat = (state: IState, chatId: string) => getChats(state).find((x) => x.id === chatId);

// export const getUnreadChats = (state: IState) => getChats(state)?.filter((x) => x.unreadCount > 0 && x.lastMessage !== null) ?? [];

export const getAvailableDialog = (state: IState) => getChatState(state).availableDialog;
export const getCurrentProject = (state: IState) => getChatState(state).projectOfAvailableDialog;
export const getAvailableDialogStart = (state: IState) => getChatState(state).dialogTrackstartTime;

export const getGDWA = (state: IState): IGDWA => {
    const defaultGdwa = { ...emptyGDWA };
    const gdwas = getChatState(state).gdwa;

    for (const gdwa of gdwas.items) {
        defaultGdwa.globalOpenAsaDialogs += gdwa.item.globalOpenAsaDialogs;
        defaultGdwa.globalOpenDialogs += gdwa.item.globalOpenDialogs;
        defaultGdwa.globalWaitingRoomDialogs += gdwa.item.globalWaitingRoomDialogs;
        defaultGdwa.openDialogs += gdwa.item.openDialogs;
        defaultGdwa.newCustomerConversionPercentage += gdwa.item.newCustomerConversionPercentage;
        defaultGdwa.globalUnassignedOpenDialogs += gdwa.item.globalUnassignedOpenDialogs;
        defaultGdwa.globalUnassignedAsaDialogs += gdwa.item.globalUnassignedAsaDialogs;
    }

    return defaultGdwa;
};

export const getImageTemplates = (state: IState) => getChatState(state).imageTemplates;
export const getImageTags = (state: IState) => getChatState(state).imageTags;
export const getEstablishedState = (state: IState) => getChatState(state).isConnectionEstablished;
