import {
    IDispatch,
    IMiddlewareAPI,
    PersistorAction, 
    getResponse,
} from '@bnb-tech/core/src/StoreManager';
import { batchActions } from 'redux-batch-actions';
import { REHYDRATE } from 'redux-persist';
import Base64 from '@bnb-tech/core/src/common/Base64';
import CoreBoolean from '@bnb-tech/core/src/common/CoreBoolean';
import LocalStorage from '@bnb-tech/core/src/data_interfaces/LocalStorage';
import Logger from '@bnb-tech/core/src/diagnostics/Logger';
import isDevelopment from '@bnb-tech/core/src/diagnostics/isDevelopmentMode';
import { OptionsObject } from 'notistack';

import { AuthenticationAction, AuthenticationActions } from '../../../actions/AuthenticationActionCreator';
import UserActionCreator, { UserAction, UserActions } from '../../../actions/UserActionCreator';
import beep from '../../../assets/audio/new_msg.mp3';
import ApiRoutes from '../../../config/ApiRoutes';
import IIFE from '../../../core/IIFE';
import { IState } from '../../../models/state';
import { getUser, isAuthenticated } from '../../../selectors/AuthenticationSelectors';
import BrowserNotificationService from '../../BrowserNotifications/BrowserNotificationService';
import NotificationActionCreator from '../../Notifications/actions/NotificationActionCreator';
import ResourceService from '../../i18n/ResourcesService';
import ChatService from '../ChatService';
import ChatActionCreator, { ChatAction, ChatActions } from '../actions/ChatActionCreator';
import { ChatListeners } from '../listener/ChatListeners';
import ChatMessages from '../messages/ChatMessages';
import { IWebsocketListener } from '../models/IWebsocketListener';
import { getAvailableDialog } from '../selectors/ChatSelectors';

// eslint-disable-next-line

export function ChatMiddleware(middlewareAPI: IMiddlewareAPI<IState>) {
    const { dispatch, getState } = middlewareAPI;
    const wsListener: IWebsocketListener[] = ChatListeners(dispatch);
    const enqueueSnackbar = (message: string, options?: OptionsObject) =>
        dispatch(
            NotificationActionCreator.enqueueSnackbar({
                key: String(new Date().getTime() + Math.random()),
                message: message,
                options,
            })
        );

    return (next: IDispatch) => async (action: AuthenticationAction | ChatAction | PersistorAction | UserAction) => {
        switch (action.type) {
            case REHYDRATE: {
                await next(action);
                dispatch(ChatActionCreator.onConnected(false));

                const token = isAuthenticated(getState());

                if (token) {
                    // globalHistory?.push(HOME_PATH);
                    requestAnimationFrame(() => {
                        dispatch(ChatActionCreator.onConnected(false));
                        Logger.log('starting ws');
                        ChatService.createInstance(ApiRoutes.createUrl(ApiRoutes.Chat.Hub), wsListener);
                        return ChatService.start(
                            () => dispatch(ChatActionCreator.setConnectionState(true)),
                            //(url, forced) => !forced && enqueueSnackbar('Verbindung unterbrochen', { variant: 'error' }),
                            (url, forced) => {
                                if (!forced) {
                                    enqueueSnackbar('Verbindung unterbrochen', { variant: 'error' });
                                    dispatch(ChatActionCreator.setConnectionState(false));
                                    dispatch(ChatActionCreator.onConnected(false));
                                }
                            },
                            () => {
                                enqueueSnackbar('Verbindung wiederhergestellt', { variant: 'info' });
                                dispatch(ChatActionCreator.setConnectionState(true));
                            },
                            () => {
                                enqueueSnackbar('Fehler', { variant: 'error' });
                                dispatch(ChatActionCreator.setConnectionState(false));
                                dispatch(ChatActionCreator.onConnected(false));
                            }
                        );
                    });
                }

                break;
            }

            case AuthenticationActions.SUBMIT_LOGIN_RESPONSE: {
                // const { token } = action.payload.result;
                await next(action);

                requestAnimationFrame(() => {
                    Logger.log('starting ws');
                    dispatch(ChatActionCreator.onConnected(false));
                    // creating user
                    ChatService.createInstance(ApiRoutes.createUrl(ApiRoutes.Chat.Hub), wsListener);
                    // send the initial handshake
                    return ChatService.start(
                        () => dispatch(ChatActionCreator.setConnectionState(true)),
                        () => {
                            enqueueSnackbar('Verbindung unterbrochen', { variant: 'error' });
                            dispatch(ChatActionCreator.setConnectionState(false));
                        },
                        () => {
                            enqueueSnackbar('Verbindung wiederhergestellt', { variant: 'info' });
                            dispatch(ChatActionCreator.setConnectionState(true));
                        },
                        () => {
                            enqueueSnackbar('Fehler', { variant: 'error' });
                            dispatch(ChatActionCreator.setConnectionState(false));
                        }
                    );
                });
                break;
            }
            case UserActions.GET_USER_RESPONSE: {
                await next(action);

                if (!ChatService.getInstance()) {
                    requestAnimationFrame(() => {
                        Logger.log('starting ws');
                        dispatch(ChatActionCreator.onConnected(false));
                        // creating user
                        ChatService.createInstance(ApiRoutes.createUrl(ApiRoutes.Chat.Hub), wsListener);
                        // send the initial handshake
                        return ChatService.start(
                            () => dispatch(ChatActionCreator.setConnectionState(true)),
                            () => {
                                enqueueSnackbar('Verbindung unterbrochen', { variant: 'error' });
                                dispatch(ChatActionCreator.setConnectionState(false));
                            },
                            () => {
                                enqueueSnackbar('Verbindung wiederhergestellt', { variant: 'info' });
                                dispatch(ChatActionCreator.setConnectionState(true));
                            },
                            () => enqueueSnackbar('Fehler', { variant: 'error' })
                        );
                    });
                }
                break;
            }
            case AuthenticationActions.SUBMIT_LOGOUT_REQUEST:
            case AuthenticationActions.SUBMIT_LOGOUT:
            case AuthenticationActions.SESSION_EXPIRED: {
                try {
                    await ChatService.destroyInstance();
                } catch (e) {
                    // instance already dead
                }
                return next(action);
            }
            // handle messages
            case ChatActions.SEND_MESSAGE: {
                await next(action);
                ChatService.send(ChatMessages.sendMessage(action.payload));
                break;
            }
            case ChatActions.READ_DIALOG: {
                await next(action);
                ChatService.send(ChatMessages.dialogRead(action.payload));
                break;
            }
            case ChatActions.REFRESH_DIALOG: {
                await next(action);
                ChatService.send(ChatMessages.refresh(action.payload));
                break;
            }
            case ChatActions.WRITE: {
                await next(action);
                ChatService.send(ChatMessages.write(action.payload));
                break;
            }

            case ChatActions.NEW_MESSAGE: {
                const state = getState();
                const availableDialog = getAvailableDialog(state);

                if (action.payload.dialog === availableDialog?.id) {
                    await next(action);
                }

                break;
            }
            case ChatActions.GET_AVAILABLE_DIALOG_REQUEST: {
                LocalStorage.removeItem(Base64.encode('duration'));
                await next(action);
                break;
            }
            case ChatActions.GET_AVAILABLE_DIALOG_RESPONSE: {
                // await next(action);

                const dialog = getResponse(action);
                if (dialog && dialog.item && dialog.item.id) {
                    const agent = getUser(getState());

                    batchActions(() => {
                        next(action);

                        dispatch(ChatActionCreator.getDialogInformation(dialog.item.id, dialog.projectId));
                        dispatch(UserActionCreator.getAgencyUser(agent.id, dialog.projectId));
                        dispatch(ChatActionCreator.getGDWA());
                    });

                    // globalHistory?.push(MESSAGES_PATH.replace(':id', dialog.id));
                    if (!isDevelopment()) {
                        try {
                            const isDisabled = CoreBoolean.parse(LocalStorage.getItem('soundDisabled'), false);
                            if (!isDisabled) {
                                setTimeout(
                                    () =>
                                        IIFE(async () => {
                                            try {
                                                const audio = new Audio(beep);
                                                audio.volume = 0.6;
                                                await audio.play();
                                            } catch {
                                                // sound unsupported as it seems?
                                            }
                                        }),
                                    0
                                );
                            }
                        } catch {
                            // not supported as it seems
                        }
                    }

                    const { BROWSER_NOTIFICATION_TITLE, BROWSER_NOTIFICATION_TEXT } =
                        ResourceService.getCurrentResources();

                    await BrowserNotificationService.showNotification(
                        ResourceService.replace(BROWSER_NOTIFICATION_TITLE, {
                            project: dialog.project?.name ?? import.meta.env.VITE_PROJECT ?? '',
                        }),
                        BROWSER_NOTIFICATION_TEXT
                    );
                } else {
                    await next(action);
                }

                break;
            }
            case ChatActions.OCCUPY_DIALOG_FROM_WAITING_ROOM_RESPONSE: {
                const { dialog, project } = getResponse(action);
                LocalStorage.removeItem(Base64.encode('duration'));

                if (dialog) {
                    batchActions(() => {
                        next(action);
                        dispatch(ChatActionCreator.getGDWA());
                    });

                    const { BROWSER_NOTIFICATION_TITLE, BROWSER_NOTIFICATION_TEXT } =
                        ResourceService.getCurrentResources();

                    await BrowserNotificationService.showNotification(
                        ResourceService.replace(BROWSER_NOTIFICATION_TITLE, {
                            project: project?.name ?? import.meta.env.VITE_PROJECT ?? '',
                        }),
                        BROWSER_NOTIFICATION_TEXT
                    );
                    // globalHistory?.push(MESSAGES_PATH.replace(':id', dialog.id));
                } else {
                    await next(action);
                }

                break;
            }
            case ChatActions.SOFT_KICK:
            case ChatActions.GET_AVAILABLE_DIALOG_FAILED: {
                await next(action);
                LocalStorage.removeItem(Base64.encode('duration'));
                // globalHistory?.push(HOME_PATH);
                break;
            }
            // case ChatActions.CREATE_DIALOG_RESPONSE: {
            //   // const { uuid } = action.payload.result;
            //   // await ChatService.send(ChatMessages.refresh());
            //   // await ChatService.send(ChatMessages.refresh(uuid));
            //   return next(action);
            // }
            default:
                return next(action);
        }
    };
}

export default ChatMiddleware;