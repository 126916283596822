import { IDictionary } from '@bnb-tech/core/src/models/helper/IDictionary';
import { ReactChildren } from '@bnb-tech/core/src/models/helper/ReactChildren';
import { isFunction } from '@bnb-tech/core/src/typeguards';
import {
    Paper,
    PaperProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties, ReactNode, memo } from 'react';

import useMobileBreakpoint from '../../hooks/useMobileBreakpoint';
import Checkbox from '../Checkbox';
import DataTableCell from './components/cell/DataTableCell';
import { CustomTablePagination } from './components/CustomTablePagination';
import { EnhancedTableHead } from './components/header/EnhancedTableHead';
import { IColumnDefinition } from './IColumnDefinition';
import { IFilterValue } from './models/IFilterValue';
import { useDataTable } from './useDataTable';

export type Order = 'asc' | 'desc';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RowType = IDictionary<any>;

export type CellMode = 'view' | 'edit';

export interface IDataTableProps {
    className?: string;
    enableMultiselect?: boolean;
    enableSelect?: boolean;
    dense?: boolean;
    columns: IColumnDefinition[];
    rows: RowType[];
    selectedRows?: IDictionary<string>;
    initialRowsPerPage?: number;
    initialRowsPerPageOption?: number[];
    enableElevation?: boolean;
    emptyState?: ReactNode;
    tableLayout?: CSSProperties['tableLayout'];
    variant?: PaperProps['variant'];
    children?: ReactChildren;
    initialSort?: { orderBy: string; order: Order };
    customRow?: ReactChildren | ((rows: RowType) => unknown);
    footerRow?: ReactChildren | ((rows: RowType) => unknown);
    initialFilter?: IDictionary<Array<IFilterValue<unknown>>>;
    currentPage?: number;
    disablePaper?: boolean;
    onRowClick?(row: RowType): void;
    onRowSelectionChanged?(selectedRows: IDictionary<string>): void;
    onNextPage?(newPage: number, rowsPerPage: number): void;
    onPreviousPage?(newPage: number): void;
    getRowId?(row: RowType): string;
    onCellChanged?(row: RowType, column: IColumnDefinition, newValue: unknown): void;
}
export const DataTable = memo((props: IDataTableProps) => {
    const {
        rows,
        columns,
        enableMultiselect,
        initialRowsPerPage = 10,
        initialRowsPerPageOption = [5, 10, 15, 20, 25, 30],
        enableElevation = false,
        onNextPage,
        getRowId = (row) => row.id,
        onCellChanged,
        customRow,
        footerRow,
        initialFilter,
        disablePaper = true,
    } = props;
    const { emptyState, onRowClick, tableLayout, dense, className, children, enableSelect } = props;
    const { selectedRows, onRowSelectionChanged, variant = 'outlined', initialSort, currentPage } = props;

    const isMobile = useMobileBreakpoint();

    const {
        sortedRows,
        handleChangePage,
        handleChangeRowsPerPage,
        emptyRows,
        isSelected,
        handleClick,
        handleSelectAllClick,
        handleRequestSort,
        selected,
        order,
        orderBy,
        page,
        rowsPerPage,
        classes,
        setFilters,
        filters,
        cellModeDic,
        handleDoubleClick,
        handleFocusLost,
        handleValueChange,
        filteredRows,
    } = useDataTable({
        initialRowsPerPage,
        initialSort,
        selectedRows,
        columns,
        rows,
        onRowSelectionChanged,
        onNextPage,
        onCellChanged,
        customRow,
        initialFilter,
        currentPage,
    });

    const content = (
        <>
            {children}
            <TableContainer className={className}>
                <Table
                    className={classes.table}
                    style={{ tableLayout: tableLayout ?? isMobile ? undefined : 'fixed' }}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        classes={classes}
                        headers={columns}
                        numSelected={Object.keys(selected).length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        enableMultiselect={enableMultiselect}
                        setFilters={setFilters}
                        filters={filters}
                        rows={rows}
                    />
                    <TableBody>
                        <>
                            {sortedRows.map((row, index) => {
                                const rowId = getRowId(row);
                                const isItemSelected = isSelected(rowId);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover={enableSelect}
                                        onClick={
                                            enableSelect || enableMultiselect || onRowClick
                                                ? (event) => (onRowClick ? onRowClick(row) : handleClick(event, rowId))
                                                : undefined
                                        }
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={rowId}
                                        className={onRowClick && 'pointer'}
                                    >
                                        {enableMultiselect && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                        )}

                                        {columns.map((x) => (
                                            <DataTableCell
                                                key={x.id}
                                                column={x}
                                                row={row}
                                                index={page * rowsPerPage + index}
                                                onFocusLost={handleFocusLost}
                                                onDoubleClick={handleDoubleClick}
                                                onValueChange={handleValueChange}
                                                mode={getCellMode(cellModeDic, rowId, x)}
                                            />
                                        ))}
                                    </TableRow>
                                );
                            })}
                            {isFunction(customRow) ? customRow(filteredRows) : customRow}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 40 : 56) * emptyRows }}>
                                    <TableCell colSpan={columns.length}>
                                        {rows.length === 0 && emptyState ? emptyState : null}
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    </TableBody>
                    {footerRow && (
                        <TableFooter>
                            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                            {isFunction(footerRow) ? (footerRow(filteredRows) as any) : footerRow}
                        </TableFooter>
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                classes={{ spacer: 'flex no-grow' }}
                rowsPerPageOptions={initialRowsPerPageOption}
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={CustomTablePagination}
                labelDisplayedRows={({ from, to, count }) => `Zeige ${from} bis ${to} von ${count} Ergebnisse`}
                // rowsPerPageOptions={[-1]}
            />
        </>
    );

    return disablePaper ? (
        <div className="flex">{content}</div>
    ) : (
        <Paper className="flex" variant={variant} elevation={!enableElevation ? 0 : undefined}>
            {content}
        </Paper>
    );
});

export default DataTable;

export const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
        },
        table: {},
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    })
);

function getCellMode(
    cellModeDic: IDictionary<IDictionary<CellMode>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowId: any,
    x: IColumnDefinition<{}>
): CellMode | undefined {
    return rowId in cellModeDic && x.id in cellModeDic[rowId] ? cellModeDic[rowId][x.id] : undefined;
}
