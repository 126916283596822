import { ChevronLeft as faChevronLeft, ChevronRight as faChevronRight } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import Icon from '../../Icon';

export function CustomTablePagination(props: TablePaginationActionsProps) {
    const { backIconButtonProps, nextIconButtonProps, page, count, rowsPerPage, onPageChange } = props;
    const classes = useStyles();
    const { inactiveText, activeText, ...buttonClasses } = classes;
    const max = Math.ceil(count / rowsPerPage);

    const canChangePrev = page > 0;
    const canChangeNext = page + 1 < max;

    const handleChangePrevious = (e: React.MouseEvent<HTMLButtonElement>) => onPageChange(e, Math.max(page - 1, 0));
    const handleChangeNext = (e: React.MouseEvent<HTMLButtonElement>) => onPageChange(e, Math.min(page + 1, max));

    return (
        <>
            <div className="flex" />
            <div className="flex row no-grow spacing padding all align-items-center ">
                <IconButton
                    {...backIconButtonProps}
                    size="small"
                    onClick={handleChangePrevious}
                    className="spacing margin right"
                    disabled={!canChangePrev}
                >
                    <Icon icon={faChevronLeft} fontSize="small" />
                </IconButton>

                {canChangePrev && (
                    <Button
                        size="small"
                        color="secondary"
                        className="spacing margin right"
                        onClick={handleChangePrevious}
                        classes={buttonClasses}
                    >
                        <Typography variant="subtitle2" className={inactiveText}>
                            {page}
                        </Typography>
                    </Button>
                )}

                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    className="spacing margin right"
                    classes={buttonClasses}
                >
                    <Typography variant="subtitle2" color="inherit" className={activeText}>
                        {page + 1}
                    </Typography>
                </Button>

                {canChangeNext && (
                    <Button
                        size="small"
                        color="secondary"
                        className="spacing margin right"
                        onClick={handleChangeNext}
                        classes={buttonClasses}
                    >
                        <Typography variant="subtitle2" className={inactiveText}>
                            {page + 2}
                        </Typography>
                    </Button>
                )}

                <IconButton
                    {...nextIconButtonProps}
                    size="small"
                    onClick={handleChangeNext}
                    disabled={count < (page + 1) * rowsPerPage}
                >
                    <Icon icon={faChevronRight} fontSize="small" />
                </IconButton>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 0,
        width: 32,
        height: 32,
        borderRadius: 32,
    },
    contained: {
        // backgroundColor: theme.palette.grey[700],
    },
    text: {
        backgroundColor: theme.palette.grey[200],
    },
    activeText: {},
    inactiveText: {
        color: theme.palette.grey[900],
    },
}));
