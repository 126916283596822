import type { IAsyncAction, ICallAPI } from '../StoreManager/actions';
import type { IDispatch, IState } from '../eManager/models';

import dispatchApiFetchFailure from './dispatchApiFetchFailure';
import handleApiFetchResponseError from './handleApiFetchResponseError';
import processApiFetchResult from './processApiFetchResult';

export async function startApiFetchRequest(
    dispatch: IDispatch,
    getState: () => IState,
    calledAction: IAsyncAction,
    callAPI: ICallAPI,
    successType: string,
    failureType: string,
    payload: {}
) {
    let resultAction;
    try {
        // fire the Request
        const response = await callAPI(getState());
        // response.url;
        // response.body;

        const text = await response.text();

        // Parse the response as text
        // Try to parse the retrieved text as json
        let json = text;
        try {
            json = JSON.parse(text);
        } catch (e) {
            // not a valid json
        }

        // check if the response was successful
        if (response.ok) {
            // process the result
            resultAction = await processApiFetchResult(
                dispatch,
                getState(),
                calledAction,
                response,
                json,
                successType,
                failureType,
                payload
            );
        } else {
            resultAction = await handleApiFetchResponseError(
                dispatch,
                getState(),
                calledAction,
                response,
                failureType,
                payload,
                json
            );
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        if (error?.name !== 'AbortError') {
            // dispatch the Show Error Action
            // TODO: check handling if it needs to be send as Error
            await dispatchApiFetchFailure(
                dispatch,
                getState(),
                calledAction,
                failureType,
                error?.message,
                payload,
                undefined,
                undefined,
                'network'
            );
        }
    }
    return resultAction;
}

export default startApiFetchRequest;
