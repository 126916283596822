import EntityController, {
    GenerateEntityAction,
    IReducerState,
} from '@bnb-tech/core/src/StoreManager/controller/EntityController';

import ApiRoutes from '../../config/ApiRoutes';
import { IUserJobTemplate } from '../../models/domain/automation';
import { IState } from '../../models/state/IState';

const UserJobTemplateLiteral = 'USER_NAME_TEMPLATE' as const;

export type UserJobTemplateAction = GenerateEntityAction<IUserJobTemplate, typeof UserJobTemplateLiteral>;

export const [UserJobTemplateActions, UserJobTemplateActionCreator] = EntityController.create<
    IUserJobTemplate,
    typeof UserJobTemplateLiteral
>(UserJobTemplateLiteral, {
    baseUrl: ApiRoutes.createUrl(ApiRoutes.UserJobTemplate.Base),
    selfUrl: (id: string) => ApiRoutes.createUrl(ApiRoutes.UserJobTemplate.Self(id)),
});

export type IUserJobTemplateState = IReducerState<IUserJobTemplate>;

const getUserJobTemplateState = (state: IState) => state.userJobTemplate;

export const [UserJobTemplateReducer, getUserJobTemplates, getUserJobTemplate] = EntityController.createReducer<
    IUserJobTemplate,
    typeof UserJobTemplateLiteral,
    IState
>(UserJobTemplateActions, getUserJobTemplateState);

export default UserJobTemplateActionCreator;
