import { ReactChildren } from '@bnb-tech/core/src/models/helper/ReactChildren';
import { useState } from 'react';

import { GlobalLoadingIndicatorContext } from './GlobalLoadingIndicatorContext';

export function GlobalLoadingIndicatorContextProvider(props: { children?: ReactChildren }) {
    const [loading, setLoading] = useState(false);

    return (
        <GlobalLoadingIndicatorContext.Provider value={{ loading, setLoading }}>
            {props.children}
        </GlobalLoadingIndicatorContext.Provider>
    );
}

export default GlobalLoadingIndicatorContextProvider;
