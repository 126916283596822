import CoreRegExp from '../CoreRegExp';

export interface IDuration {
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
    weeks?: number;
}

// PnYnMnDTnHnMnS
// const numbers = '\\d+(?:[\\.,]\\d+)?';
// const weekPattern = `(${numbers}W)`;
// const datePattern = `(${numbers}Y)?(${numbers}M)?(${numbers}D)?`;
// const timePattern = `T(${numbers}H)?(${numbers}M)?(${numbers}S)?`;

// const iso8601 = `P(?:${weekPattern}|${datePattern}(?:${timePattern})?)`;

const iso8601 =
    // eslint-disable-next-line max-len
    /P(?:(\d+(?:[.,]\d+)?W)|(\d+(?:[.,]\d+)?Y)?(\d+(?:[.,]\d+)?M)?(\d+(?:[.,]\d+)?D)?(?:T(\d+(?:[.,]\d+)?H)?(\d+(?:[.,]\d+)?M)?(\d+(?:[.,]\d+)?S)?)?)/;

const objMap = ['weeks', 'years', 'months', 'days', 'hours', 'minutes', 'seconds'] as Array<keyof IDuration>;
/**
 * The ISO8601 regex for matching / testing durations
 */
const pattern: RegExp = new CoreRegExp(iso8601);

export class CoreDuration {
    /** Parse PnYnMnDTnHnMnS format to object
     * @param {string} durationString - PnYnMnDTnHnMnS formatted string
     * @return {Object} - With a property for each part of the pattern
     */
    public static parse(durationString: string): IDuration {
        // const matches = pattern.exec(durationString) || [];
        const matches = pattern.exec(durationString) || [];
        // TODO: replace reduce function!
        // Slice away first entry in match-array
        return matches.slice(1).reduce<IDuration>((prev, next, idx) => {
            prev[objMap[idx]] = parseFloat(next) || 0;
            return prev;
        }, {});
    }

    public static stringify(duration: IDuration): string {
        const { years, months, days, hours, minutes, seconds } = duration;
        let res = 'P';

        if (years) res += `${years}Y`;

        if (months) res += `${months}M`;

        if (days) res += `${days}D`;

        if (hours || minutes || seconds) res += 'T';

        if (hours) res += `${hours}H`;

        if (minutes) res += `${minutes}M`;

        if (seconds) res += `${seconds}S`;

        if (res.length === 1) res += 'T0S';

        return res;
    }

    /**
     * Convert ISO8601 duration object to milliseconds
     *
     * @param {Object} duration - The duration object
     * @param {Date} startDate - The starting point for calculating the duration
     * @return {Number}
     */
    public static toMilliseconds(duration: IDuration, startDate?: Date) {
        const timestamp = startDate ? startDate.getTime() : Date.now();
        const now = new Date(timestamp);
        const then = this.end(duration, now);

        const seconds = then.getTime() - now.getTime();
        return seconds;
    }

    /**
     * Convert ISO8601 duration object to an end Date.
     *
     * @param {Object} duration - The duration object
     * @param {Date} startDate - The starting Date for calculating the duration
     * @return {Date} - The resulting end Date
     */
    public static end(duration: IDuration, startDate?: Date) {
        const { years = 0, months = 0, days = 0, hours = 0, minutes = 0, seconds = 0, weeks = 0 } = duration;
        // Create two equal timestamps, add duration to 'then' and return time difference
        const timestamp = startDate ? startDate.getTime() : Date.now();
        const then = new Date(timestamp);

        then.setFullYear(then.getFullYear() + years);
        then.setMonth(then.getMonth() + months);
        then.setDate(then.getDate() + days);
        then.setHours(then.getHours() + hours);
        then.setMinutes(then.getMinutes() + minutes);
        // Then.setSeconds(then.getSeconds() + duration.seconds);
        then.setMilliseconds(then.getMilliseconds() + seconds * 1000);
        // Special case weeks
        then.setDate(then.getDate() + weeks * 7);

        return then;
    }
}

export default CoreDuration;

export * from './DurationFormat';
