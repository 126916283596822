import './HeaderMenu.scss';

import CoreBoolean from '@bnb-tech/core/src/common/CoreBoolean';
import LocalStorage from '@bnb-tech/core/src/data_interfaces/LocalStorage';
import { isNullOrUndefined } from '@bnb-tech/core/src/typeguards';
import {
    Logout as faArrowRightFromBracket,
    Check as faCheck,
    MoreVert as faEllipsisVertical,
    Home as faHouse,
    DarkMode as faMoon,
    Close as faTimes,
    VolumeUp as faVolume,
    VolumeOff as faVolumeSlash,
} from '@mui/icons-material';
import { IconButton, ListItemIcon, MenuItem, Popover, Typography } from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';

import { audioContext } from '../../../App';
import Icon from '../../../components/Icon';
import { FlagIcon } from '../../../components/LanguageBadge/FlagIcon';
import { IAgencyUser } from '../../../models/domain/agency/IAgencyUser';
import ResourceContext from '../../../services/i18n/context/ResourceContext';
import useTranslation from '../../../services/i18n/core/useTranslation';
import { Languages } from '../../../services/i18n/models/ILanguageDictionary';
import { ThemeContext } from '../../../theme/ThemeContext';

export interface IHeaderMenuProps {
    user: IAgencyUser;
    onOpenSignOutDialog(): void;
}

export function HeaderMenu({ user, onOpenSignOutDialog }: IHeaderMenuProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
    const [langAnchorEl, setLangAnchorEl] = useState<HTMLElement | undefined>(undefined);
    const { setTheme, type } = useContext(ThemeContext);
    const { currentLanguage, changeLanguage } = useContext(ResourceContext);
    const translation = useTranslation();
    const { SETTINGS_LOGOUT, MENU_DARK_MODE, MENU_DASHBOARD, MENU_SOUND, MENU_ENTER } = translation;
    const [isSoundDisabled, setSoundDisabled] = useState<boolean>(
        CoreBoolean.parse(LocalStorage.getItem('soundDisabled'), false)
    );

    const [isEnterAllowed, setEnterAllowed] = useState<boolean>(
        CoreBoolean.parse(LocalStorage.getItem('chat-enter-allowed'), false)
    );

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget), []);

    const handleMenuClose = useCallback(
        (ev: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(undefined);
            ev.preventDefault();
            ev.stopPropagation();
        },
        [setAnchorEl]
    );

    const handleLangClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => setLangAnchorEl(event.currentTarget),
        []
    );

    const handleLangMenuClose = useCallback(
        (ev: React.MouseEvent<HTMLElement>) => {
            setLangAnchorEl(undefined);
            ev.preventDefault();
            ev.stopPropagation();
        },
        [setLangAnchorEl]
    );

    const toggleTheme = useCallback(() => setTheme(type === 'dark' ? 'light' : 'dark'), [setTheme, type]);

    const toggleSound = () => {
        //
        LocalStorage.setItem('soundDisabled', isSoundDisabled ? 'false' : 'true');
        setSoundDisabled(!isSoundDisabled);
        void audioContext.resume();
    };

    const toggleEnter = () => {
        //
        LocalStorage.setItem('chat-enter-allowed', isEnterAllowed ? 'false' : 'true');
        setEnterAllowed(!isEnterAllowed);
    };

    return (
        <>
            <div className="dropdown">
                <div className="flex row align-items-center">
                    <IconButton onClick={handleLangClick} color="inherit" className="spacing margin right">
                        <div style={{ width: 20, height: 20, lineHeight: `22px` }}>
                            <FlagIcon language={currentLanguage} />
                        </div>
                    </IconButton>

                    <IconButton onClick={handleClick} color="inherit">
                        <Icon icon={faEllipsisVertical} />
                    </IconButton>
                </div>
            </div>

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                open={!isNullOrUndefined(anchorEl)}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                PaperProps={{ className: 'spacing double padding top bottom' }}
            >
                <MenuItem onClick={() => window.open(import.meta.env.VITE_ADMIN_LINK ?? '', '_blank')}>
                    <ListItemIcon>
                        <Icon icon={faHouse} />
                    </ListItemIcon>
                    {MENU_DASHBOARD}
                </MenuItem>
                {/*
                <MenuItem component={Link} to={WAITING_ROOM_PATH}>
                    <ListItemIcon>
                        <Icon icon={faUserClock} />
                    </ListItemIcon>
                    {MENU_WAITINGROOM}
                </MenuItem> */}

                <MenuItem onClick={toggleTheme}>
                    <ListItemIcon>
                        <Icon icon={faMoon} />
                    </ListItemIcon>
                    {MENU_DARK_MODE}
                </MenuItem>

                <MenuItem onClick={toggleSound}>
                    <ListItemIcon>
                        <Icon icon={isSoundDisabled ? faVolumeSlash : faVolume} />
                    </ListItemIcon>
                    {MENU_SOUND}
                </MenuItem>

                <MenuItem onClick={toggleEnter}>
                    <ListItemIcon>
                        <Icon icon={isEnterAllowed ? faCheck : faTimes} />
                    </ListItemIcon>
                    {MENU_ENTER}
                </MenuItem>

                <MenuItem onClick={onOpenSignOutDialog}>
                    <ListItemIcon>
                        <Icon color="error" icon={faArrowRightFromBracket} />
                    </ListItemIcon>
                    <Typography color="error">{SETTINGS_LOGOUT}</Typography>
                </MenuItem>
            </Popover>

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={langAnchorEl}
                open={!isNullOrUndefined(langAnchorEl)}
                onClose={handleLangMenuClose}
                onClick={handleLangMenuClose}
                PaperProps={{ className: 'spacing double padding top bottom' }}
            >
                {Object.values(Languages).map((x) => (
                    <MenuItem onClick={() => changeLanguage(x)} key={x}>
                        <ListItemIcon>
                            <FlagIcon language={x} />
                        </ListItemIcon>
                        {translation[x]}
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
}

export default HeaderMenu;
