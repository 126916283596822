export type AgencyUserRole = 'superuser' | 'administrator' | 'moderator' | 'chatter';

export enum AgencyUserRoles {
    SUPERUSER = 'superuser',
    ADMINISTRATOR = 'administrator',
    MODERATOR = 'moderator',
    CHATTER = 'chatter',
}

const AgencyUserRoleLabelMap = {
    [AgencyUserRoles.SUPERUSER]: 'Super User',
    [AgencyUserRoles.ADMINISTRATOR]: 'Administrator',
    [AgencyUserRoles.MODERATOR]: 'Moderator',
    [AgencyUserRoles.CHATTER]: 'Chatter',
};

export const formatAgencyUserRole = (role: AgencyUserRole) => AgencyUserRoleLabelMap[role] ?? '';
