import { createEmptyEntityCollection, type IEntity, type IEntityCollection } from '../../models/core/entity';
import PaginatedCollectionHelper from './PaginatedCollectionHelper';

export class EntityCollectionHelper {
    public static addToCollection<T extends IEntity>(
        collection: IEntityCollection<T> = createEmptyEntityCollection<T>(),
        ...itemsToAdd: T[]
    ): IEntityCollection<T> {
        return PaginatedCollectionHelper.addToCollection<T>(collection, ...itemsToAdd);
    }

    public static mergeCollection<T extends IEntity>(
        collection: IEntityCollection<T> = createEmptyEntityCollection<T>(),
        collectionToAdd: IEntityCollection<T>
    ): IEntityCollection<T> {
        return PaginatedCollectionHelper.mergeCollection<T>(collection, collectionToAdd);
    }

    public static removeFromCollection<T extends IEntity>(
        collection: IEntityCollection<T> = createEmptyEntityCollection<T>(),
        predicate: (value: T, index: number, array: T[]) => boolean
    ) {
        return PaginatedCollectionHelper.removeFromCollection<T>(collection, predicate);
    }

    public static removeFromCollectionByProperty<T extends IEntity>(
        collection: IEntityCollection<T> = createEmptyEntityCollection<T>(),
        key: keyof T,
        value: unknown
    ) {
        return PaginatedCollectionHelper.removeFromCollectionByProperty<T>(collection, key, value);
    }

    public static updateElementOfCollection<T extends IEntity>(
        collection: IEntityCollection<T> = createEmptyEntityCollection<T>(),
        predicate: (value: T, index: number, array: T[]) => T
    ) {
        return PaginatedCollectionHelper.updateElementOfCollection<T>(collection, predicate);
    }

    public static updateElementOfCollectionByProperty<T extends IEntity>(
        collection: IEntityCollection<T> = createEmptyEntityCollection<T>(),
        key: keyof T,
        value: unknown,
        updateFunction: T | ((value: T) => T)
    ) {
        return PaginatedCollectionHelper.updateElementOfCollectionByProperty<T>(collection, key, value, updateFunction);
    }
}
export default EntityCollectionHelper;
