import type { IAction } from '../actions';
import type { IDispatch, IMiddleware, IMiddlewareAPI } from '../models';

// eslint-disable-next-line @typescript-eslint/no-type-alias
type IMiddlewareHandler<A extends IAction = IAction> = (
    middlewareAPI: IMiddlewareAPI,
    next: IDispatch<A>,
    action: A
) => IAction | void | Promise<IAction | void>;

/**
 * helper for creating simple middlewares
 *
 * @export
 * @template A
 * @param {IMiddlewareHandler<A>} handler
 * @returns {IMiddleware}
 */
export function createMiddleware<A extends IAction = IAction>(handler: IMiddlewareHandler<A>): IMiddleware {
    return (middlewareAPI: IMiddlewareAPI) => (next: IDispatch<A>) => (action: A) =>
        handler(middlewareAPI, next, action);
}

export default createMiddleware;
