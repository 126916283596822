import type { IPaginatedCollection } from '../collection/IPaginatedCollection';
import type { IEntity } from './IEntity';

export interface IEntityCollection<T extends IEntity> extends IPaginatedCollection<T> {}

export const createEmptyEntityCollection = <T extends IEntity>(items?: T[]): IEntityCollection<T> => ({
    items: items ?? [],
    count: items?.length ?? 0,
});

export const createEntityCollection = createEmptyEntityCollection;
