import { Countries, Country } from '../../models/domain/identity';
import { Language, Languages } from '../../services/i18n/models/ILanguageDictionary';
import AUS from './flags/Australia.svg';
import At from './flags/Austria.svg';
import DK from './flags/Denmark.svg';
import En from './flags/English.svg';
import FR from './flags/France.svg';
import De from './flags/Germany.svg';
import It from './flags/Italy.svg';
import NL from './flags/Netherlands.svg';
import NZ from './flags/New_Zealand.svg';
import PL from './flags/Poland.svg';
import SE from './flags/Sweden.svg';
import Ch from './flags/Swiss.svg';
import GBR from './flags/United_Kingdom.svg';
import US from './flags/Usa.svg';

export function FlagIcon(props: { language: Language; width?: number }) {
    const { language, width = 24 } = props;

    if (navigator.appVersion.indexOf('Win') !== -1) {
        switch (language) {
            case Languages.DE: {
                return <img src={De} alt="de" style={{ height: width, width: 'auto' }} />;
            }
            case Languages.EN: {
                return <img src={En} alt="en" style={{ height: width, width: 'auto' }} />;
            }
            case Languages.IT: {
                return <img src={It} alt="it" style={{ height: width, width: 'auto' }} />;
            }
            case Languages.FR: {
                return <img src={FR} alt="fr" style={{ height: width, width: 'auto' }} />;
            }
            case Languages.PL: {
                return <img src={PL} alt="pl" style={{ height: width, width: 'auto' }} />;
            }
            case Languages.NL: {
                return <img src={NL} alt="nl" style={{ height: width, width: 'auto' }} />;
            }
            case Languages.DK: {
                return <img src={DK} alt="dk" style={{ height: width, width: 'auto' }} />;
            }
            case Languages.SE: {
                return <img src={SE} alt="se" style={{ height: width, width: 'auto' }} />;
            }
        }

        return <img src={De} alt="de" style={{ height: width, width: 'auto' }} />;
    }

    return <>{LanguageFlagMap[language]}</>;
}

const LanguageFlagMap = {
    [Languages.DE]: '🇩🇪',
    [Languages.EN]: '🇺🇸',
    [Languages.FR]: '🇫🇷',
    [Languages.IT]: '🇮🇹',
    [Languages.PL]: '🇵🇱',
    [Languages.NL]: '🇳🇱',
    [Languages.DK]: '🇩🇰',
    [Languages.SE]: '🇸🇪',
};

const CountryFlagMap = {
    [Countries.DE]: '🇩🇪',
    [Countries.AT]: '🇦🇹',
    [Countries.CH]: '🇨🇭',
    [Countries.US]: '🇺🇸',
    [Countries.IT]: '🇮🇹',
    [Countries.Fr]: '🇫🇷',
    [Countries.Gbr]: '🇬🇧',
    [Countries.Aus]: '🇦🇺',
    [Countries.Nz]: '🇳🇿',
    [Countries.Pl]: '🇵🇱',
    [Countries.Nl]: '🇳🇱',
    [Countries.Dk]: '🇩🇰',
    [Countries.Se]: '🇸🇪',
};

export function FlagByCountryIcon(props: { country: Country; width?: number }) {
    const { country, width = 16 } = props;

    if (navigator.appVersion.indexOf('Win') !== -1) {
        switch (country) {
            case Countries.DE: {
                return <img src={De} alt="de" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.US: {
                return <img src={US} alt="us" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.AT: {
                return <img src={At} alt="at" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.IT: {
                return <img src={It} alt="it" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.CH: {
                return <img src={Ch} alt="ch" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.Fr: {
                return <img src={FR} alt="fr" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.Pl: {
                return <img src={PL} alt="pl" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.Nl: {
                return <img src={NL} alt="nl" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.Aus: {
                return <img src={AUS} alt="aus" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.Gbr: {
                return <img src={GBR} alt="gbr" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.Nz: {
                return <img src={NZ} alt="nz" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.Dk: {
                return <img src={DK} alt="dk" style={{ height: width, width: 'auto' }} />;
            }
            case Countries.Se: {
                return <img src={SE} alt="se" style={{ height: width, width: 'auto' }} />;
            }
        }

        return <img src={De} alt="de" style={{ height: width, width: 'auto' }} />;
    }

    return <>{CountryFlagMap[country]}</>;
}
