import FetchService from '@bnb-tech/core/src/FetchService';
import { ActionCreator, DeclareAsyncAction, IAsyncAction, createAsyncActionEnum } from '@bnb-tech/core/src/StoreManager';

import ApiRoutes from '../config/ApiRoutes';
import { IAddUserImage } from '../models/contracts/requests/automatedUser/IAddUserImage';
import { IAgencyUser } from '../models/domain/agency/IAgencyUser';
import { IUser } from '../models/domain/identity';
import { IState } from '../models/state';

export const UserActions = {
    ...createAsyncActionEnum('GET_USER'),
    ...createAsyncActionEnum('UPLOAD_IMAGES'),
    ...createAsyncActionEnum('GET_AGENCY_USER'),
} as const;

export type UserAction =
    | DeclareAsyncAction<typeof UserActions.GET_USER, IAgencyUser, string>
    | DeclareAsyncAction<typeof UserActions.UPLOAD_IMAGES, IUser>
    | DeclareAsyncAction<typeof UserActions.GET_AGENCY_USER, IAgencyUser>;

export class UserActionCreator {
    public static getUser(): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.Identity.User);

        return ActionCreator.createAsyncAction(
            UserActions.GET_USER,
            [UserActions.GET_USER_REQUEST, UserActions.GET_USER_RESPONSE, UserActions.GET_USER_FAILED],
            (state: IState) => FetchService.get(href)
        );
    }

    public static uploadImages(images: IAddUserImage[], userId: string, projectId: string): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.UserImage.UploadImages(userId), ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(UserActions.UPLOAD_IMAGES, () => FetchService.put(href, images));
    }

    public static getAgencyUser(userId: string, projectId: string): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.AgencyUser.SelfOfProject(userId, projectId));

        return ActionCreator.createAsyncActionByType(UserActions.GET_AGENCY_USER, () => FetchService.get(href));
    }
}
export default UserActionCreator;
