import {
    EntityCollectionHelper,
    PersistorAction, 
    getResponse,
    getResponseParams,
    updateState,
} from '@bnb-tech/core/src/StoreManager'; 
import { REHYDRATE } from 'redux-persist';
import { createPaginatedCollection } from '@bnb-tech/core/src/models/core/collection/IPaginatedCollection';
import { IEntityCollection, createEmptyEntityCollection } from '@bnb-tech/core/src/models/core/entity';

import { AuthenticationAction, AuthenticationActions } from '../../../actions/AuthenticationActionCreator';
import { DatingAction, DatingActions } from '../../../actions/DatingActionCreator';
import { UserAction, UserActions } from '../../../actions/UserActionCreator';
import { isEntityCollection } from '../../../core/typeguards/isEntityCollection';
import { DateType } from '../../../models/core/date/DateType';
import { IAgencyUser } from '../../../models/domain/agency/IAgencyUser';
import { IChatGift, IDialog } from '../../../models/domain/chat';
import { IDialogInfo } from '../../../models/domain/chat/IDialogInfo';
import { IGDWA } from '../../../models/domain/chat/IGDWA';
import { IImageTag } from '../../../models/domain/imageTag/IImageTag';
import { IProject, IProjectPaginatedCollection } from '../../../models/domain/project';
import { ITemplateImage } from '../../../models/domain/templateImage/ITemplateImage';
import { ChatAction, ChatActions } from '../actions/ChatActionCreator';

export interface IPendingMessage {
    message: string;
    timestamp: number;
    datetime: DateType;
}

export interface IChatState {
    dialogs: IEntityCollection<IDialog>;
    gifts: IProjectPaginatedCollection<IEntityCollection<IChatGift>>;
    availableDialog: IDialog | undefined;
    projectOfAvailableDialog: IProject | undefined;
    agentOfAvailableDialog: IAgencyUser | undefined;
    dialogTrackstartTime?: string | undefined;
    availableDialogInformation: IDialogInfo | undefined;
    gdwa: IProjectPaginatedCollection<IGDWA>;
    imageTemplates: IEntityCollection<ITemplateImage>;
    imageTags: IEntityCollection<IImageTag>;
    isConnectedToWebSocket: boolean;
    isConnectionEstablished: boolean;
}

const ChatDefault: IChatState = {
    dialogs: createEmptyEntityCollection(),
    gifts: createPaginatedCollection(),
    dialogTrackstartTime: undefined,
    availableDialog: undefined,
    projectOfAvailableDialog: undefined,
    agentOfAvailableDialog: undefined,
    availableDialogInformation: undefined,
    isConnectedToWebSocket: false,
    isConnectionEstablished: false,
    gdwa: createPaginatedCollection(),
    imageTemplates: createEmptyEntityCollection(),
    imageTags: createEmptyEntityCollection(),
};

export function ChatReducer(
    state: IChatState = ChatDefault,
    action: PersistorAction | ChatAction | AuthenticationAction | DatingAction | UserAction
): IChatState {
    switch (action.type) {
        case ChatActions.SET_CONNECTION_STATE: {
            const { newConnectionState } = action.payload;
            return updateState(state, { isConnectedToWebSocket: newConnectionState });
        }
        case ChatActions.DIALOG_REFRESH: {
            const response = action.payload;

            if (isEntityCollection<IDialog>(response)) {
                return updateState(state, {
                    dialogs: EntityCollectionHelper.addToCollection(
                        response,
                        ...state.dialogs?.items.filter((x) => x.temp)
                    ),
                });
            }

            if (response.id !== state.availableDialog?.id) {
                return state;
            }

            return updateState(state, {
                dialogs: EntityCollectionHelper.updateElementOfCollectionByProperty(
                    state.dialogs,
                    'id',
                    response.id,
                    response
                ),
                availableDialog: response.id === state.availableDialog?.id ? response : state.availableDialog,
            });
        }

        case ChatActions.NEW_MESSAGE: {
            const { dialog, message } = action.payload;

            if (dialog !== state.availableDialog?.id) {
                return state;
            }

            return updateState(state, {
                availableDialog: updateState(state.availableDialog, {
                    messages: EntityCollectionHelper.addToCollection(state.availableDialog.messages, message),
                    lastMessage: message,
                    //   unreadCount:
                    //       message.senderId === state.availableDialog.partnerId
                    //           ? state.availableDialog.unreadCount + 1
                    //           : state.availableDialog.unreadCount,
                }),
            });
        }
        case ChatActions.DIALOG_READ: {
            const { dialogId } = action.payload;

            if (dialogId !== state.availableDialog?.id) {
                return state;
            }

            return updateState(state, {
                availableDialog:
                    dialogId === state.availableDialog?.id
                        ? updateState(state.availableDialog, {
                              unreadCount: 0,
                              messages: {
                                  ...(state.availableDialog.messages ?? createEmptyEntityCollection()),
                                  items: (state.availableDialog.messages?.items ?? []).map((x) =>
                                      x.isRead ? x : { ...x, isRead: true }
                                  ),
                              },
                          })
                        : state.availableDialog,
            });
        }
        case ChatActions.ADD_DIALOG_NOTE_RESPONSE: {
            const note = getResponse(action);

            if (state.availableDialogInformation) {
                return updateState(state, {
                    availableDialogInformation: {
                        ...state.availableDialogInformation,
                        notes: EntityCollectionHelper.addToCollection(state.availableDialogInformation.notes, note),
                    },
                });
            }

            return state;
        }
        case ChatActions.UPDATE_DIALOG_NOTE_RESPONSE: {
            const note = getResponse(action);

            if (state.availableDialogInformation) {
                return updateState(state, {
                    availableDialogInformation: {
                        ...state.availableDialogInformation,
                        notes: EntityCollectionHelper.updateElementOfCollectionByProperty(
                            state.availableDialogInformation.notes,
                            'id',
                            note.id,
                            (x) => (x.id === note.id ? note : x)
                        ),
                    },
                });
            }

            return state;
        }
        case ChatActions.DELETE_DIALOG_NOTE_RESPONSE: {
            const noteId = getResponseParams(action);

            if (state.availableDialogInformation) {
                return updateState(state, {
                    availableDialogInformation: {
                        ...state.availableDialogInformation,
                        notes: EntityCollectionHelper.removeFromCollectionByProperty(
                            state.availableDialogInformation.notes,
                            'id',
                            noteId
                        ),
                    },
                });
            }
            return state;
        }
        case ChatActions.SEND_DIALOG_TO_WAITING_ROOM_RESPONSE: {
            return updateState(state, {
                availableDialog: undefined,
                dialogTrackstartTime: undefined,
                projectOfAvailableDialog: undefined,
                agentOfAvailableDialog: undefined,
            });
        }
        case ChatActions.GET_AVAILABLE_DIALOG_RESPONSE: {
            const dialog = getResponse(action);
            return updateState(state, {
                availableDialog: dialog?.item,
                projectOfAvailableDialog: dialog?.project,
                dialogTrackstartTime: dialog ? new Date().toISOString() : undefined,
                dialogs: dialog ? EntityCollectionHelper.addToCollection(state.dialogs, dialog.item) : state.dialogs,
            });
        }
        case ChatActions.GET_AVAILABLE_DIALOG_REQUEST:
        case ChatActions.GET_AVAILABLE_DIALOG_FAILED: {
            return updateState(state, {
                availableDialog: undefined,
                projectOfAvailableDialog: undefined,
                agentOfAvailableDialog: undefined,
            });
        }

        case ChatActions.GET_DIALOG_INFO_RESPONSE: {
            return updateState(state, { availableDialogInformation: getResponse(action) });
        }

        case ChatActions.OCCUPY_DIALOG_FROM_WAITING_ROOM_RESPONSE: {
            const { dialog, dialogInformation, project } = getResponse(action);

            return updateState(state, {
                availableDialog: dialog,
                dialogTrackstartTime: dialog ? new Date().toISOString() : undefined,
                dialogs: dialog ? EntityCollectionHelper.addToCollection(state.dialogs, dialog) : state.dialogs,
                availableDialogInformation: dialogInformation,
                projectOfAvailableDialog: project,
            });
        }

        case ChatActions.GET_GDWA_RESPONSE: {
            return updateState(state, { gdwa: getResponse(action) });
        }

        case ChatActions.GET_IMAGE_TAGS_RESPONSE: {
            return updateState(state, { imageTags: getResponse(action) });
        }

        case ChatActions.GET_IMAGE_TEMPLATES_REQUEST: {
            return updateState(state, { imageTemplates: createEmptyEntityCollection() });
        }

        case ChatActions.GET_IMAGE_TEMPLATES_RESPONSE: {
            return updateState(state, { imageTemplates: getResponse(action) });
        }

        case DatingActions.PURCHASE_COIN_IMAGE_RESPONSE: {
            if (!state.availableDialogInformation || !state.availableDialogInformation.userInformation) {
                return state;
            }

            const image = getResponse(action);

            /*  if (image.chatMessageId && state.availableDialog) {
                const chatMessage = state.availableDialog.messages.items.find((x) => x.id === image.chatMessageId);
                if (chatMessage === undefined) {
                    return state;
                }

                return updateState(state, {
                    availableDialog: updateState(state.availableDialog, {
                        messages: EntityCollectionHelper.updateElementOfCollectionByProperty(
                            state.availableDialog.messages,
                            'id',
                            image.chatMessageId,
                            { ...chatMessage, image: image.image }
                        ),
                    }),
                });
            } */

            if (image.chatMessageId !== undefined) {
                return state;
            }

            return updateState(state, {
                availableDialogInformation: updateState(state.availableDialogInformation, {
                    userInformation: updateState(state.availableDialogInformation.userInformation, {
                        user: updateState(state.availableDialogInformation.userInformation.user, {
                            images: EntityCollectionHelper.updateElementOfCollectionByProperty(
                                state.availableDialogInformation.userInformation.user.images,
                                'id',
                                image.blurredImage,
                                image
                            ),
                        }),
                    }),
                }),
            });
        }
        case ChatActions.GET_DIALOG_INFO_REQUEST:
        case ChatActions.GET_DIALOG_INFO_FAILED: {
            return updateState(state, { availableDialogInformation: undefined, dialogTrackstartTime: undefined });
        }
        case ChatActions.SET_LANGUAGE_RESPONSE: {
            const { language, dialogId } = getResponseParams(action);
            if (state.availableDialogInformation?.id !== dialogId) {
                return state;
            }

            return updateState(state, {
                availableDialogInformation: updateState(state.availableDialogInformation, {
                    dialogLanguage: language,
                }),
            });
        }
        case ChatActions.GET_PRESENTS_RESPONSE: {
            return updateState(state, { gifts: getResponse(action) });
        }
        case ChatActions.SOFT_KICK: {
            return updateState(state, {
                dialogTrackstartTime: undefined,
                availableDialog: undefined,
                availableDialogInformation: undefined,
                projectOfAvailableDialog: undefined,
                agentOfAvailableDialog: undefined,
                isConnectedToWebSocket: false,
                isConnectionEstablished: false,
            });
        }
        case ChatActions.ON_WRITE: {
            const { isWriting, partnerId } = action.payload;

            return updateState(state, {
                dialogs: EntityCollectionHelper.updateElementOfCollection(state.dialogs, (x) =>
                    x.partner?.id === partnerId ? updateState(x, { isWriting }) : x
                ),
            });
        }

        case UserActions.GET_AGENCY_USER_RESPONSE: {
            return updateState(state, { agentOfAvailableDialog: getResponse(action) });
        }

        case ChatActions.ON_CONNECTED: {
            return updateState(state, { isConnectionEstablished: action.payload });
        }

        case REHYDRATE:
        case AuthenticationActions.SUBMIT_LOGIN:
        case AuthenticationActions.SUBMIT_LOGOUT: {
            return ChatDefault;
        }
        default:
            return state;
    }
}

export default ChatReducer;
