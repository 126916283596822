import FetchService from '@bnb-tech/core/src/FetchService';
import { ActionCreator, createAsyncActionEnum, DeclareAsyncAction } from '@bnb-tech/core/src/StoreManager';

import ApiRoutes from '../config/ApiRoutes';
import { IAgentDashboard } from '../models/domain/dashboard/IAgentDashboard';
import { IState } from '../models/state';

export const DashboardActions = {
    ...createAsyncActionEnum('GET_DASHBOARD'),
} as const;

export type DashboardAction = DeclareAsyncAction<typeof DashboardActions.GET_DASHBOARD, IAgentDashboard>;

export class DashboardActionCreator {
    public static getDashboard() {
        const href = ApiRoutes.createUrl(ApiRoutes.Dashboard.Agent);

        return ActionCreator.createAsyncAction(
            DashboardActions.GET_DASHBOARD,
            [
                DashboardActions.GET_DASHBOARD_REQUEST,
                DashboardActions.GET_DASHBOARD_RESPONSE,
                DashboardActions.GET_DASHBOARD_FAILED,
            ],
            (state: IState) => FetchService.get(href)
        );
    }
}

export default DashboardActionCreator;
