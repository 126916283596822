import { isBool } from '../typeguards';

export type ICustomEventHandler<T> = (data?: T) => void | boolean;

export interface ICustomEvent<T> {
    on(handler: ICustomEventHandler<T>): void;
    off(handler: ICustomEventHandler<T>): void;
}

export class CustomEvent<T = void> implements ICustomEvent<T> {
    private _handlers: Array<ICustomEventHandler<T>> = [];

    public on(handler: ICustomEventHandler<T>): void {
        this._handlers.push(handler);
    }

    public off(handler: ICustomEventHandler<T>): void {
        this._handlers = this._handlers.filter((h) => h !== handler);
    }

    public trigger(data?: T): boolean {
        let succeeded = true;

        for (const handler of this._handlers) {
            const result = handler(data);
            if (isBool(result) && !result) {
                succeeded = false;
            }
        }
        return succeeded;
    }

    public expose(): ICustomEvent<T> {
        return this;
    }
}

export default CustomEvent;
