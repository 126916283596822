import MuiTextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import { CSSProperties, memo } from 'react';

export interface ITextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
    rootClassName?: string;
    rootStyle?: CSSProperties;
    variant?: 'filled' | 'outlined' | 'standard';
}

export const TextField = memo((props: ITextFieldProps) => {
    const { label, rootClassName, rootStyle, fullWidth = true, variant, ...rest } = props;

    return <MuiTextField label={label} {...rest} variant={variant} fullWidth={fullWidth} />;
});

export default TextField;
