import CoreConfiguration from '@bnb-tech/core/src/config';

import App from './App';
import config from './config';
import IIFE from './core/IIFE';

CoreConfiguration.BASE_URL = config.BASE_URL;
CoreConfiguration.enableDevTools = import.meta.env.DEV;
CoreConfiguration.isDevelopmentMode = import.meta.env.DEV;

IIFE(async () => {
    const Bootstrapper = await import('@bnb-tech/core/src/Bootstrapper');

    void Bootstrapper.default.startUpStandalone('root', <App />);
});
