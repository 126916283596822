/* eslint-disable @typescript-eslint/no-unused-vars */

import './index.scss';

import Bootstrapper from '@bnb-tech/core/src/Bootstrapper';
import { IAction, IReducer, PersistGate, StoreProvider } from '@bnb-tech/core/src/StoreManager';
import persistorStorage from '@bnb-tech/core/src/StoreManager/storage/persistorStorage';
import { CssBaseline, StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { deDE } from '@mui/material/locale';
import React, { Suspense, memo, useContext, useLayoutEffect, useMemo } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';

import { AuthenticationAction, AuthenticationActions } from './actions/AuthenticationActionCreator';
import { AppRouteConfig } from './config/routes/AppRouteConfig';
import LoadingOverlay from './LoadingOverlay';
import AppMiddlewares from './middlewares/AppMiddlewares';
import { IState } from './models/state/IState';
import AppReducer from './reducer/AppReducers';
import BrowserNotificationService from './services/BrowserNotifications/BrowserNotificationService';
import ResourceContextProvider from './services/i18n/context/ResourceContextProvider';
import NotificationProvider from './services/Notifications/context/NotificationProvider';
import { ThemeContext } from './theme/ThemeContext';
import { ThemeContextProvider } from './theme/ThemeContextProvider';
import GlobalLoadingIndicatorContextProvider from './views/Mainpage/components/GlobalLoadingIndicator/GlobalLoadingIndicatorContextProvider';

const combinedReducer = AppReducer;

const rootReducer: IReducer<IState> = (state: IState, action: AuthenticationAction | IAction) => {
    let newState: IState | undefined = state;
    if (
        action.type === AuthenticationActions.SUBMIT_LOGOUT_RESPONSE ||
        action.type === AuthenticationActions.SESSION_EXPIRED
    ) {
        newState = undefined;
    }
    return combinedReducer(newState, action);
};

const { store, persistor } = Bootstrapper.initializeStore(rootReducer, AppMiddlewares, {
    key: `cherry`,
    storage: persistorStorage,
});

// export const globalHistory = createBrowserHistory();

export const audioContext = new AudioContext();

const App = memo((props: unknown) => {
    const { theme } = useContext(ThemeContext);
    const muiTheme = useMemo(() => createTheme(theme, deDE), [theme]);

    useLayoutEffect(() => {
        void BrowserNotificationService.requestPermission();
    }, []);

    const baseRoutes = useRoutes(AppRouteConfig);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
                <CssBaseline />
                <NotificationProvider>
                    <GlobalLoadingIndicatorContextProvider>
                        <Suspense fallback={<LoadingOverlay />}>{baseRoutes}</Suspense>
                    </GlobalLoadingIndicatorContextProvider>
                </NotificationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
});

const AppContainer = () => {
    return (
        <StoreProvider store={store}>
            <PersistGate loading={<LoadingOverlay />} persistor={persistor}>
                <ResourceContextProvider>
                    <BrowserRouter>
                        <ThemeContextProvider>
                            <App />
                        </ThemeContextProvider>
                    </BrowserRouter>
                </ResourceContextProvider>
            </PersistGate>
        </StoreProvider>
    );
};

class ErrorBoundary extends React.Component<{}, { didError: boolean }> {
    constructor(props: {}) {
        super(props);
        this.state = { didError: false };
    }

    // eslint-disable-next-line class-methods-use-this
    public render() {
        return <AppContainer />;
    }

    // eslint-disable-next-line class-methods-use-this
    public componentDidCatch() {
        localStorage.clear();
        this.setState({ didError: true });
    }
}

export default ErrorBoundary;
