import { useSelector } from '@bnb-tech/core/src/StoreManager';
import { Paper } from '@mui/material';
import { Suspense, lazy, memo, useEffect, useRef } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { HOME_PATH, MESSAGES_PATH } from '../../config/Paths';
import LoadingOverlay from '../../LoadingOverlay';
import { getAvailableDialog } from '../../selectors/ChatSelectors';
import Header from './Header';
import Home from './Home';

const MessagesPage = lazy(() => import(/* webpackChunkName: "MessagesPage" */ './MessagesPage'));

export interface IMainpageProps {}

const MainPage = memo((props: IMainpageProps) => {
    const navigate = useNavigate();
    const availableDialog = useSelector(getAvailableDialog);
    const prev = usePrevious(availableDialog);

    useEffect(() => {
        if (availableDialog?.id && prev?.id !== availableDialog?.id) {
            navigate(MESSAGES_PATH.replace(':id', availableDialog.id));
        } else {
            if (!availableDialog?.id && prev?.id) {
                // if (availableDialog !== prev && !availableDialog && prev) {
                navigate(HOME_PATH);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableDialog, prev]);

    return (
        <Paper id="main" className="flex column" variant="elevation" elevation={0} square>
            <Header />

            <main className="flex">
                <Suspense fallback={<LoadingOverlay />}>
                    <Routes>
                        <Route path={MESSAGES_PATH} Component={MessagesPage} />
                        <Route path={HOME_PATH} Component={Home} />
                    </Routes>
                </Suspense>
            </main>
        </Paper>
    );
});

export default MainPage;

function usePrevious<T>(value: T) {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}
