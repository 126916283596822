import { IDictionary } from '@bnb-tech/core/src/models/helper/IDictionary';
import { ReactChildren } from '@bnb-tech/core/src/models/helper/ReactChildren';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CellMode, Order, RowType, useStyles } from './DataTable';
import { applyFilters, getComparator, stableSort } from './helper/DataTableHelper';
import { IColumnDefinition } from './IColumnDefinition';
import { IFilterValue } from './models/IFilterValue';

export interface IUseDataTableProps {
    initialRowsPerPage: number;
    initialSort?: { orderBy: string; order: Order };
    selectedRows?: IDictionary<string>;
    columns: IColumnDefinition[];
    rows: RowType[];
    customRow?: ReactChildren | ((rows: RowType) => unknown);
    initialFilter?: IDictionary<Array<IFilterValue<unknown>>>;
    currentPage?: number;
    onRowSelectionChanged?(selectedRows: IDictionary<string>): void;
    onNextPage?(newPage: number, rowsPerPage: number): void;
    onCellChanged?(row: RowType, column: IColumnDefinition, newValue: unknown): void;
}

export function useDataTable(props: IUseDataTableProps) {
    const {
        initialRowsPerPage,
        initialSort,
        selectedRows,
        columns,
        rows,
        onRowSelectionChanged,
        onNextPage,
        onCellChanged,
        customRow,
        initialFilter = {},
        currentPage = 0,
    } = props;

    const [page, setPage] = useState(currentPage);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

    const [selected, setSelected] = useState<IDictionary<string>>(selectedRows ?? {});
    const [order, setOrder] = useState<Order | undefined>(initialSort?.order);
    const [orderBy, setOrderBy] = useState<string | undefined>(initialSort?.orderBy);

    const [cellModeDic, setCellModeDic] = useState<IDictionary<IDictionary<CellMode>>>({});

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [filters, setFilters] = useState<IDictionary<Array<IFilterValue<unknown>>>>(initialFilter);

    const classes = useStyles();

    useEffect(() => {
        if (selectedRows) {
            setSelected(selectedRows);
        }
    }, [selectedRows]);

    useEffect(() => {
        setPage(currentPage);

        if (onNextPage) {
            onNextPage(currentPage, rowsPerPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, rows]);

    const handleRequestSort = useCallback(
        (_event: React.MouseEvent<unknown>, property: string) => {
            const isAsc = orderBy === property && order === 'asc';

            if (order === 'desc') {
                setOrder(undefined);
                setOrderBy(undefined);
            } else {
                setOrder(isAsc ? 'desc' : 'asc');
                setOrderBy(property);
            }
        },
        [order, orderBy]
    );

    const handleSelectAllClick = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            let newSelected: IDictionary<string> = {};
            if (event.target.checked) {
                newSelected = rows.reduce<IDictionary<string>>((prevVal, n) => ({ ...prevVal, [n.id]: n.id }), {});
            } else {
                setSelected({});
            }

            setSelected(newSelected);
            if (onRowSelectionChanged) {
                onRowSelectionChanged(newSelected);
            }
        },
        [onRowSelectionChanged, rows]
    );

    const handleClick = useCallback(
        (_event: React.MouseEvent<unknown>, id: string) => {
            let newSelected = selected;
            if (id in selected) {
                newSelected = { ...selected };
                delete newSelected[id];
            } else {
                newSelected = { ...selected, [id]: id };
            }

            setSelected(newSelected);
            if (onRowSelectionChanged) {
                onRowSelectionChanged(newSelected);
            }
        },
        [onRowSelectionChanged, selected]
    );

    const handleChangePage = useCallback(
        (_event: unknown, newPage: number) => {
            if (newPage > page && onNextPage) {
                onNextPage(newPage, rowsPerPage);
            }
            setPage(newPage);
        },
        [onNextPage, page, rowsPerPage]
    );

    const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, []);

    const isSelected = useCallback((id: string) => id in selected, [selected]);

    const emptyRows = useMemo(
        () => rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage),
        [page, rows.length, rowsPerPage]
    );

    const filteredRows = useMemo(() => applyFilters(rows, filters, columns), [columns, filters, rows]);

    const sortedRows = useMemo(
        () =>
            stableSort(filteredRows, getComparator(order, orderBy, columns)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ),
        [columns, filteredRows, order, orderBy, page, rowsPerPage]
    );

    const handleDoubleClick = (column: IColumnDefinition, row: RowType, mode?: CellMode) => {
        setCellModeDic({ ...cellModeDic, [row.id]: { ...(cellModeDic[row.id] ?? {}), [column.id]: mode } });
    };
    const handleFocusLost = (column: IColumnDefinition, row: RowType) => {
        setCellModeDic({ ...cellModeDic, [row.id]: { ...(cellModeDic[row.id] ?? {}), [column.id]: 'view' } });
    };
    const handleValueChange = (column: IColumnDefinition, row: RowType, newValue: unknown) => {
        //
        if (onCellChanged) {
            onCellChanged(row, column, newValue);
        }
    };

    useEffect(() => {
        if (filteredRows.length < page * rowsPerPage) {
            setPage(0);
        }
    }, [filteredRows.length, page, rowsPerPage]);

    return {
        sortedRows,
        handleChangePage,
        handleChangeRowsPerPage,
        emptyRows: customRow ? Math.max(emptyRows - 1, 0) : emptyRows,
        isSelected,
        handleClick,
        handleSelectAllClick,
        handleRequestSort,
        selected,
        order,
        orderBy,
        page,
        rowsPerPage,
        classes,
        setFilters,
        filters,
        handleDoubleClick,
        handleFocusLost,
        handleValueChange,
        cellModeDic,
        filteredRows,
    };
}
