import FetchService from '@bnb-tech/core/src/FetchService';
import {
    ActionCreator,
    DeclareAsyncAction,
    IAsyncAction,
    IBaseAction,
    createAsyncActionEnum,
} from '@bnb-tech/core/src/StoreManager';
import { IEntityCollection, createEmptyEntityCollection } from '@bnb-tech/core/src/models/core/entity';

import ApiRoutes from '../../../config/ApiRoutes';
import Config from '../../../config/config';
import { IAddDialogNoteRequest } from '../../../models/contracts/requests/chat/IAddDialogNoteRequest';
import { IDialogReadRequest } from '../../../models/contracts/requests/chat/IDialogReadRequest';
import { IGetDialogRequest } from '../../../models/contracts/requests/chat/IGetDialogRequest';
import { IOnWriteRequest } from '../../../models/contracts/requests/chat/IOnWriteRequest';
import { ISendImageRequest } from '../../../models/contracts/requests/chat/ISendImageRequest';
import { ISendMessageRequest } from '../../../models/contracts/requests/chat/ISendMessageRequest';
import { IDialogReadResponse } from '../../../models/contracts/responses/chat/IDialogReadResponse';
import { IGetNextDialogResponse } from '../../../models/contracts/responses/chat/IGetNextDialogResponse';
import { INewMessageResponse } from '../../../models/contracts/responses/chat/INewMessageResponse';
import { IOnWriteResponse } from '../../../models/contracts/responses/chat/IOnWriteResponse';
import { IStarProductPurchase } from '../../../models/contracts/responses/shop/IStarProductPurchase';
import { ChatMessageType, ChatMessageTypes, IChatGift, IDialog } from '../../../models/domain/chat';
import { IChatPartnerInformation } from '../../../models/domain/chat/IChatPartnerInformation';
import { IDialogInfo } from '../../../models/domain/chat/IDialogInfo';
import { IGDWA } from '../../../models/domain/chat/IGDWA';
import { IDatingUser, IDatingUserPreview } from '../../../models/domain/dating';
import { IImageTag } from '../../../models/domain/imageTag/IImageTag';
import { IProjectItem, IProjectPaginatedCollection } from '../../../models/domain/project';
import { ITemplateImage } from '../../../models/domain/templateImage/ITemplateImage';
import { IState } from '../../../models/state';
import { Language } from '../../i18n/models/ILanguageDictionary';
import { ICoinsData } from '../listener/ICoinsData';
import { IDialogNote } from './../../../models/domain/chat/IDialogNote';
import { IAddTicketRequest } from './../../../models/domain/ticket/IAddTicketRequest';
import { TicketType } from './../../../models/domain/ticket/TicketType';

export const ChatActions = {
    // OUTGOING
    SEND_MESSAGE: 'SEND_MESSAGE',
    READ_DIALOG: 'READ_DIALOG',
    REFRESH_DIALOG: 'REFRESH_DIALOG',
    WRITE: 'WRITE',

    // INCOMING
    NEW_MESSAGE: 'NEW_MESSAGE',
    DIALOG_READ: 'DIALOG_READ',
    DIALOG_REFRESH: 'DIALOG_REFRESH',
    DIALOGS_REFRESH: 'REFRESH_DIALOGS',
    UPDATE_COINS: 'UPDATE_COINS',
    ON_WRITE: 'ON_WRITE',
    ON_CONNECTED: 'ON_CONNECTED',

    // REST
    ...createAsyncActionEnum('CREATE_DIALOG'),
    ...createAsyncActionEnum('DELETE_DIALOG'),

    ...createAsyncActionEnum('GET_PRESENTS'),
    ...createAsyncActionEnum('GET_AVAILABLE_DIALOG'),
    ...createAsyncActionEnum('GET_DIALOG_INFO'),
    ...createAsyncActionEnum('UPDATE_DIALOG_INFO'),
    ...createAsyncActionEnum('UPDATE_CHAT_PARTNER_INFO'),
    ...createAsyncActionEnum('ADD_DIALOG_NOTE'),
    ...createAsyncActionEnum('UPDATE_DIALOG_NOTE'),
    ...createAsyncActionEnum('DELETE_DIALOG_NOTE'),
    ...createAsyncActionEnum('SEND_DIALOG_TO_WAITING_ROOM'),
    ...createAsyncActionEnum('OCCUPY_DIALOG_FROM_WAITING_ROOM'),
    ...createAsyncActionEnum('ADD_TICKET'),
    ...createAsyncActionEnum('GET_GDWA'),
    ...createAsyncActionEnum('GET_IMAGE_TEMPLATES'),
    ...createAsyncActionEnum('GET_IMAGE_TAGS'),

    // FAVORITE
    ...createAsyncActionEnum('SET_FAVORITE'),
    ...createAsyncActionEnum('UNSET_FAVORITE'),
    ...createAsyncActionEnum('BUY_STARPRODUCT'),

    ...createAsyncActionEnum('SEND_GIFT'),
    ...createAsyncActionEnum('SEND_IMAGE'),

    ...createAsyncActionEnum('SET_LANGUAGE'),

    REMOVE_TEMP_DIALOG: 'REMOVE_TEMP_DIALOG',

    SOFT_KICK: 'SOFT_KICK',
    SET_CONNECTION_STATE: 'SET_CONNECTION_STATE',
} as const;

// OUTGOING
type ISendMessageAction = IBaseAction<typeof ChatActions.SEND_MESSAGE, ISendMessageRequest>;
type IReadDialogAction = IBaseAction<typeof ChatActions.READ_DIALOG, IDialogReadRequest>;
type IRefreshDialogAction = IBaseAction<typeof ChatActions.REFRESH_DIALOG, IGetDialogRequest>;
type IRefreshDialogsAction = IBaseAction<typeof ChatActions.DIALOGS_REFRESH>;
type IWriteAction = IBaseAction<typeof ChatActions.WRITE, IOnWriteRequest>;

type IRemoveTempDialogAction = IBaseAction<typeof ChatActions.REMOVE_TEMP_DIALOG, { uuid: string }>;
type ISetConnectionStateAction = IBaseAction<typeof ChatActions.SET_CONNECTION_STATE, { newConnectionState: boolean }>;

// INCOMING
type IDialogRefreshAction = IBaseAction<typeof ChatActions.DIALOG_REFRESH, IDialog | IEntityCollection<IDialog>>;
type INewMessageAction = IBaseAction<typeof ChatActions.NEW_MESSAGE, INewMessageResponse>;
type IUpdateCoinsAction = IBaseAction<typeof ChatActions.UPDATE_COINS, ICoinsData>;
type IDialogReadAction = IBaseAction<typeof ChatActions.DIALOG_READ, IDialogReadResponse>;
type IOnWriteAction = IBaseAction<typeof ChatActions.ON_WRITE, IOnWriteResponse>;
type IOnConnectedAction = IBaseAction<typeof ChatActions.ON_CONNECTED, boolean>;

export type ChatAction =
    | ISendMessageAction
    | IReadDialogAction
    | IRefreshDialogAction
    | IRefreshDialogsAction
    | IDialogRefreshAction
    | INewMessageAction
    | IUpdateCoinsAction
    | IDialogReadAction
    | IRemoveTempDialogAction
    | IOnConnectedAction
    | ISetConnectionStateAction
    | DeclareAsyncAction<typeof ChatActions.BUY_STARPRODUCT, IStarProductPurchase, { stars: number }>
    | IBaseAction<typeof ChatActions.CREATE_DIALOG, IDialog>
    | DeclareAsyncAction<typeof ChatActions.DELETE_DIALOG, undefined, { id: string }>
    | DeclareAsyncAction<typeof ChatActions.UNSET_FAVORITE, undefined, { dialogId: string; name: string }>
    | DeclareAsyncAction<typeof ChatActions.SET_FAVORITE, undefined, { dialogId: string; name: string }>
    | DeclareAsyncAction<typeof ChatActions.SEND_GIFT, undefined, { dialogId: string; name: string; cost: number }>
    | DeclareAsyncAction<typeof ChatActions.GET_AVAILABLE_DIALOG, IProjectItem<IDialog> | undefined>
    | DeclareAsyncAction<typeof ChatActions.GET_DIALOG_INFO, IDialogInfo>
    | DeclareAsyncAction<typeof ChatActions.GET_GDWA, IProjectPaginatedCollection<IGDWA>>
    | DeclareAsyncAction<typeof ChatActions.GET_PRESENTS, IProjectPaginatedCollection<IEntityCollection<IChatGift>>>
    | DeclareAsyncAction<typeof ChatActions.UPDATE_DIALOG_INFO>
    | DeclareAsyncAction<typeof ChatActions.UPDATE_CHAT_PARTNER_INFO>
    | DeclareAsyncAction<typeof ChatActions.ADD_DIALOG_NOTE, IDialogNote>
    | DeclareAsyncAction<typeof ChatActions.UPDATE_DIALOG_NOTE, IDialogNote>
    | DeclareAsyncAction<typeof ChatActions.DELETE_DIALOG_NOTE, undefined, string>
    | DeclareAsyncAction<typeof ChatActions.SEND_DIALOG_TO_WAITING_ROOM>
    | DeclareAsyncAction<typeof ChatActions.OCCUPY_DIALOG_FROM_WAITING_ROOM, IGetNextDialogResponse>
    | DeclareAsyncAction<typeof ChatActions.ADD_TICKET, undefined, TicketType>
    | DeclareAsyncAction<typeof ChatActions.SEND_IMAGE, undefined, { dialogId: string; name: string; cost: number }>
    | DeclareAsyncAction<typeof ChatActions.GET_IMAGE_TEMPLATES, IEntityCollection<ITemplateImage>>
    | DeclareAsyncAction<typeof ChatActions.GET_IMAGE_TAGS, IEntityCollection<IImageTag>>
    | DeclareAsyncAction<typeof ChatActions.SET_LANGUAGE, undefined, { dialogId: string; language: Language }>
    | IBaseAction<typeof ChatActions.SOFT_KICK>
    | IWriteAction
    | IOnWriteAction;

export class ChatActionCreator {
    // OUTGOING
    public static sendMessage(
        dialog: string,
        message: string | null,
        type: ChatMessageType = ChatMessageTypes.Message,
        userId: string,
        projectId: string
    ) {
        const req: ISendMessageRequest = {
            dialogId: dialog,
            message: message ?? null,
            recipientId: userId,
            type: type,
            projectId,
        };
        return ActionCreator.createAction(ChatActions.SEND_MESSAGE, req);
    }

    public static readDialog(dialog: IDialogReadRequest) {
        return ActionCreator.createAction(ChatActions.READ_DIALOG, dialog);
    }

    /**
     * loads all dialogs or all messages of one specific dialog
     *
     * @static
     * @param {string} [dialog]
     * @returns
     * @memberof ChatActionCreator
     */
    public static refresh(projectId: string, dialog?: string, page?: number, pageSize?: number) {
        return ActionCreator.createAction<typeof ChatActions.REFRESH_DIALOG, IGetDialogRequest>(
            ChatActions.REFRESH_DIALOG,
            { dialogId: dialog, page, pageSize: pageSize ?? Config.CHAT_PAGE_SIZE, projectId }
        );
    }

    public static createDialog(chatWithUsername: string, strangerUser: IDatingUser | IDatingUserPreview) {
        // const url = generateValidUrl(Config.DIALOG_URL);

        const dialog: IDialog = {
            id: strangerUser.id,
            messages: createEmptyEntityCollection(),
            unreadCount: 0,
            isFavorite: false,
            partnerId: strangerUser.id,
            partner: {
                id: strangerUser.id,
                name: strangerUser.name,
                username: strangerUser.name,
                age: strangerUser.age,
                birthdate: strangerUser.birthdate,
                image: strangerUser.profileImage,
            },

            temp: true,
            genDate: new Date().toISOString(),
        };

        return ActionCreator.createAction(ChatActions.CREATE_DIALOG, dialog);

        // return ActionCreator.createAsyncActionByType(
        //     Config.BLACKLIST_ACTION_PREFIX + 'CREATE_DIALOG',
        //     [ChatActions.CREATE_DIALOG_REQUEST, ChatActions.CREATE_DIALOG_RESPONSE, ChatActions.CREATE_DIALOG_FAILED],
        //     (state: IState) => {
        //
        //         return FetchService.fetch(url, { username: chatWithUsername }, HttpMethods.POST, );
        //     },
        //     undefined,
        //     strangerUser
        // );
    }

    public static closeDialog(id: string, projectId: string) {
        const url = ApiRoutes.createUrl(ApiRoutes.Chat.Self(id), ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(
            ChatActions.DELETE_DIALOG,
            (state: IState) => FetchService.delete(url),
            { id }
        );
    }

    public static write(userId: string, partnerId: string, isWriting: boolean, projectId: string) {
        const req: IOnWriteRequest = {
            userId,
            partnerId,
            isWriting,
            projectId,
        };
        return ActionCreator.createAction(ChatActions.WRITE, req);
    }

    public static sendDialogToWaitingRoom(id: string, projectId: string): IAsyncAction {
        const url = ApiRoutes.createUrl(ApiRoutes.Chat.WaitingRoomSelf(id), ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(
            ChatActions.SEND_DIALOG_TO_WAITING_ROOM,

            () => FetchService.put(url)
        );
    }

    public static occupyDialogFromWaitingRoom(id: string, projectId: string): IAsyncAction {
        const url = ApiRoutes.createUrl(ApiRoutes.Chat.WaitingRoomSelf(id), ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(
            ChatActions.OCCUPY_DIALOG_FROM_WAITING_ROOM,

            () => FetchService.post(url)
        );
    }

    // INCOMING
    public static dialogRefresh(data: IDialog | IEntityCollection<IDialog>): IDialogRefreshAction {
        return ActionCreator.createAction(ChatActions.DIALOG_REFRESH, data);
    }

    public static newMessage(data: INewMessageResponse): INewMessageAction {
        return ActionCreator.createAction(ChatActions.NEW_MESSAGE, data);
    }

    public static updateCoins(data: ICoinsData): IUpdateCoinsAction {
        return ActionCreator.createAction(ChatActions.UPDATE_COINS, data);
    }

    public static dialogRead(data: IDialogReadResponse): IDialogReadAction {
        return ActionCreator.createAction(ChatActions.DIALOG_READ, data);
    }

    public static onWrite(data: IOnWriteResponse) {
        return ActionCreator.createAction(ChatActions.ON_WRITE, data);
    }

    public static sendGift(dialogId: string, giftId: string, cost: number, name: string, projectId: string) {
        const url = ApiRoutes.createUrl(ApiRoutes.Chat.SendGift(dialogId, giftId), ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(ChatActions.SEND_GIFT, (state: IState) => FetchService.post(url), {
            dialogId,
            name,
            cost,
        });
    }

    public static sendImage(dialogId: string, request: ISendImageRequest, projectId: string) {
        const url = ApiRoutes.createUrl(ApiRoutes.Chat.SendImage(dialogId), ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(ChatActions.SEND_IMAGE, () => FetchService.post(url, request));
    }

    public static removeTempDialog(uuid: string): IRemoveTempDialogAction {
        return ActionCreator.createAction(ChatActions.REMOVE_TEMP_DIALOG, { uuid: uuid });
    }

    public static getPresents() {
        const url = ApiRoutes.createUrl(ApiRoutes.Chat.Gifts);

        return ActionCreator.createAsyncActionByType(ChatActions.GET_PRESENTS, (state: IState) =>
            FetchService.get(url)
        );
    }

    public static getAvailableChat() {
        const url = ApiRoutes.createUrl(ApiRoutes.Chat.Next);

        return ActionCreator.createAsyncActionByType(ChatActions.GET_AVAILABLE_DIALOG, (state: IState) =>
            FetchService.get(url)
        );
    }

    public static getDialogInformation(id: string, projectId: string) {
        const url = ApiRoutes.createUrl(ApiRoutes.ChatInformation.DialogInformation(id), ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(ChatActions.GET_DIALOG_INFO, (state: IState) =>
            FetchService.get(url)
        );
    }

    public static getGDWA() {
        const url = ApiRoutes.createUrl(ApiRoutes.ChatInformation.GDWA);

        return ActionCreator.createAsyncActionByType(ChatActions.GET_GDWA, (state: IState) => FetchService.get(url));
    }

    public static getTemplateImages(userId: string, projectId: string) {
        const url = ApiRoutes.createUrl(ApiRoutes.Chat.TemplateImagesToUserId(userId), ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(ChatActions.GET_IMAGE_TEMPLATES, (state: IState) =>
            FetchService.get(url)
        );
    }

    public static getImageTags(projectId: string) {
        const url = ApiRoutes.createUrl(ApiRoutes.ImageTag.Base, ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(ChatActions.GET_IMAGE_TAGS, (state: IState) =>
            FetchService.get(url)
        );
    }

    public static updateChatPartnerInformation(current: IChatPartnerInformation, projectId: string) {
        const url = ApiRoutes.createUrl(
            ApiRoutes.ChatInformation.ChatPartnerInformation(current.dialogId, current.userId),
            ['projectId', projectId]
        );

        return ActionCreator.createAsyncActionByType(ChatActions.UPDATE_DIALOG_INFO, (state: IState) =>
            FetchService.patch(url, current)
        );
    }

    public static updateDialogInformation(current: IDialogInfo, projectId: string) {
        const url = ApiRoutes.createUrl(ApiRoutes.ChatInformation.DialogInformation(current.dialogId), [
            'projectId',
            projectId,
        ]);

        return ActionCreator.createAsyncActionByType(ChatActions.UPDATE_CHAT_PARTNER_INFO, (state: IState) =>
            FetchService.patch(url, current)
        );
    }

    public static addDialogNote(dialogId: string, text: string, projectId: string): IAsyncAction {
        const url = ApiRoutes.createUrl(ApiRoutes.ChatInformation.DialogNote(dialogId), ['projectId', projectId]);
        const request: IAddDialogNoteRequest = {
            text: text,
        };

        return ActionCreator.createAsyncActionByType(ChatActions.ADD_DIALOG_NOTE, () => FetchService.put(url, request));
    }

    public static updateDialogNote(dialogNote: IDialogNote, projectId: string): IAsyncAction {
        const url = ApiRoutes.createUrl(ApiRoutes.ChatInformation.DialogNoteSelf(dialogNote.dialogId, dialogNote.id), [
            'projectId',
            projectId,
        ]);

        return ActionCreator.createAsyncActionByType(ChatActions.UPDATE_DIALOG_NOTE, () =>
            FetchService.patch(url, dialogNote)
        );
    }

    public static deleteDialogNote(dialogNote: IDialogNote, projectId: string): IAsyncAction {
        const url = ApiRoutes.createUrl(ApiRoutes.ChatInformation.DialogNoteSelf(dialogNote.dialogId, dialogNote.id), [
            'projectId',
            projectId,
        ]);

        return ActionCreator.createAsyncActionByType(
            ChatActions.DELETE_DIALOG_NOTE,
            () => FetchService.delete(url),
            dialogNote.id
        );
    }

    public static addTicket(request: IAddTicketRequest, projectId: string): IAsyncAction {
        const url = ApiRoutes.createUrl(ApiRoutes.Ticket.BASE, ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(
            ChatActions.ADD_TICKET,
            () => FetchService.put(url, request),
            request.type
        );
    }

    public static softKick() {
        return ActionCreator.createAction(ChatActions.SOFT_KICK);
    }

    public static changeLanguage(dialogId: string, language: Language, projectId: string) {
        const url = ApiRoutes.createUrl(ApiRoutes.Chat.SetLanguage(dialogId), ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(
            ChatActions.SET_LANGUAGE,
            () => FetchService.patch(url, { language: language }),
            { dialogId, language }
        );
    }

    public static setConnectionState(newConnectionState: boolean) {
        return ActionCreator.createAction(ChatActions.SET_CONNECTION_STATE, { newConnectionState: newConnectionState });
    }

    public static onConnected(isEstablished: boolean) {
        return ActionCreator.createAction(ChatActions.ON_CONNECTED, isEstablished);
    }
}

export default ChatActionCreator;
