import { IEntity } from '@bnb-tech/core/src/models/core/entity';

import { Languages } from './../../../services/i18n/models/ILanguageDictionary';
import { Language } from '../../../services/i18n/models/ILanguageDictionary';
import { Currencies, Currency } from '../../core/Currency';
import { AgencyUserRole, AgencyUserRoles } from './AgencyUserRole';
import { IAgency } from './IAgency';

export interface IAgencyUser extends IEntity {
    userName: string;
    language: Language[];
    currency: Currency;
    agency?: IAgency;
    agencyId?: string;
    role: AgencyUserRole;
    plainPassword: string;
    active: boolean;
    totalIns: number;
    totalOuts: number;
    minDialog: number;
    minStandard: number;
    minAsa: number;
    amountPerIn: number;
    amountPerOut: number;
    totalRevenue: number;
}

export const defaultAgent: IAgencyUser = {
    id: '',
    active: false,
    amountPerIn: 0,
    amountPerOut: 0,
    currency: Currencies.EURO,
    language: [Languages.DE],
    minAsa: 100,
    role: AgencyUserRoles.CHATTER,
    minDialog: 100,
    minStandard: 100,
    userName: '',
    totalIns: 0,
    plainPassword: '',
    totalOuts: 0,
    totalRevenue: 0,
};

export const isAdmin = (user: IAgencyUser) =>
    user.role === AgencyUserRoles.ADMINISTRATOR || user.role === AgencyUserRoles.SUPERUSER;
