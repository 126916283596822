import {
    addToCollection,
    removeFromCollectionByProperty,
    updateElementOfCollectionByProperty,
    updateState,
} from '@bnb-tech/core/src/StoreManager';

import { NotificationAction, NotificationActions } from '../actions/NotificationActionCreator';
import { INotification } from '../models/INotification';

export interface INotificationState {
    notifications: INotification[];
}

const NotificationDefault: INotificationState = {
    notifications: [],
};

export function NotificationReducer(
    state: INotificationState = NotificationDefault,
    action: NotificationAction
): INotificationState {
    switch (action.type) {
        case NotificationActions.ENQUEUE_SNACKBAR: {
            return updateState(state, {
                notifications: addToCollection(state.notifications, action.payload),
            });
        }
        case NotificationActions.CLOSE_SNACKBAR: {
            const { dismissAll, key } = action.payload;

            if (dismissAll) {
                return updateState(state, {
                    notifications: state.notifications.map((notification) => ({ ...notification, dismissed: true })),
                });
            }

            return updateState(state, {
                notifications: updateElementOfCollectionByProperty(state.notifications, 'key', key, (notification) => ({
                    ...notification,
                    dismissed: true,
                })),
            });
        }
        case NotificationActions.REMOVE_SNACKBAR: {
            return updateState(state, {
                notifications: removeFromCollectionByProperty(state.notifications, 'key', action.payload),
            });
        }
        default:
            return state;
    }
}
