import { IDispatch, IMiddlewareAPI, getResponseParams } from '@bnb-tech/core/src/StoreManager';
import { OptionsObject } from 'notistack';
import { RehydrateAction } from 'redux-persist';

import { AuthenticationAction, AuthenticationActions } from './../../../actions/AuthenticationActionCreator';
import { UserAction } from './../../../actions/UserActionCreator';
import { TicketTypes } from '../../../models/domain/ticket/TicketType';
import { ChatActions } from './../../Chat/actions/ChatActionCreator';
import { ResourceService } from './../../i18n/ResourcesService';
import { ChatAction } from '../../Chat/actions/ChatActionCreator';
import NotificationActionCreator from '../actions/NotificationActionCreator';

type Actions = AuthenticationAction | RehydrateAction | UserAction | ChatAction;

export function NotificationMiddleware(middlewareAPI: IMiddlewareAPI) {
    const { dispatch } = middlewareAPI;
    const enqueueSnackbar = (message: string, options?: OptionsObject) =>
        dispatch(
            NotificationActionCreator.enqueueSnackbar({
                key: String(new Date().getTime() + Math.random()),
                message: message,
                options,
            })
        );

    return (next: IDispatch) => async (action: Actions) => {
        await next(action);

        switch (action.type) {
            case AuthenticationActions.SUBMIT_LOGIN_FAILED: {
                // TODO:
                enqueueSnackbar(ResourceService.getCurrentResources().ALERT_LOGIN_FAILED, { variant: 'error' });
                break;
            }
            /* case ChatActions.UPDATE_DIALOG_NOTE_FAILED: {
                const errorCode = action.payload.result.status;
                // eslint-disable-next-line no-console
                console.log(errorCode);
                if (errorCode === 401) {
                    enqueueSnackbar('Sie können keine Einträge von anderen ändern.', { variant: 'error' });
                }
                break;
            } */
            case ChatActions.ADD_TICKET_RESPONSE: {
                const type = getResponseParams(action);
                let message;

                if (type === TicketTypes.IMAGE_REQUEST) {
                    message = 'Bildanfrage erfolgreich versendet!';
                } else {
                    message = 'Chat gemeldet !';
                }

                enqueueSnackbar(message, { variant: 'success' });
                break;
            }

            // case ChatActions.NEW_MESSAGE: {
            //     const state = getState() as IState;
            //     // const user = getUser(state);

            //     const { message } = action.payload;

            //     const dialog = state.chat.dialogs.items.find((x) => x.id === action.payload.dialog);
            //     if (message.senderId === dialog?.partner?.id) {
            //         let text = '';
            //         switch (message.type) {
            //             case ChatMessageTypes.Zwinker: {
            //                 text = `${dialog?.partner.name ?? 'Jemand'} hat dir zugezwinkert 😉`;
            //                 break;
            //             }
            //             case ChatMessageTypes.Present: {
            //                 text = `${dialog?.partner.name ?? 'Jemand'} hat dir ein Geschenk geschickt 🎁`;
            //                 break;
            //             }
            //             default: {
            //                 text = `${dialog?.partner.name ?? 'Jemand'} hat dir eine Nachricht geschrieben`;
            //             }
            //         }

            //         enqueueSnackbar(text, { variant: 'info' });
            //     }

            //     break;
            // }

            default:
                break;
        }
    };
}

export default NotificationMiddleware;
