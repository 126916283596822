export type DateFormat =
    | 'time'
    | 'date'
    | 'dateTime'
    | 'longDateTime'
    | 'month'
    | 'hourMinute'
    | 'shortDate'
    | 'longDay';

export enum DateFormats {
    TIME = 'time',
    DATE = 'date',
    DATE_TIME = 'dateTime',
    LONG_DATE_TIME = 'longDateTime',
    MONTH = 'month',
    HOUR_MINUTE = 'hourMinute',
    SHORT_DATE = 'shortDate',
    LONG_DAY = 'longDay',
}

// TIME,
//     SHORT_DATE,
//     DATE,
//     DATE_TIME,
//     LONG_DAY,
//     MONTH,
