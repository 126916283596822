import { IDictionary } from '@bnb-tech/core/src/models/helper/IDictionary';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

import Checkbox from '../../../Checkbox';
import { Order, RowType, useStyles } from '../../DataTable';
import { IColumnDefinition } from '../../IColumnDefinition';
import { IFilterValue } from '../../models/IFilterValue';
import { TableHeadComponent } from './TableHeadComponent';

export interface IEnhancedTableHeadProps {
    enableMultiselect?: boolean;
    headers: IColumnDefinition[];
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    order?: Order;
    orderBy?: string;
    rowCount: number;
    rows: RowType[];

    filters: IDictionary<Array<IFilterValue<unknown>>>;
    setFilters(filters: IDictionary<Array<IFilterValue<unknown>>>): void;
    onRequestSort(event: React.MouseEvent<unknown>, property: string): void;
    onSelectAllClick(event: React.ChangeEvent<HTMLInputElement>): void;
}

export function EnhancedTableHead(props: IEnhancedTableHeadProps) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rows, filters, setFilters } = props;
    const { rowCount, onRequestSort, headers, enableMultiselect } = props;

    return (
        <TableHead>
            <TableRow>
                {enableMultiselect && (
                    <TableCell padding="checkbox" variant="head">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                )}
                {headers.map((column) => (
                    <TableHeadComponent
                        key={column.id}
                        column={column}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={onRequestSort}
                        classes={classes}
                        rows={rows}
                        filters={filters}
                        setFilters={setFilters}
                    />
                ))}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;
