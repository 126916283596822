import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useHistoryPush(path: string) {
    const navigate = useNavigate();

    const handler = useCallback(() => navigate(path), [navigate, path]);

    return handler;
}

export default useHistoryPush;
