import '../resources/css/common.css';

import { createRoot } from 'react-dom/client';
import { type PersistConfig } from 'redux-persist/es/types';

import BaseModule from 'ModuleManager/BaseModule';
import StoreManager, { type IAction, type IPersistor } from '../StoreManager';
import type { IMiddleware, IReducer, IReducerToCombine, IState, IStore } from 'StoreManager/models';
import CoreConfiguration from '../config';
import { isFunction, isNullOrUndefined } from '../typeguards';
import { register } from './registerServiceWorker';

export class Bootstrapper {
    /**
     * Creates a store instace with the given reducers, middlewares and those of the registered Modules and Plugins.
     *
     * @static
     * @param {IReducerToCombine} [reducer={}]
     * @param {IMiddleware[]} [middlewares=[]]
     * @returns {IStore<IState>}
     * @memberof Bootstrapper
     */
    public static initializeStore(
        reducer: IReducerToCombine | IReducer<IState, IAction> = {},
        middlewares: IMiddleware[] = [],
        config?: PersistConfig<IState, unknown, unknown, unknown>
    ): { store: IStore<IState>; persistor: IPersistor } {
        // Configure a Store; The Store is the Main Data Holder of the whole application.
        // Means that the Store is our Global modal and holds every piece of Information used
        const initStore = StoreManager.configure(reducer, middlewares, config);

        return initStore;
    }

    /**
     * Startup Routine for React Apps
     * You can use the Module and Plugin importer functions to
     * register your modules and plugins
     *
     * @static
     * @param {string} rootNodeName
     * @param {JSX.Element} rootNode
     * @param {() => Promise<BaseModule<any, any>[]>} [moduleImporter]
     * @param {() => Promise<BasePlugin<any, any>[]>} [pluginImporter]
     * @memberof Bootstrapper
     * @throws ROOT_NODE_NOT_FOUND
     */
    public static async startUpStandalone(
        rootNodeName: string,
        rootNode: JSX.Element | (() => JSX.Element),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        moduleImporter?: () => Promise<Array<BaseModule<any, any>>>
    ) {
        if (moduleImporter) {
            await moduleImporter();
        }

        const node = document.getElementById(rootNodeName);

        if (isNullOrUndefined(node)) {
            throw new Error(CoreConfiguration.messages.ERR_ROOT_NODE_NOT_FOUND);
        }

        const elementToRender = isFunction(rootNode) ? rootNode() : rootNode;

        createRoot(node).render(elementToRender);

        // The Service worker will be used in Production mode only, it will explicitly try to load all assets from Cache.
        register({
            onUpdate: () => {
                // localStorage.clear();
                window.location.reload();
            },
        });
    }
}

export default Bootstrapper;

export * from './IWindow';
