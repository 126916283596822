import './ActivityIndicator.scss';

import IMG from '../../assets/images/logos/cherry-middlewriting.svg';

export interface IActivityIndicatorProps {}

export const ActivityIndicator = (props: IActivityIndicatorProps) => {
    // return <IMG className="activity-indicator" height="32" width="32" />;
    return <img className="activity-indicator" src={IMG} height="32" width="32" alt="activity indicator" />;
};

export default ActivityIndicator;
