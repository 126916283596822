interface ICoreConfig {
    BASE_URL: string;
    isDevelopmentMode: boolean;
    enableDevTools: boolean;
    disableCookies: boolean;
    persistWhitelist: string[] | undefined;
    persistBlacklist: string[] | undefined;
    messages: {
        ERR_ROOT_NODE_NOT_FOUND: string;
        ERR_SECRETS_NOT_FOUND: string;
        ERR_LINK_CLASS_NOT_VALID: string;
        ERR_MODULE_NOT_REGISTERED: string;
    };
}

export const CoreConfiguration: ICoreConfig = {
    BASE_URL: '',
    enableDevTools: false,
    isDevelopmentMode: false,
    disableCookies: false,
    persistWhitelist: undefined,
    persistBlacklist: undefined,
    messages: {
        ERR_ROOT_NODE_NOT_FOUND: 'No Root node to render the app provided!',
        ERR_SECRETS_NOT_FOUND: 'Could not retrieve credentials.',
        ERR_LINK_CLASS_NOT_VALID: 'The retrieved Class is not valid',
        ERR_MODULE_NOT_REGISTERED: 'Requested module ({moduleId}) is not registered.',
    },
};

export default CoreConfiguration;
