import { useContext } from 'react';

import { GlobalLoadingIndicatorContext } from './GlobalLoadingIndicatorContext';

export function useLoadingIndicator() {
    const context = useContext(GlobalLoadingIndicatorContext);

    if (!context) {
        throw new Error('useLoadingIndicator must be used within a GlobalLoadingIndicatorContextProvider');
    }

    return context;
}

export default useLoadingIndicator;
