/* eslint-disable @typescript-eslint/no-explicit-any */

import { IDictionary } from '@bnb-tech/core/src/models/helper/IDictionary';
import { updateState } from '@bnb-tech/core/src/StoreManager';
import { isNullOrUndefined } from '@bnb-tech/core/src/typeguards';

import { Order, RowType } from '../DataTable';
import { IColumnDefinition } from '../IColumnDefinition';
import { IFilterValue } from '../models/IFilterValue';

export function renderCell(
    column: IColumnDefinition,
    row: RowType,
    value: unknown,
    index: number,
    mode?: 'view' | 'edit',
    onValueChange?: (column: IColumnDefinition, row: RowType, value: unknown) => void
): React.ReactNode {
    const { cellComponent, formatter, cellEditComponent } = column;

    if (mode === 'edit' && cellEditComponent && onValueChange) {
        return cellEditComponent(value, row, column, (newValue) => onValueChange(column, row, newValue));
    }

    if (cellComponent) {
        return cellComponent(value, row, column, index);
    } else if (formatter) {
        return formatter(value, row, column, index);
    } else if (!isNullOrUndefined(value)) {
        return String(value);
    } else {
        return null;
    }
}

export function descendingComparator<T = number | string>(a: T, b: T, orderBy: keyof T, column?: IColumnDefinition) {
    const aValue = column?.getValue
        ? column.getValue(a as any, column)
        : getValueByKey(String(orderBy), a as unknown as RowType);

    const bValue = column?.getValue
        ? column.getValue(b as any, column)
        : getValueByKey(String(orderBy), b as unknown as RowType);

    if (bValue < aValue) {
        return -1;
    }
    if (bValue > aValue) {
        return 1;
    }
    return 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComparator<Key extends keyof any>(
    order: Order | undefined,
    orderBy: Key | undefined,
    columns: IColumnDefinition[]
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    const column = orderBy ? columns.find((x) => x.id === orderBy) : undefined;

    return order === undefined || orderBy === undefined
        ? () => 0
        : order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, column)
        : (a, b) => -descendingComparator(a, b, orderBy, column);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
}

export function getValueByKey(key: string, row: RowType) {
    if (/\./.test(key)) {
        const keys = key.split('.');
        let objectToAccess = row;

        for (const currentKey of keys) {
            if (currentKey in objectToAccess) {
                objectToAccess = objectToAccess[currentKey];
            }
        }

        return objectToAccess;
    } else {
        return row[key];
    }
}

export function applyFilters<T extends RowType>(
    rows: T[],
    filters: IDictionary<Array<IFilterValue<unknown>>>,
    columns: IColumnDefinition[]
): T[] {
    const entries = Object.entries(filters);

    if (entries.length === 0) {
        return rows;
    }

    const filtered: T[] = [];

    const columnDic = columns.reduce<IDictionary<IColumnDefinition>>(
        (prevVal, currentValue) => updateState(prevVal, { [currentValue.id]: currentValue }),
        {}
    );

    for (const row of rows) {
        let didFilterMatch = false;

        for (const [column, filtersOfColumn] of entries) {
            const columnDefinition = columnDic[column];

            const value = columnDefinition.getValue
                ? columnDefinition.getValue(row, columnDefinition)
                : getValueByKey(String(column), row);

            for (const filter of filtersOfColumn) {
                const checkResult = filter.filter ? filter.filter(value, filter.value) : filter.value === value;

                if (filter.exclude) {
                    if (checkResult) {
                        didFilterMatch = true;
                        break;
                    }
                } else {
                    if (!checkResult) {
                        didFilterMatch = true;
                        break;
                    }
                }
            }

            if (didFilterMatch) {
                break;
            }
        }

        if (!didFilterMatch) {
            filtered.push(row);
        }
    }

    return filtered;
}
