import { ReactChildren } from '@bnb-tech/core/src/models/helper/ReactChildren';
import { useSelector } from '@bnb-tech/core/src/StoreManager';
import { memo } from 'react';
import { Navigate } from 'react-router-dom';

import { LOGIN_PATH, MAIN_PATH } from '../../config/Paths';
import { isAuthenticated } from '../../selectors/AuthenticationSelectors';

export interface ISecuredRouteProps {
    fallback?: string;
    insecureOnly?: boolean;
    adminSecured?: boolean;
    children: ReactChildren;
}

export const SecuredRoute = memo((props: ISecuredRouteProps) => {
    const { fallback = LOGIN_PATH, insecureOnly, children } = props;
    const authenticated = useSelector(isAuthenticated);

    if (!authenticated) {
        if (!insecureOnly) {
            return <Navigate to={fallback} />;
        }
    } else if (authenticated && insecureOnly) {
        return <Navigate to={MAIN_PATH} />;
    }

    return <>{children}</>;
});

export default SecuredRoute;
