import { type IStorage } from './models/IStorage';
import { SimpleStorage } from './SimpleStorage';

export class LocalStorage implements IStorage {
    private static _instance: LocalStorage;
    private _localStorage: Storage;

    public static getInstance(): LocalStorage {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        // check if the localstorage is supported!
        // otherwise create a Simple storage object
        if (localStorage) {
            this._localStorage = localStorage;
        } else {
            this._localStorage = new SimpleStorage();
            // eslint-disable-next-line no-native-reassign
            localStorage = this._localStorage;
        }
    }

    public getItem(key: string): string | null {
        return this._localStorage.getItem(key);
    }

    public setItem(key: string, item: string): void {
        this._localStorage.setItem(key, item);
    }

    public clear(): void {
        this._localStorage.clear();
    }

    public key(index: number): string | null {
        return this._localStorage.key(index);
    }

    public removeItem(key: string): void {
        this._localStorage.removeItem(key);
    }

    public get length(): number {
        return Object.keys(this._localStorage).length;
    }
}

export default LocalStorage.getInstance();
