import { getResponse, updateState } from '@bnb-tech/core/src/StoreManager';

import { AuthenticationAction, AuthenticationActions } from '../actions/AuthenticationActionCreator';
import { DatingAction } from '../actions/DatingActionCreator';
import { UserAction, UserActions } from '../actions/UserActionCreator';
import { IAgencyUser } from '../models/domain/agency/IAgencyUser';
import { ChatAction } from '../services/Chat/actions/ChatActionCreator';

export interface IUserState {
    user: IAgencyUser | undefined;
}

const UserDefault: IUserState = {
    user: undefined,
};

type Action = AuthenticationAction | UserAction | ChatAction | DatingAction;

export function UserReducer(state: IUserState = UserDefault, action: Action): IUserState {
    switch (action.type) {
        case AuthenticationActions.SUBMIT_LOGIN_RESPONSE: {
            const { user } = getResponse(action);
            return updateState(state, { user });
        }
        case AuthenticationActions.SUBMIT_LOGOUT_REQUEST: {
            return updateState(state, { user: undefined });
        }

        case UserActions.GET_USER_RESPONSE: {
            const user = getResponse(action);

            // if (!state.user || user.id !== state.user.id) {
            //     return state;
            // }

            return updateState(state, { user });
        }

        default:
            return state;
    }
}
