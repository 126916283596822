export type Country = 'de' | 'at' | 'ch' | 'us' | 'it' | 'fr' | 'gbr' | 'aus' | 'nz' | 'pl' | 'nl' | 'dk' | 'se';

export enum Countries {
    DE = 'de',
    AT = 'at',
    CH = 'ch',
    US = 'us',
    IT = 'it',
    Fr = 'fr',
    Gbr = 'gbr',
    Aus = 'aus',
    Nz = 'nz',
    Pl = 'pl',
    Nl = 'nl',
    Dk = 'dk',
    Se = 'se',
}

export const formatCountry = (country: Country) => CountryLabelMap[country] ?? '';
// export const formatCountryAsFlagEmoji = (country: Country) => CountryFlagMap[country] ?? '';

export const formatCountryWithNameAndEmoji = (country: Country) =>
    `${CountryLabelMap[country] ?? ''} ${CountryFlagMap[country] ?? ''}`;
const CountryLabelMap = {
    [Countries.DE]: 'Deutschland',
    [Countries.AT]: 'Österreich',
    [Countries.CH]: 'Schweiz',
    [Countries.US]: 'USA',
    [Countries.IT]: 'Italien',
    [Countries.Fr]: 'Frankreich',
    [Countries.Gbr]: 'Großbritannien',
    [Countries.Aus]: 'Australien',
    [Countries.Nz]: 'Neuseeland',
    [Countries.Pl]: 'Polen',
    [Countries.Nl]: 'Niederlande',
    [Countries.Dk]: 'Dänemark',
    [Countries.Se]: 'Schweden',
};
const CountryFlagMap = {
    [Countries.DE]: '🇩🇪',
    [Countries.AT]: '🇦🇹',
    [Countries.CH]: '🇨🇭',
    [Countries.US]: '🇺🇸',
    [Countries.IT]: '🇮🇹',
    [Countries.Fr]: '🇫🇷',
    [Countries.Gbr]: '🇬🇧',
    [Countries.Aus]: '🇦🇺',
    [Countries.Nz]: '🇳🇿',
    [Countries.Pl]: '🇵🇱',
    [Countries.Nl]: '🇳🇱',
    [Countries.Dk]: '🇩🇰',
    [Countries.Se]: '🇸🇪',
};
