import FetchService from '@bnb-tech/core/src/FetchService';
import { ActionCreator, createAsyncActionEnum, DeclareAsyncAction } from '@bnb-tech/core/src/StoreManager';

import ApiRoutes from '../config/ApiRoutes';
import { IAdminPurchaseCoinImageRequest } from '../models/contracts/requests/user/IAdminPurchaseCoinImageRequest';
import { IPurchaseCoinImageRequest } from '../models/contracts/requests/user/IPurchaseCoinImageRequest';
import { IUserImage } from '../models/domain/identity';
import { IState } from '../models/state';

export const DatingActions = {
    ...createAsyncActionEnum('PURCHASE_COIN_IMAGE'),
} as const;

export type DatingAction = DeclareAsyncAction<
    typeof DatingActions.PURCHASE_COIN_IMAGE,
    IUserImage,
    IPurchaseCoinImageRequest & { cost: number }
>;

export class DatingActionCreator {
    public static purchaseCoinImage(request: IAdminPurchaseCoinImageRequest, cost: number, projectId: string) {
        const href = ApiRoutes.createUrl(ApiRoutes.Dating.Image(request.imageId), ['projectId', projectId]);

        return ActionCreator.createAsyncActionByType(
            DatingActions.PURCHASE_COIN_IMAGE,
            (state: IState) => FetchService.post(href, request),
            { ...request, cost }
        );
    }
}

export default DatingActionCreator;
