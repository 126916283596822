import { ReactChildren } from '@bnb-tech/core/src/models/helper/ReactChildren';
import { Typography } from '@mui/material';
import { CSSProperties } from 'react';

export interface IEmptyStateProps {
    image?: string;
    title?: string;
    titleStyle?: CSSProperties;
    description?: string | ReactChildren;
    imageStyles?: CSSProperties;
}

export const EmptyState = (props: IEmptyStateProps) => {
    const { image, title, titleStyle, description, imageStyles = {} } = props;

    return (
        <div className="flex column align-items-center justify-content-center">
            {image && (
                <img
                    src={image}
                    alt="empty state"
                    style={{ maxWidth: 350, userSelect: 'none', ...imageStyles }}
                    className="spacing triple margin bottom"
                    decoding="async"
                    loading="lazy"
                    draggable={false}
                />
            )}
            {title && (
                <Typography variant="h5" style={titleStyle}>
                    {title}
                </Typography>
            )}
            {description && <Typography>{description}</Typography>}
        </div>
    );
};

export default EmptyState;
