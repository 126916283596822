import CoreDate from '../common/CoreDate/';
import { HttpMethods, type HttpMethod } from '../models/core/link';
import type { IDictionary } from '../models/helper/IDictionary';
import { Fetchmodes, type Fetchmode } from './Fetchmode';

//#region define Headers

const CONTENT_TYPE_HEADER = 'Content-Type';
const CONTENT_TYPE_HEADER_VALUE = 'application/json';

const ACCEPT_HEADER = 'Accept';
const CORS_ACCESS_HEADER = 'Access-Control-Request-Method';
const ORIGIN_HEADER = 'Origin';

function isHeaderObject(a: Headers | string[][] | Record<string, unknown>): a is Headers {
    return 'append' in a;
}

// set up default headers:
const baseHeaders = new Headers([[ACCEPT_HEADER, CONTENT_TYPE_HEADER_VALUE]]);
const corsHeaders = new Headers([
    [CORS_ACCESS_HEADER, '*'],
    [ACCEPT_HEADER, '*'],
]);
//#endregion

export interface IRequestResult {
    href: string;
    options: RequestInit;
    response: string;
    status: number;
    ok: boolean;
    requestTimestamp: string;
    responseTimestamp: string;
}

export class FetchService {
    private static _pendingRequests: IDictionary<{
        url: string;
        method: string;
        promise: Promise<Response>;
        abortController: AbortController;
    }> = {};

    public static async get(
        url: URL | string,
        abortController: AbortController | null = new AbortController(),
        isSynchronized: boolean = false,
        abortSameRequests: boolean = true,
        mode?: Fetchmode,
        headers?: Headers | string[][] | Record<string, unknown>
    ): Promise<Response> {
        return this.fetch(
            url,
            null,
            HttpMethods.GET,
            mode,
            abortController,
            isSynchronized,
            abortSameRequests,
            headers
        );
    }

    public static async post<T = unknown>(
        url: URL | string,
        data: T | null = null,
        abortController: AbortController | null = new AbortController(),
        isSynchronized: boolean = false,
        abortSameRequests: boolean = true,
        mode?: Fetchmode,
        headers?: Headers | string[][] | Record<string, unknown>
    ): Promise<Response> {
        return this.fetch<T>(
            url,
            data,
            HttpMethods.POST,
            mode,
            abortController,
            isSynchronized,
            abortSameRequests,
            headers
        );
    }

    public static async put<T = unknown>(
        url: URL | string,
        data: T | null = null,
        abortController: AbortController | null = new AbortController(),
        isSynchronized: boolean = false,
        abortSameRequests: boolean = true,
        mode?: Fetchmode
    ): Promise<Response> {
        return this.fetch<T>(url, data, HttpMethods.PUT, mode, abortController, isSynchronized, abortSameRequests);
    }

    public static async patch<T = unknown>(
        url: URL | string,
        data: T | null = null,
        abortController: AbortController | null = new AbortController(),
        isSynchronized: boolean = false,
        abortSameRequests: boolean = true,
        mode?: Fetchmode
    ): Promise<Response> {
        return this.fetch<T>(url, data, HttpMethods.PATCH, mode, abortController, isSynchronized, abortSameRequests);
    }

    public static async delete(
        url: URL | string,
        abortController: AbortController | null = new AbortController(),
        isSynchronized: boolean = false,
        abortSameRequests: boolean = true,
        mode?: Fetchmode
    ): Promise<Response> {
        return this.fetch(url, null, HttpMethods.DELETE, mode, abortController, isSynchronized, abortSameRequests);
    }

    /**
     * Starts an Ajax Call using the Js Fetch-Api
     *
     * @static
     * @param {(URL | string)} url
     * @param {(unknown | null)} [data=null]
     * @param {HttpMethod} [method=HttpMethods.GET]
     * @param {Fetchmode} [mode=undefined]
     * @returns {Promise<Response>}
     * @memberof FetchApi
     */
    public static async fetch<T = unknown>(
        url: URL | string,
        data: T | null = null,
        method?: HttpMethod,
        mode?: Fetchmode,
        abortController: AbortController | null = new AbortController(),
        isSynchronized: boolean = false,
        abortSameRequests: boolean = true,
        headers?: Headers | string[][] | Record<string, unknown>
    ): Promise<Response> {
        // if the given URL is not already an URL, create one:
        const fetchUrl = url instanceof URL ? url : new URL(url);

        const abortControllerInstance = abortController ?? new AbortController();

        // save the request options
        const requestOptions = {
            href: fetchUrl.toString(),
            options: this._generateRequestOptions(
                fetchUrl,
                data,
                (method || HttpMethods.GET).toUpperCase(),
                mode,
                abortControllerInstance,
                headers
            ),
            requestTimestamp: new CoreDate().toISOString(),
        };

        //
        if (
            abortSameRequests &&
            requestOptions.href in this._pendingRequests &&
            requestOptions.options.method === HttpMethods.GET &&
            this._pendingRequests[requestOptions.href].method === HttpMethods.GET
        ) {
            this._pendingRequests[requestOptions.href].abortController.abort();
        }

        // if the current call is synchronized we need to wait until all promises are settled
        if (isSynchronized && Object.keys(this._pendingRequests).length > 0) {
            await Promise.allSettled(Object.values(this._pendingRequests).map((x) => x.promise));
        }

        // fire the request and start a request promise
        const p = fetch(requestOptions.href, requestOptions.options);
        // push the promise
        this._pendingRequests[requestOptions.href] = {
            url: requestOptions.href,
            method: requestOptions.options.method || HttpMethods.GET,
            promise: p,
            abortController: abortControllerInstance,
        };

        // await the fetch response
        const response = await p.finally(() => {
            // remove the promise
        });
        delete this._pendingRequests[requestOptions.href];

        if (!response.ok) {
            // if (fetchUrl.host === ApplicationProvisionSettings.currentInstance.currentEp.uri) {
            //     ApplicationProvisionSettings.currentInstance.currentEp =
            //         ApplicationProvisionSettings.currentInstance.nextEp;
            // }
        }

        return response;
    }

    private static _generateRequestOptions(
        url: URL,
        data: unknown | null = null,
        method: HttpMethod = HttpMethods.GET,
        mode?: Fetchmode,
        abortSignal?: AbortController | null,
        additionalHeaders?: Headers | string[][] | Record<string, unknown>
    ) {
        const headers = new Headers(baseHeaders);

        try {
            if (additionalHeaders) {
                if (additionalHeaders instanceof Headers) {
                    additionalHeaders.forEach((value: string, key: string) => {
                        headers.set(key, value);
                    });
                } else if (Array.isArray(additionalHeaders)) {
                    additionalHeaders.forEach((header) => {
                        headers.set(header[0], header[1]);
                    });
                } else {
                    Object.keys(additionalHeaders).forEach((key) => {
                        headers.set(key, String(additionalHeaders[key]));
                    });
                }
            }
        } catch {}

        // as soon the Ajax call is a Post then let the data POST in the Request's body
        const hasBodyData = data && method !== HttpMethods.GET && method !== HttpMethods.HEAD;

        // if the data is not null, then add the data to the body respecting the type
        const requestBody = !hasBodyData ? undefined : data instanceof FormData ? data : JSON.stringify(data);

        // initalize the options
        const requestOptions: RequestInit = {
            method: method,
            headers: mode === Fetchmodes.CORS ? new Headers(corsHeaders) : headers,
            mode: mode,
            body: requestBody,
            credentials: 'include',
            signal: abortSignal?.signal,
            redirect: 'manual',
        };

        if (requestOptions.headers && isHeaderObject(requestOptions.headers)) {
            // delete the content type header
            if (requestOptions.headers.has(CONTENT_TYPE_HEADER)) {
                requestOptions.headers.delete(CONTENT_TYPE_HEADER);
            }

            // if neccessary set up content type header
            if (hasBodyData && !(data instanceof FormData)) {
                requestOptions.headers.set(CONTENT_TYPE_HEADER, CONTENT_TYPE_HEADER_VALUE);
            }

            // add Origin Header to fix safari bug
            if (requestOptions.headers.has(ORIGIN_HEADER)) {
                requestOptions.headers.delete(ORIGIN_HEADER);
            }
            requestOptions.headers.set(ORIGIN_HEADER, globalThis.location.origin);
        }

        return requestOptions;
    }
}

export default FetchService;
