import { useDispatch, useSelector } from '@bnb-tech/core/src/StoreManager';
import { Typography } from '@mui/material';
import { memo, useEffect } from 'react';

import ChatActionCreator from '../../../../../services/Chat/actions/ChatActionCreator';
import { getGDWA } from '../../../../../services/Chat/selectors/ChatSelectors';

export const GDWAInfo = memo((props: {}) => {
    const gdwa = useSelector(getGDWA);
    const dispatch = useDispatch();

    const {
        globalOpenAsaDialogs,
        globalOpenDialogs,
        globalWaitingRoomDialogs,
        // newCustomerConversionPercentage,
        openDialogs,
        globalUnassignedAsaDialogs,
        globalUnassignedOpenDialogs,
    } = gdwa;

    useEffect(() => {
        const handler = () => dispatch(ChatActionCreator.getGDWA());

        handler();

        const interval = setInterval(handler, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch]);

    return (
        <Typography variant="h5" className="spacing margin right triple" style={{ order: 1 }}>
            G: {globalUnassignedOpenDialogs}/{globalOpenDialogs} D: {openDialogs} W: {globalWaitingRoomDialogs} A:{' '}
            {globalUnassignedAsaDialogs}/{globalOpenAsaDialogs}
            {/*
            -{' '}
            <Typography
                variant="inherit"
                component="span"
                color={newCustomerConversionPercentage >= 10 ? 'success' : 'error'}
            >
                C: {newCustomerConversionPercentage.toFixed(0)} %
            </Typography> */}
        </Typography>
    );
});
