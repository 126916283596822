import isString from '../typeguards/isString';

export class CoreRegExp extends RegExp {
    public static replaceInvalidRegexStrings(s: string) {
        return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    constructor(pattern: string | RegExp, flags?: string) {
        if (isString(pattern)) {
            super(CoreRegExp.replaceInvalidRegexStrings(pattern), flags);
        } else {
            super(pattern, flags);
        }
    }
}

export default CoreRegExp;
