import { IDictionary } from '@bnb-tech/core/src/models/helper/IDictionary';
import { ArrowUpward as faArrowUp, FilterList as faFilter } from '@mui/icons-material';
import { FilterList as faFilterSolid } from '@mui/icons-material';
import { Badge, Divider, IconButton, Popover, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import React, { useCallback, useState } from 'react';

import Icon from '../../../Icon';
import { Order, RowType, useStyles } from '../../DataTable';
import { IColumnDefinition } from '../../IColumnDefinition';
import { IFilterValue } from '../../models/IFilterValue';

export interface ITableHeadComponentProps {
    column: IColumnDefinition<{}>;
    order?: Order;
    orderBy?: string;
    classes: ReturnType<typeof useStyles>;
    filters: IDictionary<Array<IFilterValue<unknown>>>;
    rows: RowType[];

    setFilters(filters: IDictionary<Array<IFilterValue<unknown>>>): void;
    onRequestSort(event: React.MouseEvent<unknown>, property: string): void;
}

export function TableHeadComponent(props: ITableHeadComponentProps) {
    const { column, order, orderBy, onRequestSort, classes, filters, setFilters, rows } = props;
    const { id, numeric, disablePadding, style, sortable = true, label, hasFilter, filterComponent } = column;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);

    const sorthandler = (event: React.MouseEvent<HTMLSpanElement>) => onRequestSort(event, id);
    const handleFilterClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
        []
    );
    const handleSetFilter = (newFilters: Array<IFilterValue<unknown>>) => {
        setFilters({ ...filters, [id]: newFilters });
    };

    const isSorted = orderBy === id;
    const filtersOfColumn = filters[id] ?? [];
    const filtersOfColumncount = filtersOfColumn.length;

    return (
        <TableCell
            key={id}
            align={numeric ? 'right' : 'left'}
            padding={disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === undefined ? undefined : orderBy === id ? order : false}
            style={style}
            variant="head"
        >
            {sortable ? (
                <TableSortLabel
                    active={isSorted}
                    direction={isSorted ? order : 'asc'}
                    onClick={sorthandler}
                    IconComponent={(p) => <Icon className={p.className} icon={faArrowUp} />}
                >
                    {label}
                    {orderBy === id && (
                        <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    )}
                </TableSortLabel>
            ) : (
                label
            )}

            {hasFilter && filterComponent && (
                <>
                    <IconButton onClick={handleFilterClick} size="small">
                        <Badge badgeContent={filtersOfColumncount} color="primary">
                            <Icon icon={filtersOfColumncount > 0 ? faFilterSolid : faFilter} fontSize="small" />
                        </Badge>
                    </IconButton>
                    {anchorEl && (
                        <Popover open anchorEl={anchorEl} onClose={() => setAnchorEl(undefined)}>
                            <div className="flex spacing padding all double">
                                <Typography>{label}</Typography>
                                <Divider />
                                {filterComponent(column, rows, filtersOfColumn, handleSetFilter)}
                            </div>
                        </Popover>
                    )}
                </>
            )}
        </TableCell>
    );
}
