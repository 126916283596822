import Config from './config';

const BASE = '/v1';
const IDENTITY_BASE = BASE + '/identity';
const SHOP_BASE = BASE + '/shop';
const DATING_BASE = BASE + '/dating';
const CHAT_BASE = BASE + '/chat';
const CHAT_INFORMATION_BASE = BASE + '/chatInformation';
const TICKET_BASE = BASE + '/ticket';
const DASHBOARD_BASE = BASE + '/dashboard';
const IMAGE_TAG_BASE = BASE + '/templateImageTag';
const USER_IMAGES_BASE = BASE + '/userimage';
const USER_JOB_TEMPLATE_BASE = BASE + '/user-job-template';
const AGENCY_USER_BASE = BASE + '/agencyUser';

class ApiRoutes {
    public static BASE = BASE;

    public static Identity = class {
        public static BASE = IDENTITY_BASE;
        public static Login = IDENTITY_BASE + '/login';
        public static Logout = IDENTITY_BASE + '/logout';
        public static User = IDENTITY_BASE + '/user';
    };

    public static Dashboard = class {
        public static BASE = DASHBOARD_BASE;
        public static Agent = DASHBOARD_BASE + '/agent';
    };

    public static AgencyUser = class {
        public static Base = AGENCY_USER_BASE;
        public static Self = (id: string) => AGENCY_USER_BASE + '/' + id;
        public static SelfOfProject = (id: string, projectId: string) =>
            `${AGENCY_USER_BASE}/${id}/project/${projectId}`;
    };

    public static UserImage = class {
        public static BASE = USER_IMAGES_BASE;
        public static UploadImages = (id: string) => USER_IMAGES_BASE + '/uploadImages/' + id;
    };

    public static Ticket = class {
        public static BASE = TICKET_BASE;
        public static Self = (id: string) => TICKET_BASE + '/' + id;
    };

    public static Shop = class {
        public static BASE = SHOP_BASE;
        public static GiftsImage = (id: string) => `${ApiRoutes.Chat.Gifts}/image/${id}`;
    };

    public static Dating = class {
        public static BASE = DATING_BASE;
        public static Image = (id: string) => DATING_BASE + `/image/${id}`;
    };

    public static ImageTag = class {
        public static Base = IMAGE_TAG_BASE;
        public static Self = (id: string) => `${ApiRoutes.ImageTag.Base}/${id}`;
    };

    public static Chat = class {
        public static BASE = CHAT_BASE;
        public static Hub = CHAT_BASE + '/hub';
        public static SendIcebreaker = CHAT_BASE + '/icebreaker';
        public static Next = CHAT_BASE + '/next';
        public static Gifts = CHAT_BASE + '/gift';
        public static WaitingRoom = CHAT_BASE + '/waitingRoom';
        public static TemplateImages = CHAT_BASE + '/templateImage';

        public static Self = (id: string) => CHAT_BASE + `/${id}`;
        public static SendGift = (id: string, giftId: string) => ApiRoutes.Chat.Self(id) + `/gift/${giftId}`;
        public static SendImage = (id: string) => ApiRoutes.Chat.Self(id) + `/image`;
        public static SendWink = (senderId: string, userId: string) => CHAT_BASE + `/wink/${senderId}/${userId}`;
        public static WaitingRoomSelf = (id: string) => ApiRoutes.Chat.WaitingRoom + '/' + id;
        public static TemplateImagesToUserId = (id: string) => `${ApiRoutes.Chat.TemplateImages}/user/${id}`;

        public static SetLanguage = (id: string) => ApiRoutes.Chat.Self(id) + `/language`;
    };

    public static ChatInformation = class {
        public static BASE = CHAT_INFORMATION_BASE;
        public static GDWA = CHAT_INFORMATION_BASE + '/gdwa';
        public static Self = (id: string) => CHAT_INFORMATION_BASE + `/${id}`;
        public static DialogInformation = (id: string) => ApiRoutes.ChatInformation.Self(id) + `/information`;
        public static ChatPartnerInformation = (id: string, partnerId: string) =>
            ApiRoutes.ChatInformation.DialogInformation(id) + `/partner/${partnerId}`;

        public static DialogNote = (id: string) => ApiRoutes.ChatInformation.DialogInformation(id) + '/note';
        public static DialogNoteSelf = (id: string, dialogNoteId: string) =>
            ApiRoutes.ChatInformation.DialogNote(id) + '/' + dialogNoteId;
    };

    public static UserJobTemplate = class {
        public static Base = USER_JOB_TEMPLATE_BASE;
        public static Self = (id: string) => `${ApiRoutes.UserJobTemplate.Base}/${id}`;
    };

    public static createUrl(uri: string, ...searchParams: Array<[string, string]>): string {
        const url = new URL(uri, Config.BASE_URL);

        if (searchParams) {
            for (const [name, value] of searchParams) {
                url.searchParams.append(name, value);
            }
        }

        return url.toString();
    }
}

export default ApiRoutes;
