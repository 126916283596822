import type { IWindow } from 'Bootstrapper/IWindow';
import ModuleManager from '../ModuleManager/ModuleManager';
import CoreConfiguration from '../config';
import { isFunction } from '../typeguards';
import CustomEvent from '../ui_helper/CustomEvent';
import { compose, legacy_createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import type { PersistConfig } from 'redux-persist/es/types';
import MiddlewareManager, { type StoreEnhancer } from './MiddlewareManager';
import ReducerManager from './ReducerManager';
import type { IAction } from './actions';
import type { IDispatch, IMiddleware, IReducer, IReducerToCombine, IReducersMapObject, IState, IStore } from './models';
import type { IPersistor } from './models/IPersistor';
import storage from './storage/persistorStorage';

declare let globalThis: IWindow;

const STATE_STORAGE_KEY = `${globalThis?.location?.host ?? 'app'}-store`;
const persistConfig = {
    key: STATE_STORAGE_KEY,
    storage,
    whitelist: CoreConfiguration.persistWhitelist,
    blacklist: CoreConfiguration.persistBlacklist,
};

export class StoreManager {
    public static onStoreInitialized: CustomEvent<IStore<IState>> = new CustomEvent<IStore<IState>>();

    private static _store: IStore<IState>;
    private static _persistor: IPersistor;

    public static configure(
        reducer: IReducerToCombine | IReducer<IState, IAction> = {},
        middlewares: IMiddleware[] = [],
        config?: PersistConfig<IState, unknown, unknown, unknown>
    ): { store: IStore<IState>; persistor: IPersistor } {
        const initialState = undefined;
        // create the reducer while
        const storeReducers = isFunction(reducer)
            ? persistReducer(config ?? persistConfig, reducer)
            : this.createReducer(
                  reducer,
                  ModuleManager.getReducer(),
                  initialState
              );
        // create the store instance with initial state from Storage
        return this._finalCreateStore(storeReducers, this.applyMiddleware(middlewares), initialState);
    }

    public static applyMiddleware(middlewares: IMiddleware[] = []) {
        // get middlewares from modules
        const moduleMiddlewares = ModuleManager.getMiddlewares();
        // combine all middlewares
        MiddlewareManager.concat(middlewares, moduleMiddlewares);
        // register change listener
        MiddlewareManager.addListener(this._onMiddlewaresChanged.bind(this));
        // return the applied middlewares
        return MiddlewareManager.applyMiddleware();
    }

    public static createReducer(
        reducer: IReducerToCombine = {},
        moduleReducers: IReducersMapObject<{}, IAction> = Object.create(null),
        pluginReducers: IReducersMapObject<{}, IAction> = Object.create(null),
        initialState?: {}
    ): IReducer<IState, IAction> {
        // combine the reducer
        const combinedReducers = { ...reducer, ...pluginReducers, ...moduleReducers };
        // add reducers
        ReducerManager.addCombinedReducers(combinedReducers);
        // register change listener
        ReducerManager.addListener(this._onReducerChanged.bind(this));
        // return the Combinded reducer
        return persistReducer(persistConfig, ReducerManager.combine(initialState));
    }

    private static _onReducerChanged(reducers: IReducersMapObject<IState>) {
        // we only need to replace the reducers when the store already had been initialized!
        if (this.store) {
            this.store.replaceReducer(persistReducer(persistConfig, ReducerManager.combine(this.store.getState())));
        }
    }

    private static _onMiddlewaresChanged(middlewares: IMiddleware[]) {
        // TODO: apply new middlewares to store!
        // // we only need to replace the reducers when the store already had been initialized!
        // if (this.store) {
        //     this.store.replaceReducer(MiddlewareManager.applyMiddleware);
        // }
    }

    private static _finalCreateStore(
        combinedReducers: IReducer<IState>,
        middleware: StoreEnhancer<IDispatch, IState>,
        state?: IState
    ): { store: IStore<IState>; persistor: IPersistor } {
        // compose middlewares
        const composed = this._devToolsComposer(middleware);
        // create the store creator
        const storeCreator = composed(legacy_createStore);
        // create the store
        const store = storeCreator<IState, IAction, IState>(combinedReducers, state as IState) as IStore<IState>;
        // save the store instance
        this._store = store;
        this.onStoreInitialized.trigger(store);

        this._persistor = persistStore(store);

        // and return it
        return { store: this._store, persistor: this._persistor };
    }

    /**
     * gets the right composer function, depending if the Devtools can be enabled
     *
     * See for details:
     * https://github.com/zalmoxisus/redux-devtools-extension#usage
     *
     * @readonly
     * @private
     * @static
     * @memberof StoreManager
     */
    private static get _devToolsComposer() {
        let devTools = compose;

        if (CoreConfiguration.enableDevTools) {
            if (globalThis?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
                devTools = globalThis.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
            }
        }
        return devTools;
    }

    public static get store(): IStore<IState> {
        return this._store;
    }

    public static get persistor(): IPersistor {
        return this._persistor;
    }
}

export default StoreManager;