import type { IOnRequestResultPayload } from 'middlewares/fetchMiddleware';
import { type ILink } from 'models/core/link';
import { isArray, isFunction, isNullOrUndefined, isString } from '../../typeguards';
import type { Action } from 'redux';
import type { IState } from '../models';
import type { IAction } from './IAction';

export type ICallAPI = (state?: IState) => Promise<Response>;

export interface ICallAPIParametersResult<T = unknown> {
    href: string;
    data?: T;
    link?: ILink;
}

export type ICallAPIParameters<T = unknown> = (state?: IState) => ICallAPIParametersResult<T>;

export type IShouldCallAPI = (state?: IState) => boolean;

/**
 *
 *
 * @export
 * @interface IAsyncAction
 * @extends {Action}
 * @template RequestData RequestActiontype
 * @template ReceivedData ReceivedActionType
 * @template FailedAction FailedActionType
 * @template P Payload
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IAsyncAction<
    RequestData extends string = string,
    ReceivedData extends string = string,
    FailedAction extends string = string,
    P = any,
> extends Action {
    types: [RequestData, ReceivedData, FailedAction];
    callAPI: ICallAPI;
    shouldCallAPI: IShouldCallAPI;
    payload?: P;
    showBusyIndicator?: boolean;
    isInterruptive?: boolean;
    onError?(data?: IOnRequestResultPayload<{}>): void;
}

export function isAsyncAction(action: IAction | IAsyncAction): action is IAsyncAction {
    if (!isNullOrUndefined(action) && 'types' in action && 'callAPI' in action) {
        const { types, callAPI } = action;

        if (!types) {
            return false;
        }

        if (!isArray(types) || types.length !== 3 || !types.every(isString)) {
            throw new TypeError('Expected an array of three string types.');
        }
        if (!isFunction(callAPI)) {
            throw new TypeError('Expected callAPI to be a function.');
        }

        return true;
    } else {
        return false;
    }
}
