import type { ReactChildren } from '../models/helper/ReactChildren';
import type { IState, IStore } from '../oreManager/models';
import type { ComponentType } from 'react';
import { Provider } from 'react-redux';

export interface IStoreProviderProps {
    store: IStore<IState>;
    children: ReactChildren;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StoreProvider: ComponentType<IStoreProviderProps> = Provider as any;

export default StoreProvider;
