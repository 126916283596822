import { isAsyncAction, type IAction, type IAsyncAction } from '../../StoreManager/actions';
import createMiddleware from '../../StoreManager/middleware/createMiddleware';
import type { IDispatch, IMiddlewareAPI, IState } from '../../StoreManager/models';
import CustomEvent from '../../ui_helper/CustomEvent';
import startApiFetchRequest from './startApiFetchRequest';

export interface IOnRequestResultPayload<R = {}> {
    dispatch: IDispatch;
    response: Response;
    calledAction: IAsyncAction;
    actionType: string;
    result?: R;
    payload: {};
    state: IState;
}

export const onRequestResult: CustomEvent<IOnRequestResultPayload> = new CustomEvent<IOnRequestResultPayload>();

export const fetchMiddleware = createMiddleware<IAction>(
    async (middlewareAPI: IMiddlewareAPI, next: IDispatch<IAction>, action: IAction) => {
        const { dispatch, getState } = middlewareAPI;

        // exclude the IAsyncAction from normal actions
        if (!isAsyncAction(action)) {
            // Normal action: pass it on
            return next(action);
        }

        const { types, callAPI, shouldCallAPI = (state: IState) => true, payload = {} } = action;

        // check if should call api with current state
        if (!shouldCallAPI(getState())) {
            return;
        }

        // extract action types:
        const [requestType, successType, failureType] = types;

        // dispatch that the request is started
        await dispatch({ type: requestType, payload });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        // start the request and handle the request
        return await startApiFetchRequest(dispatch, getState, action, callAPI, successType, failureType, payload);
    }
);

export default fetchMiddleware;
