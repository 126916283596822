import type { HttpMethod } from '../tchService/HttpMethod';

import type { IDictionary } from '../../helper/IDictionary';
import type { ITimer } from '../timer/ITimer';
import type { LinkRelation } from './LinkRelation';

// eslint-disable-next-line @typescript-eslint/ban-types
export type IContentType = IDictionary<object[]>;

/**
 * The value-object class Link represents a relative or absolute reference to another resource.
 *
 * @export
 * @interface ILink
 */
export interface ILink {
    /**
    /**
     * Optional request content to pass. See section on member "content".
     *
     * @type {IContentType | IContentType[] | null | undefined}
     * @memberof ILink
     * @default null
     */
    content?: IContentType | IContentType[] | null | undefined;
    /**
     * Fully or partially qualified URI.
     *
     * @type {string}
     * @memberof ILink
     */
    href: string;
    /**
     * The optional HTTP method or verb to use.
     * The optional method is one of the predefined HTTP verbs get, post, put or delete.
     * If the method is missing, a get is assumed implicitly. But no matter the method name,
     * a get and options call should always be possible.
     *
     * @type {string}
     * @memberof ILink
     * @default HttpMethods.GET
     */
    method?: HttpMethod;
    /**
     * The optional relation consists of two case-insensitive parts
     * separated by a slash sign (“/”). The first part as basis specifies
     * the relation itself and the optional second part the verb or
     * method to use when invoking the link. If the second part is
     * missing, only a get is allowed. No matter the second part,
     * a get and options call should always be possible.
     *
     * @type {LinkRelation | null}
     * @memberof ILink
     * @default null
     */
    rel?: LinkRelation | null;
    /**
     * The optional value indicating whether the [href] property is a URI template
     * and/or the content property is templated
     *
     * @type {boolean}
     * @memberof ILink
     * @default false
     */
    isTemplated?: boolean;
    /**
     * The culture dependent name or descriptive text associated with the link.
     *
     * @type {string | null}
     * @memberof ILink
     * @default null
     */
    text?: string | null;
    /**
     * Optional timer that allows the specification of a time delay and time interval to auto-following or auto-invocating the link.
     * See separate section on “auto-invocation”.
     *
     * @type {ITimer | null}
     * @memberof ILink
     * @default null
     */
    timer?: ITimer | null;
    /**
     * Optional Multipurpose Internet Mail Exchange (MIME) media types.
     * See section on member “type”.
     *
     * @type {string}
     * @memberof ILink
     */
    type?: string;
}

export const defaultLink: ILink = { href: '' };
