import { LinearProgress } from '@mui/material';

import { useLoadingIndicator } from './useLoadingIndicator';

export interface IGlobalLoadingIndicatorProps {}

export const GlobalLoadingIndicator = (props: IGlobalLoadingIndicatorProps) => {
    const { loading } = useLoadingIndicator();

    if (!loading) {
        return null;
    }

    return <LinearProgress style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} />;
};

export default GlobalLoadingIndicator;
